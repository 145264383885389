export const MENU_SIDEBAR = {
  GET: 'GET_MENU_SIDEBAR',
  UPDATE: {
    COLLAPSE: 'UPDATE_COLLAPSE',
  },
};

export const PREFERENCES = {
  DATE: 'DATE_TIME_SETTING',
};
