import {
  FETCH_KYC_JOURNEY_REQUEST,
  FETCH_KYC_JOURNEY_SUCCESS,
  FETCH_KYC_JOURNEY_FAILURE,
  FETCH_BANK_JOURNEY_REQUEST,
  FETCH_BANK_JOURNEY_SUCCESS,
  FETCH_BANK_JOURNEY_FAILURE,
  FETCH_TXN_JOURNEY_REQUEST,
  FETCH_TXN_JOURNEY_SUCCESS,
  FETCH_TXN_JOURNEY_FAILURE,
} from './analyticsActionTypes';
const initialState = {
  loadingUserKycJourney: false,
  userKYCJourney: [],
  userKYCJourneyErrMsg: '',
  loadingUserBankJourney: false,
  userBankJourney: [],
  userBankJourneyErrMsg: '',
  loadingUserTxnJourney: false,
  userTxnJourney: [],
  userTxnJourneyErrMsg: '',
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KYC_JOURNEY_REQUEST:
      return {
        ...state,
        loadingUserKycJourney: true,
        userKYCJourney: [],
        userKYCJourneyErrMsg: '',
      };
    case FETCH_KYC_JOURNEY_SUCCESS:
      return {
        ...state,
        loadingUserKycJourney: false,
        userKYCJourney: action.payload,
        userKYCJourneyErrMsg: '',
      };
    case FETCH_KYC_JOURNEY_FAILURE:
      return {
        ...state,
        loadingUserKycJourney: false,
        userKYCJourney: [],
        userKYCJourneyErrMsg: action.payload,
      };
    case FETCH_BANK_JOURNEY_REQUEST:
      return {
        ...state,
        loadingUserBankJourney: true,
        userBankJourney: [],
        userBankJourneyErrMsg: '',
      };
    case FETCH_BANK_JOURNEY_SUCCESS:
      return {
        ...state,
        loadingUserBankJourney: false,
        userBankJourney: action.payload,
        userBankJourneyErrMsg: '',
      };
    case FETCH_BANK_JOURNEY_FAILURE:
      return {
        ...state,
        loadingUserBankJourney: false,
        userBankJourney: [],
        userBankJourneyErrMsg: action.payload,
      };
    case FETCH_TXN_JOURNEY_REQUEST:
      return {
        ...state,
        loadingUserTxnJourney: true,
        userTxnJourney: [],
        userTxnJourneyErrMsg: '',
      };
    case FETCH_TXN_JOURNEY_SUCCESS:
      return {
        ...state,
        loadingUserTxnJourney: false,
        userTxnJourney: action.payload,
        userTxnJourneyErrMsg: '',
      };
    case FETCH_TXN_JOURNEY_FAILURE:
      return {
        ...state,
        loadingUserTxnJourney: false,
        userTxnJourney: [],
        userTxnJourneyErrMsg: action.payload,
      };
    default:
      return state;
  }
};

export default analyticsReducer;
