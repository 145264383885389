import {
  FETCH_FIAT_TXN_REQUEST,
  FETCH_FIAT_TXN_SUCCESS,
  FETCH_FIAT_TXN_FAILED,
  FETCH_VIRTUAL_ACCOUNT_REQUEST,
  FETCH_VIRTUAL_ACCOUNT_SUCCESS,
  FETCH_VIRTUAL_ACCOUNT_FAILED,
  UPDATE_ADMIN_REMARKS_REQUEST,
  UPDATE_ADMIN_REMARKS_SUCCESS,
  UPDATE_ADMIN_REMARKS_FAILURE,
  FETCH_FIAT_ORDER_REQUEST,
  FETCH_FIAT_ORDER_SUCCESS,
  FETCH_FIAT_ORDER_FAILURE,
  FETCH_ALL_FIAT_TXN_FAILURE,
  FETCH_ALL_FIAT_TXN_SUCCESS,
  FETCH_ALL_FIAT_TXN_REQUEST,
  FETCH_Z1_HYPTO_BALANCE_REQUEST,
  FETCH_Z1_HYPTO_BALANCE_SUCCESS,
  FETCH_Z1_HYPTO_BALANCE_FAILURE,
  UPDATE_REFUND_STATUS_FAILURE,
  UPDATE_REFUND_STATUS_SUCCESS,
  UPDATE_REFUND_STATUS_REQUEST,
  CREDIT_DEPOSIT_REQUEST,
  CREDIT_DEPOSIT_SUCCESS,
  CREDIT_DEPOSIT_FAILURE,
  UPLOAD_BANK_FEED_REQUEST,
  UPLOAD_BANK_FEED_FAILURE,
  UPLOAD_BANK_FEED_SUCCESS,
  BANK_CREDIT_DEPOSIT_REQUEST,
  BANK_CREDIT_DEPOSIT_FAILURE,
  BANK_CREDIT_DEPOSIT_SUCCESS,
  GET_MATCHING_BANK_FEEDS_REQUEST,
  GET_MATCHING_BANK_FEEDS_FAILURE,
  GET_MATCHING_BANK_FEEDS_SUCCESS,
  UPDATE_BANK_FEED_STATUS_REQUEST,
  UPDATE_BANK_FEED_STATUS_FAILURE,
  UPDATE_BANK_FEED_STATUS_SUCCESS,
  FETCH_PAYMENT_OPTIONS_REQUEST,
  FETCH_PAYMENT_OPTIONS_SUCCESS,
  FETCH_PAYMENT_OPTIONS_FAILURE,
  FETCH_SCREENSHOTS_REQUEST,
  FETCH_SCREENSHOTS_SUCCESS,
  FETCH_SCREENSHOTS_FAILURE,
  CANCEL_ORDER_REQUEST,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAILURE,
  CREATE_DEPOSIT_ORDER_REQUEST,
  CREATE_DEPOSIT_ORDER_SUCCESS,
  CREATE_DEPOSIT_ORDER_FAILURE,
  UPDATE_BANK_REFERENCE_REQUEST,
  UPDATE_BANK_REFERENCE_SUCCESS,
  UPDATE_BANK_REFERENCE_FAILURE,
  REFUND_DEPOSIT_REQUEST,
  REFUND_DEPOSIT_SUCCESS,
  REFUND_DEPOSIT_FAILURE,
  BANK_FEED_BY_ID_REQUEST,
  BANK_FEED_BY_ID_SUCCESS,
  BANK_FEED_BY_ID_FAILURE,
  SET_FILTER_QUERY_PARAMS,
} from './paymentActionTypes';
const initialState = {
  loadingFiatTxnStatus: false,
  fiatTxns: [],
  fiatTxnsErrorMsg: '',
  loadingCryptoTxnStatus: false,
  cryptoTxns: [],
  cryptoTxnsErrorMsg: '',
  loadingVirtualAccountStatus: false,
  virtualAccount: {},
  virtualAccountErrMsg: '',
  fiatCount: 0,
  loadingAdminRemarksStatus: false,
  adminRemarksUpdated: false,
  adminRemarksErrorMsg: '',
  loadingFiatOrderStatus: false,
  fiatOrderDetails: {},
  fiatOrderErrorMsg: '',
  fiatMinAmount: '',
  fiatMaxAmount: '',
  loadingAllFiatTxnsStatus: false,
  allFiatTxns: [],
  allFiatTxnsErrorMsg: '',
  allFiatTxnsCount: 0,
  loadingZ1HyptoBalance: false,
  Z1HyptoBalance: {},
  Z1HyptoBalanceErrMsg: '',
  loadingUpdateRefundStatus: false,
  updateRefundStatus: false,
  updateRefundStatusErrorMsg: '',
  loadingCreditDeposit: false,
  creditDeposit: false,
  creditDepositErrorMsg: '',
  uploadingBankFeed: false,
  bankFeedUploadSuccess: false,
  loadingMatchingBankFeeds: false,
  matchingBankFeeds: [],
  matchingBankFeedErrorMsg: '',
  processingManualBankCredit: false,
  manualBankCreditSuccess: false,
  manualBankCreditErrorMsg: '',
  updatingBankFeedStatus: false,
  bankFeedStatusUpdated: false,
  loadingPaymentOptions: false,
  paymentOptions: [],
  paymentOptionsErrorMsg: '',
  loadingScreenshot: false,
  bankDepositScreenshot: '',
  loadingCancelOrder: false,
  cancelOrderSuccess: false,
  cancelOrderFailed: '',
  creatingDepositOrder: false,
  depositOrderCreated: false,
  updatingBankReference: false,
  bankReferenceUpdated: false,
  updateBankReferenceFailed: '',
  loadingRefundDeposit: false,
  refundDepositSuccess: false,
  refundDepositErrorMsg: '',
  loadingBankFeedById: false,
  bankFeedById: false,
  bankFeedByIdErrorMsg: '',
  filterQueryParams: {},
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIAT_TXN_REQUEST:
      return {
        ...state,
        loadingFiatTxnStatus: true,
        fiatTxns: [],
        fiatTxnsErrorMsg: '',
        fiatCount: 0,
        fiatMinAmount: '',
        fiatMaxAmount: '',
      };
    case FETCH_FIAT_TXN_SUCCESS:
      return {
        ...state,
        loadingFiatTxnStatus: false,
        fiatTxns: action.payload.transactions,
        fiatTxnsErrorMsg: '',
        fiatCount: action.payload.count,
        fiatMinAmount: action.payload.minAmount,
        fiatMaxAmount: action.payload.maxAmount,
      };
    case FETCH_FIAT_TXN_FAILED:
      return {
        ...state,
        loadingFiatTxnStatus: false,
        fiatTxns: [],
        fiatTxnsErrorMsg: action.payload,
        fiatCount: 0,
        fiatMinAmount: '',
        fiatMaxAmount: '',
      };
    case FETCH_VIRTUAL_ACCOUNT_REQUEST:
      return {
        ...state,
        loadingVirtualAccountStatus: true,
        virtualAccount: {},
        virtualAccountErrMsg: '',
      };
    case FETCH_VIRTUAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingVirtualAccountStatus: false,
        virtualAccount: action.payload,
        virtualAccountErrMsg: '',
      };
    case FETCH_VIRTUAL_ACCOUNT_FAILED:
      return {
        ...state,
        loadingVirtualAccountStatus: false,
        virtualAccount: {},
        virtualAccountErrMsg: action.payload,
      };
    case UPDATE_ADMIN_REMARKS_REQUEST:
      return {
        ...state,
        loadingAdminRemarksStatus: true,
        adminRemarksUpdated: false,
        adminRemarksErrorMsg: '',
      };
    case UPDATE_ADMIN_REMARKS_SUCCESS:
      return {
        ...state,
        loadingAdminRemarksStatus: false,
        adminRemarksUpdated: action.payload,
        adminRemarksErrorMsg: '',
      };
    case UPDATE_ADMIN_REMARKS_FAILURE:
      return {
        ...state,
        loadingAdminRemarksStatus: false,
        adminRemarksUpdated: false,
        adminRemarksErrorMsg: action.payload,
      };
    case FETCH_FIAT_ORDER_REQUEST:
      return {
        ...state,
        loadingFiatOrderStatus: true,
        fiatOrderDetails: {},
        fiatOrderErrorMsg: '',
      };
    case FETCH_FIAT_ORDER_SUCCESS:
      return {
        ...state,
        loadingFiatOrderStatus: false,
        fiatOrderDetails: action.payload,
        fiatOrderErrorMsg: '',
      };
    case FETCH_FIAT_ORDER_FAILURE:
      return {
        ...state,
        loadingFiatOrderStatus: false,
        fiatOrderDetails: {},
        fiatOrderErrorMsg: action.payload,
      };
    case FETCH_ALL_FIAT_TXN_REQUEST:
      return {
        ...state,
        loadingAllFiatTxnsStatus: true,
        allFiatTxns: [],
        allFiatTxnsErrorMsg: '',
        allFiatTxnsCount: 0,
      };
    case FETCH_ALL_FIAT_TXN_SUCCESS:
      return {
        ...state,
        loadingAllFiatTxnsStatus: false,
        allFiatTxns: action.payload.transactions,
        allFiatTxnsErrorMsg: '',
        allFiatTxnsCount: action.payload.count,
      };
    case FETCH_ALL_FIAT_TXN_FAILURE:
      return {
        ...state,
        loadingAllFiatTxnsStatus: false,
        allFiatTxns: [],
        allFiatTxnsErrorMsg: action.payload,
        allFiatTxnsCount: 0,
      };
    case FETCH_Z1_HYPTO_BALANCE_REQUEST:
      return {
        ...state,
        loadingZ1HyptoBalance: true,
        Z1HyptoBalance: {},
        Z1HyptoBalanceErrMsg: '',
      };
    case FETCH_Z1_HYPTO_BALANCE_SUCCESS:
      return {
        ...state,
        loadingZ1HyptoBalance: false,
        Z1HyptoBalance: action.payload,
        Z1HyptoBalanceErrMsg: '',
      };
    case FETCH_Z1_HYPTO_BALANCE_FAILURE:
      return {
        ...state,
        loadingZ1HyptoBalance: false,
        Z1HyptoBalance: {},
        Z1HyptoBalanceErrMsg: action.payload,
      };
    case UPDATE_REFUND_STATUS_REQUEST:
      return {
        ...state,
        loadingUpdateRefundStatus: true,
        updateRefundStatus: false,
        updateRefundStatusErrorMsg: '',
      };
    case UPDATE_REFUND_STATUS_SUCCESS:
      return {
        ...state,
        loadingUpdateRefundStatus: false,
        updateRefundStatus: action.payload,
        updateRefundStatusErrorMsg: '',
      };
    case UPDATE_REFUND_STATUS_FAILURE:
      return {
        ...state,
        loadingUpdateRefundStatus: false,
        updateRefundStatus: false,
        updateRefundStatusErrorMsg: action.payload,
      };
    case CREDIT_DEPOSIT_REQUEST:
      return {
        ...state,
        loadingCreditDeposit: true,
        creditDeposit: false,
        creditDepositErrorMsg: '',
      };
    case CREDIT_DEPOSIT_SUCCESS:
      return {
        ...state,
        loadingCreditDeposit: false,
        creditDeposit: action.payload,
        creditDepositErrorMsg: '',
      };
    case CREDIT_DEPOSIT_FAILURE:
      return {
        ...state,
        loadingCreditDeposit: false,
        creditDeposit: false,
        creditDepositErrorMsg: action.payload,
      };
    case UPLOAD_BANK_FEED_REQUEST:
      return {
        ...state,
        uploadingBankFeed: true,
        bankFeedUploadSuccess: false,
      };
    case UPLOAD_BANK_FEED_SUCCESS:
      return {
        ...state,
        uploadingBankFeed: false,
        bankFeedUploadSuccess: true,
      };
    case UPLOAD_BANK_FEED_FAILURE:
      return {
        ...state,
        uploadingBankFeed: false,
        bankFeedUploadSuccess: false,
      };
    case BANK_CREDIT_DEPOSIT_REQUEST:
      return {
        ...state,
        processingManualBankCredit: true,
        manualBankCreditSuccess: false,
        manualBankCreditErrorMsg: '',
      };
    case BANK_CREDIT_DEPOSIT_SUCCESS:
      return {
        ...state,
        processingManualBankCredit: false,
        manualBankCreditSuccess: true,
        manualBankCreditErrorMsg: '',
      };
    case BANK_CREDIT_DEPOSIT_FAILURE:
      return {
        ...state,
        processingManualBankCredit: false,
        manualBankCreditSuccess: false,
        manualBankCreditErrorMsg: action.payload,
      };
    case GET_MATCHING_BANK_FEEDS_REQUEST:
      return {
        ...state,
        loadingMatchingBankFeeds: true,
        matchingBankFeeds: [],
        matchingBankFeedErrorMsg: '',
      };
    case GET_MATCHING_BANK_FEEDS_SUCCESS:
      return {
        ...state,
        loadingMatchingBankFeeds: false,
        matchingBankFeeds: action.payload,
        matchingBankFeedErrorMsg: '',
      };
    case GET_MATCHING_BANK_FEEDS_FAILURE:
      return {
        ...state,
        loadingMatchingBankFeeds: false,
        matchingBankFeeds: [],
        matchingBankFeedErrorMsg: action.payload,
      };
    case UPDATE_BANK_FEED_STATUS_REQUEST:
      return {
        ...state,
        updatingBankFeedStatus: true,
        bankFeedStatusUpdated: false,
      };
    case UPDATE_BANK_FEED_STATUS_SUCCESS:
      return {
        ...state,
        updatingBankFeedStatus: false,
        bankFeedStatusUpdated: true,
      };
    case UPDATE_BANK_FEED_STATUS_FAILURE:
      return {
        ...state,
        updatingBankFeedStatus: false,
        bankFeedStatusUpdated: false,
      };
    case FETCH_PAYMENT_OPTIONS_REQUEST:
      return {
        ...state,
        loadingPaymentOptions: true,
        paymentOptions: [],
        paymentOptionsErrorMsg: '',
      };
    case FETCH_PAYMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        loadingPaymentOptions: false,
        paymentOptions: action.payload,
        paymentOptionsErrorMsg: '',
      };
    case FETCH_PAYMENT_OPTIONS_FAILURE:
      return {
        ...state,
        loadingPaymentOptions: false,
        paymentOptions: [],
        paymentOptionsErrorMsg: action.payload,
      };
    case FETCH_SCREENSHOTS_REQUEST:
      return {
        ...state,
        loadingScreenshot: true,
        bankDepositScreenshot: '',
      };
    case FETCH_SCREENSHOTS_SUCCESS:
      return {
        ...state,
        loadingScreenshot: false,
        bankDepositScreenshot: action.payload,
      };
    case FETCH_SCREENSHOTS_FAILURE:
      return {
        ...state,
        loadingScreenshot: false,
        bankDepositScreenshot: '',
      };
    case CANCEL_ORDER_REQUEST:
      return {
        ...state,
        loadingCancelOrder: true,
        cancelOrderSuccess: false,
        cancelOrderFailed: '',
      };
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        loadingCancelOrder: false,
        cancelOrderSuccess: action.payload,
        cancelOrderFailed: '',
      };
    case CANCEL_ORDER_FAILURE:
      return {
        ...state,
        loadingCancelOrder: false,
        cancelOrderSuccess: false,
        cancelOrderFailed: action.payload,
      };
    case CREATE_DEPOSIT_ORDER_REQUEST:
      return {
        ...state,
        creatingDepositOrder: true,
        depositOrderCreated: false,
      };
    case CREATE_DEPOSIT_ORDER_SUCCESS:
      return {
        ...state,
        creatingDepositOrder: false,
        depositOrderCreated: true,
      };
    case CREATE_DEPOSIT_ORDER_FAILURE:
      return {
        ...state,
        creatingDepositOrder: false,
        depositOrderCreated: false,
      };

    case UPDATE_BANK_REFERENCE_REQUEST:
      return {
        ...state,
        updatingBankReference: true,
        bankReferenceUpdated: false,
        updateBankReferenceFailed: '',
      };
    case UPDATE_BANK_REFERENCE_SUCCESS:
      return {
        ...state,
        updatingBankReference: false,
        bankReferenceUpdated: action.payload,
        updateBankReferenceFailed: '',
      };
    case UPDATE_BANK_REFERENCE_FAILURE:
      return {
        ...state,
        updatingBankReference: false,
        bankReferenceUpdated: false,
        updateBankReferenceFailed: action.payload,
      };
    case REFUND_DEPOSIT_REQUEST:
      return {
        ...state,
        loadingRefundDeposit: true,
        refundDepositSuccess: false,
        refundDepositErrorMsg: '',
      };
    case REFUND_DEPOSIT_SUCCESS:
      return {
        ...state,
        loadingRefundDeposit: false,
        refundDepositSuccess: action.payload,
        refundDepositErrorMsg: '',
      };
    case REFUND_DEPOSIT_FAILURE:
      return {
        ...state,
        loadingRefundDeposit: false,
        refundDepositSuccess: false,
        refundDepositErrorMsg: action.payload,
      };
    case BANK_FEED_BY_ID_REQUEST:
      return {
        ...state,
        loadingBankFeedById: true,
        bankFeedById: false,
        bankFeedByIdErrorMsg: '',
      };
    case BANK_FEED_BY_ID_SUCCESS:
      return {
        ...state,
        loadingBankFeedById: false,
        bankFeedById: action.payload,
        bankFeedByIdErrorMsg: '',
      };
    case BANK_FEED_BY_ID_FAILURE:
      return {
        ...state,
        loadingBankFeedById: false,
        bankFeedById: false,
        bankFeedByIdErrorMsg: action.payload,
      };
    case SET_FILTER_QUERY_PARAMS:
      return {
        ...state,
        filterQueryParams: action.payload,
      };
    default:
      return state;
  }
};

export default paymentReducer;
