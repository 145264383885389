import React, { memo, useEffect, useState } from 'react';
import { Sidebar } from 'components/organisms';
import Routes from 'routes/Routes';
import { Layout } from 'antd';
import './AdminWrapper.scss';
// import { useAuth0 } from '@auth0/auth0-react';
import { Header } from 'components/organisms';
import { useSelector, useDispatch } from 'react-redux';
import { setPermissions, jsonApiHeader } from 'utils/Utility';
import { fetchCurrencySettings } from 'redux/crypto/cryptoActions';
import { fetchPaymentOptions } from 'redux/payments/paymentActions';
import { STORAGE_KEYS } from '../../../constants';
import BalanceBar from 'components/molecules/BalanceBar';
import { fetchAppConfig } from 'redux/admin/adminActions';
import { localStorageSetItem } from 'services/LocalStorageService';
const { Content } = Layout;

const AdminWrapper = (props) => {
  // const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const [isScrolled, setIsScrolled] = useState(false);
  const { sidebarOptions } = useSelector((state) => state.screenOptionsReducer);
  // const handleFetchCurrencySettings = async () => {
    // const accessToken = await getAccessTokenSilently();
    // const header = jsonApiHeader(accessToken);
    // dispatch(fetchCurrencySettings(header));
  // };
  // const handleFetchPaymentOptions = async () => {
    // const accessToken = await getAccessTokenSilently();
    // const header = jsonApiHeader(accessToken);
    // dispatch(fetchPaymentOptions(header));
  // };
  // const handleFetchAdminProfile = async () => {
  //   const accessToken = await getAccessTokenSilently();
  //   const header = jsonApiHeader(accessToken);
  //   dispatch(fetchAppConfig(header, { userId: user.sub }));
  // };
  // const authPermissions =
  //   user && user['https://zebfi.tech/permissions']
  //     ? user['https://zebfi.tech/permissions']
  //     : [];
  // setPermissions(authPermissions);

  // const { currencySettings } = useSelector((state) => state.cryptoReducer);
  // useEffect(() => {
  //   document.querySelector('.site-layout').addEventListener('scroll', (e) => {
  //     if (isScrolled != document.querySelector('.site-layout').scrollTop > 64) {
  //       setIsScrolled(document.querySelector('.site-layout').scrollTop > 64);
  //     } else {
  //       setIsScrolled(document.querySelector('.site-layout').scrollTop > 64);
  //     }
  //   });
  //   handleFetchCurrencySettings();
  //   handleFetchPaymentOptions();
  //   handleFetchAdminProfile();
  // }, []);
  // if (currencySettings && currencySettings.length > 0) {
  //   localStorageSetItem(STORAGE_KEYS.CURRENCY_CONFIG, currencySettings);
  // }
  return (
    <Layout style={{ height: '100%' }} id="z2-admin">
      <Sidebar />
      <Layout className="site-layout">
        <Header isScrolled={isScrolled} />
        {/* <Header isScrolled={isScrolled} user={user} /> */}
        <Content
          className="zeb-admin-container"
          style={{
            margin: `94px 30px 94px 30px`,
            padding: 0,
            minHeight: 'calc(100vh - 124px)',
          }}
        >
          <Routes />
        </Content>
        {/* <BalanceBar sidebarOptions={sidebarOptions} /> */}
      </Layout>
    </Layout>
  );
};

export default memo(AdminWrapper);
