import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import rootReducer from './redux/rootReducer';
// import { ENV_CONFIG } from './config';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { use } from 'react-use-googlelogin';
import {GoogleAuthProvider} from "components/google-login/GoogleAuthProvider";
axios.interceptors.request.use(function (request) {
  request = { ...request, url: encodeURI(request.url) };
  return request;
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxThunk))
);
// const domain = ENV_CONFIG.REACT_APP_AUTH0_DOMAIN;
// const clientId = ENV_CONFIG.REACT_APP_AUTH0_CLIENT_ID;
// const audience = ENV_CONFIG.REACT_APP_AUTH0_AUDIENCE;

const history = createBrowserHistory();

let app = document.getElementById('root');

if (app) {
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
  ReactDOM.render(
    // <Auth0Provider
    //   domain={domain}
    //   clientId={clientId}
    //   redirectUri={window.location.origin}
    //   audience={audience}
    //   cacheLocation="localstorage"
    // >
    <GoogleAuthProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleAuthProvider>
    // </Auth0Provider>
    ,
    app
  );
}
