import { ENV_CONFIG } from '../../config';

import { MENU_SIDEBAR, PREFERENCES } from './screenOptionsTypes';
const BASE_URL = ENV_CONFIG.BASE_URL;
// returns action type
export const fetchOptions = () => {
  return {
    type: MENU_SIDEBAR.GET,
  };
};

export const updateCollapse = (data) => {
  return {
    type: MENU_SIDEBAR.UPDATE.COLLAPSE,
    payload: data,
  };
};

export const setDateTimeValue = (data) => {
  return {
    type: PREFERENCES.DATE,
    payload: data,
  };
};

// action creator (handle async API calls)
export const fetchSidebarOptions = () => (dispatch) => {
  dispatch(fetchOptions());
};
export const updateSidebarCollapse = (value) => (dispatch) => {
  dispatch(updateCollapse(value));
};
export const setDateTime = (value) => (dispatch) => {
  dispatch(setDateTimeValue(value));
};
