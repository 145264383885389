import React from 'react';
import { useState } from 'react';
import Foot from '../Foot';
import { Sprite } from 'components/atoms';
import classes from './BalanceBar.module.scss';

function BalanceBar({ sidebarOptions }) {
  const [isClosed, setIsClosed] = useState(false);
  return (
    <div
      className={`${isClosed ? classes.closed : classes.opened} ${
        classes.balanceBar
      }`}
      style={{
        background: 'var(--bg-color-2)',
        position: 'fixed',
        bottom: '0',
        zIndex: '2',
        width: `calc(100% - ${sidebarOptions.isCollapsed ? '80' : '220'}px)`,
      }}
    >
      {console.log(isClosed)}
      <span
        className={`${classes.icon} ${
          isClosed ? classes.closed : classes.opened
        }`}
        onClick={() => setIsClosed(!isClosed)}
      >
        <Sprite id="chevron-down" width={12} height={12} />
      </span>
      <Foot />
    </div>
  );
}

export default BalanceBar;
