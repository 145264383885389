import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILED,
  SET_USER_PROFILE,
  SEARCH_USER_REQUEST,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILED,
  UPDATE_QUERY_PARAMS,
  SET_CRYPTO_OUTGOING_TXN_REQUEST,
  SET_CRYPTO_OUTGOING_TXN_SUCCESS,
  SET_CRYPTO_OUTGOING_TXN_FAILED,
  SET_FIAT_OUTGOING_TXN_REQUEST,
  SET_FIAT_OUTGOING_TXN_SUCCESS,
  SET_FIAT_OUTGOING_TXN_FAILED,
  DEACTIVATE_ACCOUNT_REQUEST,
  DEACTIVATE_ACCOUNT_SUCCESS,
  DEACTIVATE_ACCOUNT_FAILED,
  RESET_SETTING_STATES,
  SET_DEBIT_REQUEST,
  SET_DEBIT_SUCCESS,
  SET_DEBIT_FAILED,
  EDIT_MOBILE_NUMBER_REQUEST,
  EDIT_MOBILE_NUMBER_SUCCESS,
  EDIT_MOBILE_NUMBER_FAILED,
  FETCH_ADMIN_REMARKS_REQUEST,
  FETCH_ADMIN_REMARKS_SUCCESS,
  FETCH_ADMIN_REMARKS_FAILED,
  GET_USER_LOGIN_HISTORY_REQUEST,
  GET_USER_LOGIN_HISTORY_SUCCESS,
  GET_USER_LOGIN_HISTORY_FAILED,
  GET_RECENT_SIGNUP_LIST_REQUEST,
  GET_RECENT_SIGNUP_LIST_SUCCESS,
  GET_RECENT_SIGNUP_LIST_FAILURE,
  FETCH_GLOBAL_SEARCH_LIST_REQUEST,
  FETCH_GLOBAL_SEARCH_LIST_SUCCESS,
  FETCH_GLOBAL_SEARCH_LIST_FAILURE,
  GET_REJECTED_EXCEL_DETAILS_REQUEST,
  GET_REJECTED_EXCEL_DETAILS_SUCCESS,
  GET_REJECTED_EXCEL_DETAILS_FAILURE,
} from './userActionTypes';

const initialState = {
  loadingUserProfile: false,
  userFilterRefresh: false,
  userProfile: {
    countryCode: 'IN',
    createdAt: '',
    createdBy: '',
    email: '',
    firstName: null,
    kycLevel: 0,
    kycStatus: 0,
    lastName: null,
    modifiedAt: null,
    modifiedBy: '',
    name: '',
    nickName: '',
    phoneNumber: '',
    phoneNumberIsdCode: '',
    picture: '',
    userId: '',
    usersReferalCode: '',
  },
  userProfileErrorMsg: '',
  loadingSearchUser: false,
  userList: [],
  searchUserSuccess: false,
  searchUserErrorMsg: '',
  queryParams: {
    userId: '',
    name: '',
    phoneNumber: '',
    email: '',
  },
  loadingOutgoingTxn: false,
  blockCryptoValue: false,
  setOutgoingTxnErrMsg: '',

  loadingFiatOutgoingTxn: false,
  blockFiatValue: false,
  setFiatOutgoingTxnErrMsg: '',

  loadingDeactivateAccount: false,
  deactivateAccountSuccess: null,
  deactivateAccountErrMsg: '',
  updateCryptoOutgoingSuccess: false,
  updateFiatOutgoingSuccess: false,
  updateDebitSettingSuccess: false,
  loadingDebit: false,
  blockDebitValue: false,
  setDebitErrMsg: '',

  loadingEditMobile: false,
  editMobileSuccess: false,
  editMobileErrorMsg: '',
  loadingAdminRemarks: false,
  adminRemarks: [],
  adminRemarksErrorMsg: '',
  loadingUserLoginHistory: false,
  userLoginHistory: null,
  userLoginHistoryErrMsg: '',
  loadingRecentSignUpList: false,
  recentSignUpList: [],
  recentSignUpListErrorMsg: '',
  loadingFetchGlobalSearchList: false,
  globalSearchList: [],
  globalSearchListErrorMsg: '',
  loadingRejectedExcelDetails: false,
  rejectedExcelDetails: [],
  rejectedExcelDetailsErrorMsg: '',
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE_REQUEST:
      return {
        ...state,
        loadingUserProfile: true,
        userProfileErrorMsg: '',
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loadingUserProfile: false,
        userProfile: action.payload,
        userProfileErrorMsg: '',
      };
    case GET_USER_PROFILE_FAILED:
      return {
        ...state,
        loadingUserProfile: false,
        userProfile: {},
        userProfileErrorMsg: action.payload,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        loadingUserProfile: false,
        userProfile: action.payload,
        userProfileErrorMsg: '',
      };
    case SEARCH_USER_REQUEST:
      return {
        ...state,
        loadingSearchUser: true,
        userList: [],
        searchUserSuccess: false,
        userFilterRefresh: false,
        searchUserErrorMsg: '',
      };
    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        loadingSearchUser: false,
        userList: action.payload.data,
        queryParams: action.payload.params,
        searchUserSuccess: true,
        userFilterRefresh: false,
        searchUserErrorMsg: null,
      };
    case SEARCH_USER_FAILED:
      return {
        ...state,
        loadingSearchUser: false,
        searchUserSuccess: false,
        userFilterRefresh: true,
        searchUserErrorMsg: action.payload,
      };
    case UPDATE_QUERY_PARAMS:
      return {
        ...state,
        queryParams: { ...action.payload },
      };
    case DEACTIVATE_ACCOUNT_REQUEST:
      return {
        ...state,
        loadingDeactivateAccount: true,
        deactivateAccountSuccess: false,
        deactivateAccountErrMsg: '',
      };
    case DEACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingDeactivateAccount: false,
        deactivateAccountSuccess: true,
        deactivateAccountErrMsg: '',
      };
    case DEACTIVATE_ACCOUNT_FAILED:
      return {
        ...state,
        loadingDeactivateAccount: false,
        deactivateAccountSuccess: false,
        deactivateAccountErrMsg: action.payload,
      };
    case SET_CRYPTO_OUTGOING_TXN_REQUEST:
      return {
        ...state,
        loadingOutgoingTxn: true,
        updateCryptoOutgoingSuccess: false,
        setOutgoingTxnErrMsg: '',
      };
    case SET_CRYPTO_OUTGOING_TXN_SUCCESS:
      return {
        ...state,
        loadingOutgoingTxn: false,
        updateCryptoOutgoingSuccess: true,
        blockCryptoValue: action.payload,
        setOutgoingTxnErrMsg: '',
      };
    case SET_CRYPTO_OUTGOING_TXN_FAILED:
      return {
        ...state,
        loadingOutgoingTxn: false,
        updateCryptoOutgoingSuccess: false,
        blockCryptoValue: { ...state.blockCryptoValue },
        setOutgoingTxnErrMsg: action.payload,
      };
    case SET_FIAT_OUTGOING_TXN_REQUEST:
      return {
        ...state,
        loadingFiatOutgoingTxn: true,
        updateFiatOutgoingSuccess: false,
        setFiatOutgoingTxnErrMsg: '',
      };
    case SET_FIAT_OUTGOING_TXN_SUCCESS:
      return {
        ...state,
        loadingFiatOutgoingTxn: false,
        updateFiatOutgoingSuccess: true,
        blockFiatValue: action.payload,
        setFiatOutgoingTxnErrMsg: '',
      };
    case SET_FIAT_OUTGOING_TXN_FAILED:
      return {
        ...state,
        loadingFiatOutgoingTxn: false,
        updateFiatOutgoingSuccess: false,
        blockFiatValue: { ...state.blockFiatValue },
        setFiatOutgoingTxnErrMsg: action.payload,
      };
    // case RESET_SETTING_STATES:
    // 	return {
    // 		...state,
    // 		loadingOutgoingTxn: false,
    // 		blockCryptoValue: false,
    // 		setOutgoingTxnErrMsg: '',
    // 		loadingDisableOutgoingTxn: false,
    // 		disableOutgoingTxnSuccess: false,
    // 		disableOutgoingTxnErrMsg: '',
    // 		loadingDeactivateAccount: false,
    // 		deactivateAccountSuccess: false,
    // 		deactivateAccountErrMsg: '',
    // 	};
    case SET_DEBIT_REQUEST:
      return {
        ...state,
        loadingDebitValue: true,
        updateDebitSettingSuccess: false,
        setDebitErrMsg: '',
      };
    case SET_DEBIT_SUCCESS:
      return {
        ...state,
        loadingDebitValue: false,
        updateDebitSettingSuccess: true,
        blockDebitValue: action.payload,
        setDebitErrMsg: '',
      };
    case SET_DEBIT_FAILED:
      return {
        ...state,
        loadingDebitValue: false,
        updateDebitSettingSuccess: false,
        blockDebitValue: { ...state.blockDebitValue },
        setDebitErrMsg: action.payload,
      };

    case EDIT_MOBILE_NUMBER_REQUEST:
      return {
        ...state,
        loadingEditMobile: true,
        editMobileSuccess: false,
        editMobileErrorMsg: '',
      };
    case EDIT_MOBILE_NUMBER_SUCCESS:
      return {
        ...state,
        loadingEditMobile: false,
        editMobileSuccess: true,
        editMobileErrorMsg: '',
      };
    case EDIT_MOBILE_NUMBER_FAILED:
      return {
        ...state,
        loadingEditMobile: false,
        editMobileSuccess: false,
        editMobileErrorMsg: action.payload,
      };
    case FETCH_ADMIN_REMARKS_REQUEST:
      return {
        ...state,
        loadingAdminRemarks: true,
        adminRemarks: [],
        adminRemarksErrorMsg: '',
      };
    case FETCH_ADMIN_REMARKS_SUCCESS:
      return {
        ...state,
        loadingAdminRemarks: false,
        adminRemarks: action.payload,
        adminRemarksErrorMsg: '',
      };
    case FETCH_ADMIN_REMARKS_FAILED:
      return {
        ...state,
        loadingAdminRemarks: false,
        adminRemarks: [],
        adminRemarksErrorMsg: action.payload,
      };
    case GET_USER_LOGIN_HISTORY_REQUEST:
      return {
        ...state,
        loadingUserLoginHistory: true,
        userLoginHistory: [],
        userLoginHistoryErrMsg: '',
      };
    case GET_USER_LOGIN_HISTORY_SUCCESS:
      return {
        ...state,
        loadingUserLoginHistory: false,
        userLoginHistory: action.payload,
        userLoginHistoryErrMsg: '',
      };
    case GET_USER_LOGIN_HISTORY_FAILED:
      return {
        ...state,
        loadingUserLoginHistory: false,
        userLoginHistory: null,
        userLoginHistoryErrMsg: action.payload,
      };
    case GET_RECENT_SIGNUP_LIST_REQUEST:
      return {
        ...state,
        loadingRecentSignUpList: true,
        recentSignUpList: [],
        recentSignUpListErrorMsg: '',
      };
    case GET_RECENT_SIGNUP_LIST_SUCCESS:
      return {
        ...state,
        loadingRecentSignUpList: false,
        recentSignUpList: action.payload.data,
        recentSignUpListErrorMsg: '',
      };
    case GET_RECENT_SIGNUP_LIST_FAILURE:
      return {
        ...state,
        loadingRecentSignUpList: false,
        recentSignUpList: [],
        recentSignUpListErrorMsg: action.payload,
      };
    case FETCH_GLOBAL_SEARCH_LIST_REQUEST:
      return {
        ...state,
        loadingFetchGlobalSearchList: true,
        globalSearchList: [],
        globalSearchListErrorMsg: '',
      };
    case FETCH_GLOBAL_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        loadingFetchGlobalSearchList: false,
        globalSearchList: action.payload.data,
        globalSearchListErrorMsg: '',
      };
    case FETCH_GLOBAL_SEARCH_LIST_FAILURE:
      return {
        ...state,
        loadingFetchGlobalSearchList: false,
        globalSearchList: [],
        globalSearchListErrorMsg: action.payload,
      };

    case GET_REJECTED_EXCEL_DETAILS_REQUEST:
      return {
        ...state,
        loadingRejectedExcelDetails: true,
        rejectedExcelDetails: [],
        rejectedExcelDetailsErrorMsg: '',
      };
    case GET_REJECTED_EXCEL_DETAILS_SUCCESS:
      return {
        ...state,
        loadingRejectedExcelDetails: false,
        rejectedExcelDetails: action.payload.data,
        rejectedExcelDetailsErrorMsg: '',
      };
    case GET_REJECTED_EXCEL_DETAILS_FAILURE:
      return {
        ...state,
        loadingRejectedExcelDetails: false,
        rejectedExcelDetails: [],
        rejectedExcelDetailsErrorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
