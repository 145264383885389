import React, { useCallback, useEffect, useState } from 'react';
import { Text, Title,Sprite } from 'components/atoms';
import { Row, Col,Table,Select, Card } from 'antd';

const WalletCards = (props) => {

return(
    <>

      {
    
            Object.keys(props.data).map((key, idx) => {
                return (
                    // <Col span={6} key={`${key}-${idx}`} onClick={getClickedData} drawerData={clickedData}>
                    <Col span={6} key={`${key}-${idx}`} onClick={()=>props.onWalletSelect(key,props.data[key])}>
                         <Card className={props.currentId === key ? "active wallet-cards" : "wallet-cards"} bordered={false}>
                         <Sprite id={key+ '-icon'} width={40} height={40} />
                         <div style={{marginLeft:10}}>
                             <p>{key}</p>
                             <span>
                              {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(props.data[key].totalBalanceInUsd)}
                              </span> 
                              <p><span>Total Coins: {props.data[key].coinLength}</span></p>
                           {/* <span> ${Math.round((props.data[key].totalBalanceInUsd + Number.EPSILON) * 100) / 100 }</span> */}
                         </div>
                         </Card>
                    </Col>)
            })
            
        }
                {/* {props.data(val =>  {
                        return( 
                    <Col span={6}>
                        <Card title="Card title" bordered={false}>
                        {val.data.coinType}
                        </Card>
                      </Col>
                        )
                })} */}
     
         
    </>
);
};
export default WalletCards;