export const KYC_STATUS_REQUEST = 'KYC_STATUS_REQUEST';
export const GET_KYC_STATUS_SUCCESS = 'GET_KYC_STATUS_SUCCESS';
export const GET_KYC_STATUS_FAILED = 'GET_KYC_STATUS_FAILED';
export const BANK_DETAILS_REQUEST = 'BANK_DETAILS_REQUEST';
export const GET_BANK_DETAILS_SUCCESS = 'GET_BANK_DETAILS_SUCCESS';
export const GET_BANK_DETAILS_FAILED = 'GET_BANK_DETAILS_FAILED';
export const UPDATE_KYC_STATUS_REQUEST = 'UPDATE_KYC_STATUS_REQUEST';
export const UPDATE_KYC_STATUS_SUCCESS = 'UPDATE_KYC_STATUS_SUCCESS';
export const UPDATE_KYC_STATUS_FAILED = 'UPDATE_KYC_STATUS_FAILED';
export const FETCH_AML_DATA_REQUEST = 'FETCH_AML_DATA_REQUEST';
export const FETCH_AML_DATA_SUCCESS = 'FETCH_AML_DATA_SUCCESS';
export const FETCH_AML_DATA_FAILED = 'FETCH_AML_DATA_FAILED';
export const UPDATE_PRIMARY_BANK_REQUEST = 'UPDATE_PRIMARY_BANK_REQUEST';
export const UPDATE_PRIMARY_BANK_SUCCESS = 'UPDATE_PRIMARY_BANK_SUCCESS';
export const UPDATE_PRIMARY_BANK_FAILED = 'UPDATE_PRIMARY_BANK_FAILED';
export const ADD_BANK_REQUEST = 'ADD_BANK_REQUEST';
export const ADD_BANK_SUCCESS = 'ADD_BANK_SUCCESS';
export const ADD_BANK_FAILED = 'ADD_BANK_FAILED';
export const DELETE_BANK_REQUEST = 'DELETE_BANK_REQUEST';
export const DELETE_BANK_SUCCESS = 'DELETE_BANK_SUCCESS';
export const DELETE_BANK_FAILED = 'DELETE_BANK_FAILED';
export const UPDATE_BANK_STATUS_REQUEST = 'UPDATE_BANK_STATUS_REQUEST';
export const UPDATE_BANK_STATUS_SUCCESS = 'UPDATE_BANK_STATUS_SUCCESS';
export const UPDATE_BANK_STATUS_FAILED = 'UPDATE_BANK_STATUS_FAILED';
export const FETCH_ID_AND_ADDRESS_DOC_REQUEST =
  'FETCH_ID_AND_ADDRESS_DOC_REQUEST';
export const FETCH_ID_AND_ADDRESS_DOC_SUCCESS =
  'FETCH_ID_AND_ADDRESS_DOC_SUCCESS';
export const FETCH_ID_AND_ADDRESS_DOC_FAILED =
  'FETCH_ID_AND_ADDRESS_DOC_FAILED';
export const FETCH_KYC_DOC_IMAGE_REQUEST = 'FETCH_KYC_DOC_IMAGE_REQUEST';
export const FETCH_KYC_DOC_IMAGE_SUCCESS = 'FETCH_KYC_DOC_IMAGE_SUCCESS';
export const FETCH_KYC_DOC_IMAGE_FAILED = 'FETCH_KYC_DOC_IMAGE_FAILED';
export const UPDATE_BANK_IFSC_REQUEST = 'UPDATE_BANK_IFSC_REQUEST';
export const UPDATE_BANK_IFSC_SUCCESS = 'UPDATE_BANK_IFSC_SUCCESS';
export const UPDATE_BANK_IFSC_FAILED = 'UPDATE_BANK_IFSC_FAILED';
export const GET_KYC_BY_ID_REQUEST = 'GET_KYC_BY_ID_REQUEST';
export const GET_KYC_BY_ID_SUCCESS = 'GET_KYC_BY_ID_SUCCESS';
export const GET_KYC_BY_ID_FAILURE = 'GET_KYC_BY_ID_FAILURE';
export const GET_VENDOR_BY_DOC_REQUEST = 'GET_VENDOR_BY_DOC_REQUEST';
export const GET_VENDOR_BY_DOC_FAILURE = 'GET_VENDOR_BY_DOC_FAILURE';
export const GET_VENDOR_BY_DOC_SUCCESS = 'GET_VENDOR_BY_DOC_SUCCESS';
export const GET_KYC_BY_TYPE_REQUEST = 'GET_KYC_BY_TYPE_REQUEST';
export const GET_KYC_BY_TYPE_SUCCESS = 'GET_KYC_BY_TYPE_SUCCESS';
export const GET_KYC_BY_TYPE_FAILURE = 'GET_KYC_BY_TYPE_FAILURE';
export const REJECT_KYC_REQUEST = 'REJECT_KYC_REQUEST';
export const REJECT_KYC_SUCCESS = 'REJECT_KYC_SUCCESS';
export const REJECT_KYC_FAILURE = 'REJECT_KYC_FAILURE';
export const VERIFY_KYC_REQUEST = 'VERIFY_KYC_REQUEST';
export const VERIFY_KYC_SUCCESS = 'VERIFY_KYC_SUCCESS';
export const VERIFY_KYC_FAILURE = 'VERIFY_KYC_FAILURE';
export const GET_KYC_STATUS_V2_REQUEST = 'GET_KYC_STATUS_V2_REQUEST';
export const GET_KYC_STATUS_V2_SUCCESS = 'GET_KYC_STATUS_V2_SUCCESS';
export const GET_KYC_STATUS_V2_FAILURE = 'GET_KYC_STATUS_V2_FAILURE';
