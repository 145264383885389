import React, { useEffect } from 'react';
import {Route, Redirect} from 'react-router-dom';
import { useGoogleAuth, GoogleAuthContext } from 'components/google-login/GoogleAuthProvider';

const PublicRouter = ({component: Component, ...rest}) => {

    const { isSignedIn, isInitialized,  } = useGoogleAuth();
    console.log(rest);
    console.log(isInitialized);
    if(!isInitialized){
        return (
        <div>
            loading...
        </div>
        )
    }

    return (
        <div>
            <Route {...rest} render={props => (
                !isSignedIn ?
                <Component {...props} /> : 
                <Redirect exact to="/" />
                )} />    
        </div>
    );
};

export default PublicRouter;