import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Select, Table, Input, Form, Modal, InputNumber, Radio } from 'antd';
import {
    PageHeaders,
} from 'components/molecules';
import { Text } from 'components/atoms';
import axios from 'axios';

const AddManualBalance = (props) => {

    const [wallets, setWallets] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState({})
    const [showBalanceBox, setShowBalanceBox] = useState(false)
    const [walletModalVisible, setWalletModalVisible] = useState(false)
    const [openWrappedTokenBox, setOpenWrappedTokenBox] = useState(false)

    const getAllActiveManualWallets = async () => {
        try {
            let response = await axios.get("https://walletbackend.zebpay.co/provider/manualWallets", {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
                }
            });

            response = response.data.data
            setWallets(response)
        } catch (err) {
            alert(err)
        }
    }

    const addWalletBalance = async (wallet, balance) => {
        try {
            await axios.post('https://walletbackend.zebpay.co/provider/addManualBalance', {
                id: wallet.id,
                decimalPlaces: wallet.decimalPlaces,
                balance: parseFloat(balance),
                coinType: wallet.coinType,
                walletId: wallet.walletId,
                rootAddress: wallet.rootAddress,
                provider: wallet.provider
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
                }
            })

            console.log('wallet balance entered');
        } catch (error) {
            console.error(error)
            alert(`${error}, ${error.message}`)
        }
    }

    const addWallet = async (wallet) => {
            const res = await axios.post('https://walletbackend.zebpay.co/provider/addManualWallet', {
                coinType: wallet.coinType,
                rootAddress: wallet.rootAddress,
                walletType: wallet.walletType,
                provider: wallet.provider,
                decimalPlaces: wallet.decimalPlaces,
                isUTXObase: wallet.isUTXObase,
                displayName: wallet.displayName,
                notes: wallet.notes, 
                isDefiToken: wallet.isDefiToken,
                WrappedTokenOf: wallet.WrappedTokenOf
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
                }
            })

            if(res.data.status === 201){
                alert('Wallet added successfully')
                await getAllActiveManualWallets()
            }
            else if(res.data.status === 400){
                alert(res.data.error)
                throw new Error(res.data.error)
            }
    }

    useEffect(async () => {
        await getAllActiveManualWallets();
    }, []);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const handleCancelForBalanceModal = () => {
        setSelectedWallet({})
        setShowBalanceBox(false)
        props.onData(false) // setting balance modal visible as false
    }

    const handleCancelForWalletModal = () => {
        setWalletModalVisible(false) // setting wallet modal visible as false
    }

    const onFinishBalanceModal = async (formdata) => {
        try {
            if (formdata.balance.walletBalance !== undefined) {
                await addWalletBalance(selectedWallet, formdata.balance.walletBalance)
                setSelectedWallet({})
                props.onData(false) // setting isModalVisible as false
            }
            else{
                alert('Fill the details')
            }
        } catch (err) {
            console.log(err.message);
            console.log(err.data);
            alert('some error occured. not able to add wallet balance. please try again')
        }
    }

    const onFinishWalletModal = async (formdata) => {
        try {
            let validCall = true

            formdata.wallet.walletType = 'MANUAL'
            formdata.wallet.provider = 25
            formdata.wallet.decimalPlaces = Math.floor(parseInt(formdata.wallet.decimalPlaces))
            formdata.wallet.isUTXObase = (formdata.wallet.isUTXObase === 'TRUE' ? true : false)
            formdata.wallet.isDefiToken = (formdata.wallet.isDefiToken === 'TRUE' ? true : false)
            formdata.wallet.WrappedTokenOf = (formdata.wallet.isDefiToken === true ? formdata.wallet.WrappedTokenOf : '')
            
            for(let key in formdata.wallet){
                if(formdata.wallet[key] === undefined){
                    validCall = false
                    break
                }
            }
            
            if (validCall) {
                await addWallet(formdata.wallet)
                setWalletModalVisible(false)
                setOpenWrappedTokenBox(false)
            }
            else{
                alert('Fill all the fields of the form')
            }   
        } catch (error) {
            console.log(error);
            alert(error.message);
        }
    }

    const handleDisplayNameChange = (value, option) => {
        setSelectedWallet(wallets[option.key])
        setShowBalanceBox(true)
    }

    const handleResetBalanceModal = () => {
        setSelectedWallet({})
        setShowBalanceBox(false)
    }

    const handleAddNewWalletClick = () => {
        setWalletModalVisible(true)
    }

    const handleResetWalletModal = () => {
        setOpenWrappedTokenBox(false)
    }

    return (
        <div>
            <Modal title="Add Manual Wallet Balance" mask={true} visible={props.isModalVisible} destroyOnClose={true} onCancel={handleCancelForBalanceModal} footer={[]}>
                <Form {...layout} name="nest-messages" id='addmanualbalance' onFinish={onFinishBalanceModal} validateMessages={validateMessages}>
                    <Form.Item name={['balance', 'displayName']} labelAlign='left' label="Display Name" rules={[]}>
                        <Select showSearch onChange={handleDisplayNameChange} defaultValue={"Select Display Name"}>
                            {wallets.map((item, index) => {
                                return (<Select.Option key={index} value={item['displayName']}>
                                    {item['displayName']}
                                </Select.Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item name={['balance', 'id']} label="Id" labelAlign='left' rules={[]}>
                        <Text size="md">
                            {selectedWallet.id ? selectedWallet.id : ''}
                        </Text>
                    </Form.Item>
                    <Form.Item name={['balance', 'coinType']} label="Coin Type" labelAlign='left' rules={[]}>
                        <Text size="md">
                            {selectedWallet.coinType ? selectedWallet.coinType : ''}
                        </Text>
                    </Form.Item>

                    <Form.Item name={['balance', 'rootAddress']} label="Root Address" labelAlign='left' rules={[]}>
                        <Text size="md">
                            {selectedWallet.rootAddress ? selectedWallet.rootAddress : ''}
                        </Text>
                    </Form.Item>
                    <Form.Item name={['balance', 'decimalPlaces']} label="Decimal Places" labelAlign='left' rules={[]}>
                        <Text size="md">
                            {selectedWallet.decimalPlaces ? selectedWallet.decimalPlaces : ''}
                        </Text>
                    </Form.Item>
                    <Form.Item name={['balance', 'provider']} label="Provider" labelAlign='left' rules={[]}>
                        <Text size="md">
                            {selectedWallet.provider ? selectedWallet.provider : ''}
                        </Text>
                    </Form.Item>
                    <Form.Item name={['balance', 'walletType']} label="Wallet Type" labelAlign='left' rules={[]}>
                        <Text size="md">
                            {selectedWallet.walletType ? selectedWallet.walletType : ''}
                        </Text>
                    </Form.Item>
                    <Form.Item name={['balance', 'walletBalance']} label="Wallet Balance" labelAlign='left' rules={[]}>
                        {showBalanceBox ? <Input placeholder={`Enter ${selectedWallet.coinType} balance`} type='number' autoFocus /> : <Text size="md">
                            {selectedWallet.walletBalance ? selectedWallet.walletBalance : ''}
                        </Text>}
                    </Form.Item>




                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button onClick={handleResetBalanceModal} type="secondary" htmlType="reset">
                            Reset
                        </Button>
                        <Button type="primary" key="addmanualbalance" htmlType="submit" style={{ marginLeft: "20px" }}>
                            Submit
                        </Button>
                        <Button onClick={handleAddNewWalletClick} type="primary" style={{ marginLeft: "20px" }}>
                            Add New Wallet
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>

            <Modal title="Add New Wallet" mask={true} visible={walletModalVisible} destroyOnClose={true} onCancel={handleCancelForWalletModal} footer={[]}>
                <Form {...layout} name="nest-messages" id='addwallet' onFinish={onFinishWalletModal} validateMessages={validateMessages}>
                    <Form.Item name={['wallet', 'walletType']} label="Wallet Type" labelAlign='left' rules={[]}>
                        <Text size="md">
                            {'Manual'}
                        </Text>
                    </Form.Item>
                    <Form.Item name={['wallet', 'provider']} label="Provider" labelAlign='left' rules={[]}>
                        <Text size="md">
                            {25}
                        </Text>
                    </Form.Item>
                    <Form.Item name={['wallet', 'coinType']} label="Coin Type" labelAlign='left' rules={[]}>
                        <Input autoFocus placeholder='Enter Coin Type' />
                    </Form.Item>
                    <Form.Item name={['wallet', 'rootAddress']} label="Root Address" labelAlign='left' rules={[]}>
                        <Input autoFocus placeholder='Enter Root Address' />
                    </Form.Item>
                    <Form.Item name={['wallet', 'decimalPlaces']} label="Decimal Places" labelAlign='left' rules={[]}>
                        <Input autoFocus type='number' placeholder='Enter Decimal Places' />
                    </Form.Item>
                    <Form.Item name={['wallet', 'isUTXObase']} label="is UTXO base" labelAlign='left' rules={[]}>
                        <Select >
                            {['TRUE', 'FALSE'].map(item => (
                                <Select.Option key={item.key} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={['wallet', 'isDefiToken']} label="is Defi token" labelAlign='left' rules={[]}>
                        <Select onSelect={(x) => {
                            if (x === 'TRUE') {
                                setOpenWrappedTokenBox(true)
                            }
                            else {
                                setOpenWrappedTokenBox(false)
                            }
                        }}>
                            {['TRUE', 'FALSE'].map(item => (
                                <Select.Option key={item.key} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={['wallet', 'WrappedTokenOf']} label="Wrapped Token Of" labelAlign='left' rules={[]}>
                        {openWrappedTokenBox ? <Input autoFocus placeholder='Wrapped Token Of' /> : ''}
                    </Form.Item>
                    <Form.Item name={['wallet', 'displayName']} label="Display Name" labelAlign='left' rules={[]}>
                        <Input autoFocus placeholder='Enter Display Name' />
                    </Form.Item>
                    <Form.Item name={['wallet', 'notes']} label="Notes" labelAlign='left' rules={[]}>
                        <Input autoFocus placeholder='Enter Notes' />
                    </Form.Item>



                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button onClick={handleResetWalletModal} type="secondary" htmlType="reset">
                            Reset
                        </Button>
                        <Button type="primary" key="addwallet" htmlType="submit" style={{ marginLeft: "20px" }}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>

        </div>
    );
};

export default AddManualBalance;
