import {
  KYC_STATUS_REQUEST,
  GET_KYC_STATUS_SUCCESS,
  GET_KYC_STATUS_FAILED,
  BANK_DETAILS_REQUEST,
  GET_BANK_DETAILS_SUCCESS,
  GET_BANK_DETAILS_FAILED,
  UPDATE_KYC_STATUS_REQUEST,
  UPDATE_KYC_STATUS_SUCCESS,
  UPDATE_KYC_STATUS_FAILED,
  FETCH_AML_DATA_REQUEST,
  FETCH_AML_DATA_SUCCESS,
  FETCH_AML_DATA_FAILED,
  UPDATE_PRIMARY_BANK_REQUEST,
  UPDATE_PRIMARY_BANK_SUCCESS,
  UPDATE_PRIMARY_BANK_FAILED,
  ADD_BANK_REQUEST,
  ADD_BANK_SUCCESS,
  ADD_BANK_FAILED,
  DELETE_BANK_REQUEST,
  DELETE_BANK_SUCCESS,
  DELETE_BANK_FAILED,
  UPDATE_BANK_STATUS_REQUEST,
  UPDATE_BANK_STATUS_SUCCESS,
  UPDATE_BANK_STATUS_FAILED,
  FETCH_ID_AND_ADDRESS_DOC_REQUEST,
  FETCH_ID_AND_ADDRESS_DOC_SUCCESS,
  FETCH_ID_AND_ADDRESS_DOC_FAILED,
  FETCH_KYC_DOC_IMAGE_REQUEST,
  FETCH_KYC_DOC_IMAGE_SUCCESS,
  FETCH_KYC_DOC_IMAGE_FAILED,
  UPDATE_BANK_IFSC_REQUEST,
  UPDATE_BANK_IFSC_SUCCESS,
  UPDATE_BANK_IFSC_FAILED,
  GET_KYC_BY_TYPE_REQUEST,
  GET_KYC_BY_TYPE_SUCCESS,
  GET_KYC_BY_TYPE_FAILURE,
  REJECT_KYC_REQUEST,
  REJECT_KYC_FAILURE,
  REJECT_KYC_SUCCESS,
  VERIFY_KYC_REQUEST,
  VERIFY_KYC_FAILURE,
  VERIFY_KYC_SUCCESS,
  GET_VENDOR_BY_DOC_REQUEST,
  GET_VENDOR_BY_DOC_FAILURE,
  GET_VENDOR_BY_DOC_SUCCESS,
  GET_KYC_STATUS_V2_REQUEST,
  GET_KYC_STATUS_V2_SUCCESS,
  GET_KYC_STATUS_V2_FAILURE,
} from './kycActionTypes';

const initialState = {
  loadingKYCStatus: false,
  kycStatus: {},
  kycStatusErrorMsg: '',
  loadingBankDetails: false,
  bankDetails: [],
  bankDetailsErrorMsg: '',
  updatingKYCStatus: false,
  kycStatusUpdated: false,
  kycStatusUpdateErrMsg: '',
  loadingAMLData: false,
  amlData: {},
  amlDataErrMsg: '',
  updatingPrimaryBank: false,
  primaryBankUpdated: false,
  primaryBankUpdateErrMsg: '',
  loadingBankAdd: false,
  bankAdded: false,
  addBankErrorMsg: '',
  bankDeleted: false,
  loadingBankDelete: false,
  deleteBankErrorMsg: '',
  bankstatusUpdated: false,
  loadingBankStatusUpdate: false,
  updateBankStatusErrorMsg: '',
  loadingIDAndAddressDoc: false,
  idAndAddressDoc: {},
  idAndAddressDocErrMsg: '',
  loadingKycDocImage: false,
  kycDocImage: '',
  kycDocImageErrorMsg: '',
  updatingBankIFSC: false,
  bankIFSCUpdated: false,
  bankIFSCErrMsg: '',
  loadingKycList: false,
  kycList: [],
  loadingRejectKyc: false,
  rejectKycSuccess: false,
  loadingVerifyKyc: false,
  verifyKycSuccess: false,
  loadingVendorResponse: false,
  vendorResponse: {},
  loadingKycStatusV2: false,
  kycStatusV2: {},
};

const kycReducer = (state = initialState, action) => {
  switch (action.type) {
    case KYC_STATUS_REQUEST:
      return {
        ...state,
        loadingKYCStatus: true,
        kycStatus: {},
        kycStatusErrorMsg: '',
      };
    case GET_KYC_STATUS_SUCCESS:
      return {
        ...state,
        loadingKYCStatus: false,
        kycStatus: action.payload,
        kycStatusErrorMsg: '',
      };
    case GET_KYC_STATUS_FAILED:
      return {
        ...state,
        loadingKYCStatus: false,
        kycStatus: {},
        kycStatusErrorMsg: action.payload,
      };
    case BANK_DETAILS_REQUEST:
      return {
        ...state,
        loadingBankDetails: true,
        bankDetails: [],
        bankDetailsErrorMsg: '',
      };
    case GET_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        loadingBankDetails: false,
        bankDetails: action.payload,
        bankDetailsErrorMsg: '',
      };
    case GET_BANK_DETAILS_FAILED:
      return {
        ...state,
        loadingBankDetails: false,
        bankDetails: [],
        bankDetailsErrorMsg: action.payload,
      };
    case UPDATE_KYC_STATUS_REQUEST:
      return {
        ...state,
        updatingKYCStatus: true,
        kycStatusUpdated: false,
        kycStatusErrorMsg: '',
      };
    case UPDATE_KYC_STATUS_SUCCESS:
      return {
        ...state,
        updatingKYCStatus: false,
        kycStatusUpdated: true,
        kycStatusUpdateErrMsg: '',
      };
    case UPDATE_KYC_STATUS_FAILED:
      return {
        ...state,
        updatingKYCStatus: false,
        kycStatusUpdated: false,
        kycStatusUpdateErrMsg: action.payload,
      };
    case FETCH_AML_DATA_REQUEST:
      return {
        ...state,
        loadingAMLData: true,
        amlData: {},
        amlDataErrMsg: '',
      };
    case FETCH_AML_DATA_SUCCESS:
      return {
        ...state,
        loadingAMLData: false,
        amlData: action.payload,
        amlDataErrMsg: '',
      };
    case FETCH_AML_DATA_FAILED:
      return {
        ...state,
        loadingAMLData: false,
        amlData: {},
        amlDataErrMsg: action.payload,
      };
    case UPDATE_PRIMARY_BANK_REQUEST:
      return {
        ...state,
        updatingPrimaryBank: true,
        primaryBankUpdated: false,
        primaryBankUpdateErrMsg: '',
      };
    case UPDATE_PRIMARY_BANK_SUCCESS:
      return {
        ...state,
        updatingPrimaryBank: false,
        primaryBankUpdated: action.payload,
        primaryBankUpdateErrMsg: '',
      };
    case UPDATE_PRIMARY_BANK_FAILED:
      return {
        ...state,
        updatingPrimaryBank: false,
        primaryBankUpdated: false,
        primaryBankUpdateErrMsg: action.payload,
      };
    case ADD_BANK_REQUEST:
      return {
        ...state,
        bankAdded: false,
        loadingBankAdd: true,
        addBankErrorMsg: '',
      };
    case ADD_BANK_SUCCESS:
      return {
        ...state,
        bankAdded: action.payload,
        loadingBankAdd: false,
        addBankErrorMsg: '',
      };
    case ADD_BANK_FAILED:
      return {
        ...state,
        bankAdded: false,
        loadingBankAdd: false,
        addBankErrorMsg: action.payload,
      };

    case DELETE_BANK_REQUEST:
      return {
        ...state,
        bankDeleted: false,
        loadingBankDelete: true,
        deleteBankErrorMsg: '',
      };
    case DELETE_BANK_SUCCESS:
      return {
        ...state,
        bankDeleted: action.payload,
        loadingBankDelete: false,
        deleteBankErrorMsg: '',
      };
    case DELETE_BANK_FAILED:
      return {
        ...state,
        bankDeleted: false,
        loadingBankDelete: '',
        deleteBankErrorMsg: action.payload,
      };

    case UPDATE_BANK_STATUS_REQUEST:
      return {
        ...state,
        bankstatusUpdated: false,
        loadingBankStatusUpdate: true,
        updateBankStatusErrorMsg: '',
      };
    case UPDATE_BANK_STATUS_SUCCESS:
      return {
        ...state,
        bankstatusUpdated: action.payload,
        loadingBankStatusUpdate: false,
        updateBankStatusErrorMsg: '',
      };
    case UPDATE_BANK_STATUS_FAILED:
      return {
        ...state,
        bankstatusUpdated: false,
        loadingBankStatusUpdate: false,
        updateBankStatusErrorMsg: action.payload,
      };
    case FETCH_ID_AND_ADDRESS_DOC_REQUEST:
      return {
        ...state,
        loadingIDAndAddressDoc: true,
        // idAndAddressDoc: {},
        idAndAddressDocErrMsg: '',
      };
    case FETCH_ID_AND_ADDRESS_DOC_SUCCESS:
      return {
        ...state,
        loadingIDAndAddressDoc: false,
        idAndAddressDoc: action.payload,
        idAndAddressDocErrMsg: '',
      };
    case FETCH_ID_AND_ADDRESS_DOC_FAILED:
      return {
        ...state,
        loadingIDAndAddressDoc: false,
        idAndAddressDoc: {},
        idAndAddressDocErrMsg: action.payload,
      };

    case FETCH_KYC_DOC_IMAGE_REQUEST:
      return {
        ...state,
        loadingKycDocImage: true,
        kycDocImage: '',
        kycDocImageErrorMsg: '',
      };
    case FETCH_KYC_DOC_IMAGE_SUCCESS:
      return {
        ...state,
        loadingKycDocImage: false,
        kycDocImage: action.payload,
        kycDocImageErrorMsg: '',
      };
    case FETCH_KYC_DOC_IMAGE_FAILED:
      return {
        ...state,
        loadingKycDocImage: false,
        kycDocImage: {},
        kycDocImageErrorMsg: action.payload,
      };

    case UPDATE_BANK_IFSC_REQUEST:
      return {
        ...state,
        updatingBankIFSC: true,
        bankIFSCUpdated: false,
        bankIFSCErrMsg: '',
      };
    case UPDATE_BANK_IFSC_SUCCESS:
      return {
        ...state,
        updatingBankIFSC: false,
        bankIFSCUpdated: true,
        bankIFSCErrMsg: '',
      };
    case UPDATE_BANK_IFSC_FAILED:
      return {
        ...state,
        updatingBankIFSC: false,
        bankIFSCUpdated: false,
        bankIFSCErrMsg: action.payload,
      };
    case GET_KYC_BY_TYPE_REQUEST:
      return {
        ...state,
        loadingKycList: true,
        kycList: action.payload,
      };
    case GET_KYC_BY_TYPE_SUCCESS:
    case GET_KYC_BY_TYPE_FAILURE:
      return {
        ...state,
        loadingKycList: false,
        kycList: action.payload,
      };
    case REJECT_KYC_REQUEST:
      return {
        ...state,
        loadingRejectKyc: true,
        rejectKycSuccess: action.payload,
      };
    case REJECT_KYC_FAILURE:
    case REJECT_KYC_SUCCESS:
      return {
        ...state,
        loadingRejectKyc: false,
        rejectKycSuccess: action.payload,
      };
    case VERIFY_KYC_REQUEST:
      return {
        ...state,
        loadingVerifyKyc: true,
        verifyKycSuccess: action.payload,
      };
    case VERIFY_KYC_FAILURE:
    case VERIFY_KYC_SUCCESS:
      return {
        ...state,
        loadingVerifyKyc: false,
        verifyKycSuccess: action.payload,
      };
    case GET_VENDOR_BY_DOC_REQUEST:
      return {
        ...state,
        loadingVendorResponse: true,
        vendorResponse: action.payload,
      };
    case GET_VENDOR_BY_DOC_FAILURE:
    case GET_VENDOR_BY_DOC_SUCCESS:
      return {
        ...state,
        loadingVendorResponse: false,
        vendorResponse: action.payload,
      };
    case GET_KYC_STATUS_V2_REQUEST:
      return {
        ...state,
        loadingKycStatusV2: true,
        kycStatusV2: {},
      };
    case GET_KYC_STATUS_V2_SUCCESS:
    case GET_KYC_STATUS_V2_FAILURE:
      return {
        ...state,
        loadingKycStatusV2: false,
        kycStatusV2: action.payload,
      };
    default:
      return state;
  }
};

export default kycReducer;
