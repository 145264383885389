import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Button,Skeleton,Select,DatePicker,Table, Input } from 'antd';
import {
  KycAnalyticsCard,
  PageHeaders,
  PreviewUserCard,
} from 'components/molecules';
import axios from 'axios';
import { Sprite } from 'components/atoms';
import { useHistory } from 'react-router';
import moment from 'moment';
import { P } from '@antv/g2plot';
import FileDownload from 'js-file-download'
import { get } from 'https';
import { alertErrorMessage } from 'utils/ErrorAlerter';
import AddManualBalance from './AddManualBalance';
import AddManualPaperBalance from './AddManualPaperBalance';

const Reports = () => {
  const dateFormat = 'YYYY/MM/DD HH:mm:ss';
  const { Option } = Select; 
  
  const [assetsStore,setAssetsStore] = useState([]);
  const [disabled,setDisabled]=useState(true)
  const [loading, setLoading] = useState(false);
  const [csvLoading, setcsvLoading] = useState(false);
  const [myReports,setMyReports] = useState([]);
  const [myWalletReports,setMyWalletReports] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [totalBalance,setTotalBalance] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isPaperBalanceModalVisible, setIsPaperBalanceModalVisible] = useState(false)
  const queryString = require('query-string');
function disabledDate(current) {
  // Can not select days before today and today
  return current && current.valueOf() > Date.now();
}
  const token =  localStorage.getItem('returnedToken');
  // const token =  localStorage.getItem('tokenid');
  const email=localStorage.getItem('email');
  const history = useHistory();
    /*
* On getAssets handler
* if token then fetch data using axios and setAssetsStore (select box) on getAssets handler 
* @return - null
*/
  const getAssets = async() =>{
    try {
      setLoading(true);
      if(token){
    //  const response = await axios.get("https://walletbackend.zebpay.co/wallet/coin/coins", {
    //   headers: {
    //     Authorization: 'Bearer ' + token
    //   }
    //   });
    const response = await axios.get("https://walletbackend.zebpay.co/additional-services/supportedTokens", {
      headers: {
        Authorization: 'Bearer ' + token
      }
      });
     response.data.data.push({
      "coinSymbol":"All Wallet Balance"
     })
     response.data.data.push({
      "coinSymbol":"All Exchange Balance"
     })

     setAssetsStore(response.data.data);
     
    //  setReportData(response.data.data.newData);
    //  console.log(response.data.data.newData)
    setLoading(false);
      }
      else{
        history.push('/login');
      }
    } catch (err) {
      // alert(`${err}\n${alertErrorMessage(err.response.status)}`)
      alert(`${err}, ${err.response.data.message}`)
    }
  }
  useEffect(() => {
    /* if there is coin list in localstorage
    then do nothing
    else getAssets();
      const parsed = queryString.parse(location.search);
console.log(parsed);
if parse
    */
   
    getAssets();
    const parsed = queryString.parse(location.search);
    console.log(parsed);

    if(parsed && parsed.coin){
      setSelectedCoin(parsed.coin.toUpperCase());
      setSelectedDate(parsed.ts);
      fetchReports(parsed.coin.toUpperCase(),parsed.ts);
      if(parsed.coin==="All Wallet Balance"||parsed.coin==="All Exchange Balance"){
        setDisabled(false)
      }else{
        setDisabled(true);
      }
    }
   
    // setLocalData(localData);
    // console.log(myReports)
  // console.log(localData[0].paperBalanceSum);
  }, []);
    /*
* On onReportChange handler
* setSelectedCoin on onReportChange handler 
* @return - null
*/
  const onReportChange = (value) => {
    setSelectedCoin(value);
    if(value==="All Wallet Balance"|| value==='All Exchange Balance'){
      setDisabled(false)
    }else{
      setDisabled(true);
    }
    // console.log(selectedCoin);
  };
    /*
* On onDateChange handler
* setSelectedDate on onDateChange handler 
* @return - null
*/
const onDateChange = (date, dateString) =>{
  // console.log(dateString);
  setSelectedDate(dateString);
}
/*Fetch Button HAndle*/
    /*
* On fetchReports handler
* Fetch data using getReports handler on fetchReports handler 
* @return - coin, date
*/
  const fetchReports = async(coin,date) => {
    // const selectedCoin = selectedCoin || coin || ; 
    // const selectedTs = date || selectedDate; 
    if(coin == undefined || date==undefined){
      alert("Please select both asset and a valid date");
      return;
    }

    setLoading(true);

    try{
      const selectedData = await getReports(coin,date)
      setMyReports(selectedData.data.data.onPaperBalance);
      setMyWalletReports(selectedData.data.data.walletBalance);
      setTotalBalance(
        {
          paperBalance:selectedData.data.data.paperBalanceSum,
          walletBalance:selectedData.data.data.walletBalanceSum
        }
        
      );
      setLoading(false);
    }
    catch(err){
      console.log(err);
      setLoading(false);
      alert("Some error occured. Please refresh and try again");
      return;
    }
  }
  const getReports = (coin,ts)=>{
    return axios.get(`https://walletbackend.zebpay.co/wallet/coin/balances?coin=${coin}&ts=${ts}`, {
                    headers: {
                      Authorization: 'Bearer ' + token
                    }
                    })
                    // .then(res=>{
                    //   // return res;
                    // }).catch(err=>{
                    //   console.log("error occured in fetching data");
                    //   setLoading(false);
                    // });
  }


  const downloadCSV = async(coin,date) => {

      if(coin == undefined||date==undefined){
        alert("Please select an asset and a valid date");
        setcsvLoading(false);
        return;
      }
      else{
        setcsvLoading(true);
        await getCSV(coin,date);
        setcsvLoading(false);
      }// console.log(myReports);
  }
  const getCSV = (coin,ts)=>{
    return axios.get(`https://walletbackend.zebpay.co/wallet/coin/balance.csv?coin=${coin}&ts=${ts}&authorization=${token}`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
      })
    .then(res=>{
      if(res){
        FileDownload(res.data,"data.csv")
      }
    })
    .catch(err => {
      console.log("error occured while fetching data " , err);
      alert("Some error occured. Please refresh and try again")
    });
  }

  const emailCSV = async function(coin,ts){
    if(ts==undefined){
      alert("Please enter valid date");
      return;
    }
    if(["All Wallet Balance", "All Exchange Balance"].includes(coin)){
      return getExchangeOrWalletBalanceForEmail(coin,ts)
    }else{
      return emailBalanceCSV(coin,ts)
    }
  }

  const emailBalanceCSV= async function(coin,ts){
    const params={
      ts:ts,
      coin:coin,
      authorization:token,
      email:email
    }
    return axios({
      method:"get",
      // url:'http://localhost:4001/wallet/coin/all-wallet-balances',
      url:'https://walletbackend.zebpay.co/wallet/coin/emailcsv',
      // url:'http://blockanalytics-api.zebuat.com/wallet/coin/all-wallet-balances',
      params: params,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then(res=>{
    if(res){
      return;
    }
  })
  .catch(error=>{
    console.log("error occured in getting wallet Balance",error);
    return;
})
  }

  const getExchangeOrWalletBalanceForEmail=async function(coin,ts){
    if(ts==undefined){
      alert("Please enter valid date");
      return;
    }
    alert("CSV will be mailed shortly.\nClick OK to recieve email");
    // setLoading(true);
    if(coin==='All Wallet Balance'){
    await getWalletBalance(ts);
    }
    if(coin==='All Exchange Balance'){
      await getExchangeBalance(ts);
    }
    // setLoading(false);
  }

  const getWalletBalance= async function(ts){
      console.log("current ts",ts)
      const params={
        ts:ts,
        authorization:token,
        email:email
      }
      return axios({
        method:"get",
        // url:'http://localhost:4001/wallet/coin/all-wallet-balances',
        url:'https://walletbackend.zebpay.co/wallet/coin/all-wallet-balances',
        // url:'http://blockanalytics-api.zebuat.com/wallet/coin/all-wallet-balances',
        params: params,
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .then(res=>{
      if(res){
        return;
      }
    })
    .catch(error=>{
      console.log("error occured in getting wallet Balance",error);
      // setLoading(false);
      return;
  })
}

const getExchangeBalance=async function(ts){
  console.log("current ts",ts)
  const params={
    ts:ts,
    authorization:token,
    email:email
  }
  return axios({
    method:"get",
    // url:'http://localhost:4001/wallet/coin/all-exchange-balances',
    url:'https://walletbackend.zebpay.co/wallet/coin/all-exchange-balances',
    // url:'http://blockanalytics-api.zebuat.com/wallet/coin/all-exchange-balances',
    params: params,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
  .then(res=>{
  if(res){
    return;
  }
})
.catch(error=>{
  console.log("error occured in getting wallet Balance",error);
  setLoading(false);
  return;
})
}

  /*Table data*/

  const modifiedData = myReports.map(({body, ...item}) => ({
    ...item,
    key: item.id,
    }));

    const columns = [
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Label',
        dataIndex: 'label',
      },
      {
        title: 'Balance',
        dataIndex:'balance',
        align:'right',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.balance - b.balance,
        render:(text, row, index)=>{
          return (
            <b>{new Intl.NumberFormat().format(text)}</b>
          )
        }
      },
      {
        title: 'Time',
        dataIndex:'ts_IN',
        align:'right',
      },
      {
        title: 'Updated By',
        dataIndex:'updatedBy'
      }
    
    ];

    console.log(myWalletReports);

    const handleModal = (data) => {
      setIsModalVisible(data)
    }

    const handlePaperBalanceModal = (data) => {
      setIsPaperBalanceModalVisible(data)
    }

    const modifiedWalletData = myWalletReports.map(({body, ...item}) => ({
      ...item,
      key: item.id,
      }));
      const walletColumns = [
        {
          title: 'Name',
          dataIndex: 'name',
        },
        {
          title: 'Wallet Type',
          dataIndex: 'walletType',
          render: (text, row, index) => {
            // console.log(row);
          return (
            <div className="vertical-mid">
              <Sprite id={row.walletType.toLowerCase() + '-icon'} width={20} height={20}/>
              <span>{row.walletType}</span>
            </div>
            );
          },
        },
        {
          title: 'Provider',
          dataIndex:'storageType'
        },
        {
          title: 'Balance',
          dataIndex:'balance',
          align:'right',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.balance - b.balance,
          render:(text, row, index)=>{
            return (
              <b>{new Intl.NumberFormat().format(text)}</b>
            )
          }
        },
        {
          title: 'Time - IST',
          dataIndex:'ts_IN',
          align:'right'
        },
        {
          title: 'Time - UTC',
          dataIndex:'ts',
          align:'right'
        }
      
      ];
      const skeleton = (
        <div className="z2-card padded" style={{minHeight: "400px"}} > 
          <Skeleton active paragraph={{ rows: 2 }} />
        </div>
      )
/*Tab active inactive */
      const [isActive, setActiveTab] = useState("false");

      const toggleClass = () => {
        setActiveTab(!isActive); 
       };
  return (
    <div>
      <Row gutter={[20]}>
        <Col span={24}>
          <PageHeaders
            title={'Welcome - Zebpay Admin'}
            section={'Wallet'}
            subSection={'Reports'}
          />
        </Col>
      </Row>
      {/* {permissions[UI_ACCESS_SCOPES.readUser] ? ( */}
        <>
          <Row gutter={[20, 20]} wrap={false}>
            <Col flex="auto">
              <Row gutter={[20, 20]}>
                <Col style={{minWidth:"50%", width:'100%', paddingLeft: '0px', paddingRight: '0px'}}>
                  <div className={'z2-card padded no-shadow'}>
                  <Select
                  showSearch
                  style={{ width: 200}}
                  placeholder="Select Assets"
                  optionFilterProp="children"
                  onChange={onReportChange}
                  value={selectedCoin}
                  >
                    {assetsStore.map((val,index) =>  {
                  return(
                      <Select.Option key={val.coinSymbol} value={val.coinSymbol}>{val.coinSymbol}</Select.Option>
                   )})}
                </Select>
                <DatePicker 
                  onChange={onDateChange} 
                  disabledDate={disabledDate} 
                  placeholder="Select Date" 
                  allowClear={false} 
                  format={dateFormat} 
                  style={{marginLeft:"20px",width: 200}}
                  value={selectedDate?moment(selectedDate):""}
                  showTime
                  />
                  <Button type="primary" loading={loading} onClick = {()=>fetchReports(selectedCoin,selectedDate)} style={{marginLeft:"20px"}} disabled={!disabled}>Fetch Details</Button>
                  <Button type="primary" loading={csvLoading} onClick = {()=>downloadCSV(selectedCoin,selectedDate)} style={{marginLeft:"20px"}} disabled={!disabled}>Download CSV for CoinBalance</Button>
                  <Button type="primary" onClick = {()=>emailCSV(selectedCoin,selectedDate)} style={{marginLeft:"20px"}}>Email CSV</Button>
                  {/* <Button type="primary" onClick = {()=>setIsModalVisible(true)} style={{marginLeft:"20px"}}>Add Manual Wallet Balance</Button> */}
  
                  {/* <Input >Coin Not Found, Type Here</Input> */}
                  </div>
                </Col>
                <Col span={24}>
                {/* <h2>{}{selectedCoin} </h2> */}
                </Col>
              </Row>

              <Row gutter={[20, 20]}>
                <Col style={{minWidth:"50%", width:'100%', paddingLeft: '0px', paddingRight: '0px'}}>
                  <div className={'z2-card padded no-shadow'}>
                  <Button type="primary" onClick = {()=>setIsPaperBalanceModalVisible(true)} style={{marginLeft:"20px"}}>Add Manual Paper Balance</Button>
                  <Button type="primary" onClick = {()=>setIsModalVisible(true)} style={{marginLeft:"20px"}}>Add Manual Wallet Balance</Button>
                  <AddManualPaperBalance isModalVisible={isPaperBalanceModalVisible} onData={handlePaperBalanceModal}></AddManualPaperBalance>
                  <AddManualBalance isModalVisible={isModalVisible} onData={handleModal}></AddManualBalance>
                  </div>
                </Col>
                <Col span={24}>
                </Col>
              </Row>
              
              <Row gutter={[20, 20]} className="Reports-Cont">
                <div span={12} style={{marginBottom: '1rem'}}>
                  <div className="z2-card padded no-shadow">
                    <h3>Paper Balance:</h3>
                 {totalBalance.paperBalance ?
                    <h3 style={{float:"right"}}>{new Intl.NumberFormat().format(totalBalance.paperBalance)+" Tokens"}</h3>
                  :
                  null
                 }
                    </div>
                </div>
                
                <div span={12}>
              
                <Table columns={columns} dataSource={modifiedData} loading= {loading} pagination={false}/>
                
                  </div>
               
              </Row>    
                
              <Row gutter={[20, 20]} className="Reports-Cont">
                {/* <Col span={isActive ? "12" : "24"}> */}
                
                <div span={12} style={{marginBottom: '1rem'}}>
                  <div className="z2-card padded no-shadow">
                    <h3>Exchange and Wallet Balance:</h3>
                    {totalBalance.walletBalance ?
                    <h3 style={{float:"right"}}>{new Intl.NumberFormat().format(totalBalance.walletBalance) +" Tokens"}</h3>
                  :
                  null
                  }
                    </div>
                </div>

                  <div span={12}>
               
                <Table columns={walletColumns} dataSource={modifiedWalletData} loading= {loading} pagination={false}/>
                  
                  </div>
                  </Row>

            </Col>
          </Row>
        </>
      {/* ) : (
        <Text size="md">You don't have permission to view this screen.</Text>
      )} */}
    </div>
  );
};

export default Reports;
