import axios from 'axios';
import { alertErrorMessage } from 'utils/ErrorAlerter';

const BALANCE_SUMMARY_URL = "https://walletbackend.zebpay.co/monitor/balance-summary";

export const getBalanceSummary = async (callback) => {
    
    try {
      const token =  localStorage.getItem('returnedToken');
        const response  = await axios.get(BALANCE_SUMMARY_URL, {
          headers: {
            Authorization: 'Bearer ' + token
          }
          });
        console.log(response.data)
        callback(response.data.data.newData,null);
      } catch (err) {
        // alert(`${err}\n${alertErrorMessage(err.response.status)}`)
        alert(`${err}`)
        callback(null,err.message);
      }
};
  
// export const localStorageGetItem = (key) => {
// const data = localStorage.getItem(key);
// if (data !== null) {
//     return JSON.parse(data);
// }
// return null;
// };
// export const localStorageRemoveItem = (key) => {
// localStorage.removeItem(key);
// window.localStorage.clear();
// };
  