import React, { useState,useEffect } from 'react';
import { Table,Popover,Button  } from 'antd';
import Moment from 'moment';
import {localStorageGetItem} from "services/LocalStorageService";
import { Sprite } from 'components/atoms';
let token= localStorage.getItem('returnedToken')

const WalletsTable = (props) => {
    const { filter } = props;
    const [allWallets, setAllWallets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState(filter);
    const dateFormat = localStorageGetItem('dateFormat');
    // console.log(dateFormat);
    const content = (
      <div>
        <p>Content</p>
        <p>Content</p>
      </div>
    );
    const columns = [
        {
          title: '',
          dataIndex: 'displayName',
          render: (text, row, index) => {
            // console.log("Row data", row);
            return (
              <div className="flex">
               <span> 
                 <Sprite id={row.walletType.toLowerCase() + '-icon'} width={40} height={40}/>
                </span>
                <div className="wallet-table-data-name">{row.displayName}
                <br/>
                <span>
                {row.walletType} </span></div>
              </div>
            );
          },
        },
        {
          title: 'Volume',
          dataIndex: 'volume',
          align:'right',
          sorter: (a, b) => a.volume - b.volume,
          render: (text, row, index) => {
            return (
              <div>
                <div className="wallet-table-data-vol"> {new Intl.NumberFormat().format(row.volume)} {row.coinType}
                <br/>
                <span>
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(row.balanceInUSD)}
                {/* ${Math.round((row.balanceInUSD + Number.EPSILON) * 100) / 100 }          */}
                </span>
                </div>
              </div>
            );
          },
        },
        {
          title: 'Date/Time',
          dataIndex: 'recordTime',
          align:'right',
          render: (date, row, index) => {
            const formattedDate = Moment(date).format(dateFormat);
            return {
              children: <span>{formattedDate}</span>
            };
          },
          
        },
        {
          title: 'Root Address',
          dataIndex: 'rootAddress',
          align:'right',
          render: (text, row, index) => {
            return (
              <div>
                <Popover placement="topLeft"  content={row.rootAddress} title="Root Address" trigger="click">
                  <Button><Sprite id={'config'} width={20} height={20}/></Button>
              </Popover>
              <Button onClick={() => {navigator.clipboard.writeText(row.rootAddress)}}><Sprite id={'copy-icon'} width={20} height={20}/></Button>
              </div>
            );
          },
          
        }

      ];


    useEffect(()=>{
        setLoading(true);
        fetch("https://walletbackend.zebpay.co/wallet/all-balance",{
          headers: {
            Authorization: 'Bearer ' + token
        }
      })
            .then((res) => res.json())
            .then((result) => {
                setLoading(false)
            setAllWallets(result.wallet);
            // console.log("wallet table result wallet",result.wallet)
            }, []);
    },[props.id])
    const modifiedData = allWallets.map(({body, ...item}) => ({
    ...item,
    key: item.walletId
    }));

    // const modifiedData = allWallets.map(function({body, ...item}) { 
    //   return {
    //   ...item,
    //   key: item.walletId
    //   }
    // });
    const filtredWallet = modifiedData.filter(function(d){return d.coinType.toLowerCase()== props.id.toLowerCase() })
    // console.log(props);
 
    return (
        <div>
            <h2>{props.id.length<0 || props.id=="All Assets"? modifiedData.length:filtredWallet.length} {props.id} Wallets</h2>
            <Table columns={columns} loading={loading} dataSource={props.id.length<0 || props.id=="All Assets" ? modifiedData : filtredWallet }  rowKey={props.id} pagination={false} />
        </div>
    );
};

export default WalletsTable;

  