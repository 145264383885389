import React, { useCallback, useEffect, useState } from 'react';
import Meta from 'antd/lib/card/Meta';
import {COINCONFIG} from "../../constants/coinconfig";
import { Avatar } from 'antd';

const WalletDrawer = (props) => {
const [drawerBalance, setDrawerBalance] = useState([]);
const [drawerCoins, setDrawerCoins] = useState([]);
let myArr = {coins:[]};
    if(props.data.hasOwnProperty("name")){
        myArr = props.data.data;
        // setDrawerBalance(myArr.totalBalanceInUsd);
        // setDrawerCoins(myArr.coins);
        // console.log(myArr.coins);
    }
  var currDrawerVal=  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(myArr.totalBalanceInUsd);
// console.log(myArr.coins);
return(
    <>
    <h3>{props.data.name} 
    <span>{myArr.hasOwnProperty("totalBalanceInUsd")?currDrawerVal:""}</span></h3>
    {/* {console.log(props.data.data.totalBalanceInUsd)} */}
    <ul>
{
    myArr.coins.length>0 ?
    (myArr.coins.map(function(d, idx){
        console.log(d.coin);
        const title  = COINCONFIG[d.coin.toUpperCase()]? COINCONFIG[d.coin.toUpperCase()].label:d.coin.toUpperCase();
      const icon = COINCONFIG[d.coin.toUpperCase()]? COINCONFIG[d.coin.toUpperCase()].icon:<Avatar src='https://cdn.iconscout.com/icon/premium/png-256-thumb/crypto-coin-2432529-2015842.png' />
        return (<li key={idx}  className="wallet-coins"><Meta avatar={icon} title={title}/> <div className="wallet-vol"> {new Intl.NumberFormat().format(d.volume)}</div></li>)
      }))
    :
    ""
}
 
    </ul>   

    </>
);
};
export default WalletDrawer;