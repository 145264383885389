import axios from "axios";
import React, { useEffect, useState } from "react";
import { Input, Table } from "antd";
import { ENV_CONFIG } from '../../config';
import { alertErrorMessage } from 'utils/ErrorAlerter';

const Search = () =>{
    const { Search } = Input;
    const[allWallets,setAllWallets] = useState([]);
    const [filteredWallets, setFilteredWallets] =useState(allWallets);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const FilterByNameInput = (
        <Input
          placeholder="Search Root Address"
          value={value}
          autoFocus
          onChange={e => {
            const currValue = e.target.value;
            setValue(currValue);
            const filteredData = allWallets.filter(entry =>
              entry.rootAddress.includes(currValue)
            );
            setFilteredWallets(filteredData);
          }}
        />
      );

    const columns = [
        {
          title: FilterByNameInput,
          dataIndex: 'rootAddress',
          key: 'rootAddress',
        //   filters: filteredWallets.filter(item => ({ text: item.rootAddress, value: item.rootAddress })),
        //   onFilter: (value, record) => record.rootAddress.toLowerCase().includes(value.toLowerCase()),
        //   filterSearch: true
        }
        ,
        {
          title: 'Asset Name',
          dataIndex: 'coinType',
          key: 'coinType',
          filters: filteredWallets.map(item => ({ text: item.coinType, value: item.coinType })),
          onFilter: (value, record) => record.coinType.toLowerCase().includes(value.toLowerCase()),
          filterSearch: true,
        },
        {
          title: 'Display Name',
          dataIndex: 'displayName',
          key: 'displayName',
          filters: filteredWallets.map(item => ({ text: item.displayName, value: item.displayName })),
          onFilter: (value, record) => record.displayName.toLowerCase().includes(value.toLowerCase()),
          filterSearch: true
        },
        {
          title: 'Wallet Type',
          dataIndex: 'walletType',
          key: 'walletType',
          // filters: filteredWallets.map(item => ({ text: item.walletType, value: item.walletType })),
          // onFilter: (value, record) => record.walletType.toLowerCase().includes(value.toLowerCase()),
          // filterSearch: true
        },
        {
            title: 'Created Date',
            dataIndex: 'Ttimestamp',
            key: 'Ttimestamp',
        }
    
      ];

    useEffect(()=>{
        try {
          setLoading(true);
          axios(ENV_CONFIG.BASE_URL+'/wallet/all-balance', {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
            }
          })
          .then(response => {
          console.log(response.data.wallet)
          setAllWallets(response.data.wallet);
          setFilteredWallets(response.data.wallet);
          })
          .catch(error => {
          console.log('Error getting fake data: ' + error);
          })
          setLoading(false);
        } catch (err) {
          // alert(`${err}\n${alertErrorMessage(err.response.status)}`)
          alert(`${err}, ${err.response.data.message}`)
        }
    },[]);


    // const handleSearch = (event) => {
    //     let value = event.target.value.toLowerCase();
    //     let result = [];
    //     // console.log(value);
    //     result = allWallets.filter((data) => {
    //         // console.log(data.rootAddress);
    //     return data.rootAddress.search(value) != -1;
    //     });
    //     setFilteredWallets(result);
    // }

    return(
        <>
        <h2>Search Root Address</h2>
        {/* <input type="text" onChange={(event) =>handleSearch(event)}/> */}
        {/* <Search placeholder="input search text" onChange={(event) =>handleSearch(event)} style={{ width: 200 }} /> */}
        <div style={{padding:10}}>
        <Table columns={columns} loading={loading} dataSource={filteredWallets}/> 
        {/* {filteredWallets.map((value,index)=>{
        return(
        <div key={value.displayName+value.walletId+value.rootAddress}>
        <div className="z2-card padded" style={{marginTop:20}}>
        {value.rootAddress}
        </div>
        </div>
        )
        })} */}
        </div>
        </>
    );


}

export default Search;