import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Select, Table, Input, Form, Modal, InputNumber, Radio } from 'antd';
import {
    PageHeaders,
} from 'components/molecules';
import { Text } from 'components/atoms';
import axios from 'axios';

const AdminPanel = () => {

    const [users, setUsers] = useState([]);
    const [editClicked, setEditClicked] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [userToEdit, setUserToEdit] = useState({})
    const [allRoles, setAllRoles] = useState([])

    const getAllUsers = async () => {
        try {
            let response = await axios.get("https://walletbackend.zebpay.co/user", {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
                }
            });

            response = response.data.data
            response.forEach(data=>{data.lastLogin = new Date(data.lastLogin).toLocaleDateString() + " " + new Date(data.lastLogin).toLocaleTimeString()})
            setUsers(response)
        } catch (err) {
            alert(err)
        }
    }

    const getAllRoles = async () => {
        try {
            let response = await axios.get("https://walletbackend.zebpay.co/user/roles", {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
                }
            });

            response = response.data.data
            setAllRoles(response)

        } catch (err) {
            alert(err)
        }
    }

    useEffect(async () => {
        await getAllUsers();
        await getAllRoles();
    }, []);

    const userTableCols = [
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Role',
            dataIndex: 'role'
        },
        {
            title: 'IsActive',
            dataIndex: 'isActive',
            render: (text, record) => {
                return "" + text
            }
        },
        {
            title: 'Last Login',
            dataIndex: 'lastLogin'
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button type="primary" onClick={() => { handleEditClick(text, record) }} style={{ marginLeft: "20px" }}>Edit</Button>
                )
            }
        }
    ];

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const handleEditClick = async (text, record) => {
        setEditClicked(true)
        setUserToEdit(record)
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    const editUserDetails = async (user) => {
        const updatedDetails = {}
        if (user.name !== undefined) updatedDetails['name'] = user.name
        if (user.role !== undefined) updatedDetails['role'] = user.role
        if (user.isActive !== undefined) updatedDetails['isActive'] = (user.isActive === "FALSE" ? false : true)

        await axios.put('https://walletbackend.zebpay.co/user', {
            email: userToEdit.email,
            updatedDetails
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
            }
        })

        await getAllUsers()
    }

    const onFinish = async (formdata) => {
        try {
            let anyChange = false
            for (let key in formdata.user) {
                if (formdata.user[key] !== userToEdit[key]) {
                    anyChange = true
                    break;
                }
            }

            if (anyChange) {
                await editUserDetails(formdata.user)
            }

            setUserToEdit({})
            setIsModalVisible(false);
        } catch (err) {
            console.log(err.message);
            console.log(err.data);
            alert('some error occured. not able to edit user details. please try again')
        }
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };


    return (
        <div>
            <Row gutter={[20]}>
                <Col span={24}>
                    <PageHeaders
                        title={'Welcome - Zebpay Admin'}
                        section={'Admin Panel'}
                        subSection={'Users'}
                    />
                </Col>
            </Row>

            <Table columns={userTableCols} dataSource={users} pagination={false}></Table>

            <Modal title="Edit User" mask={true} visible={isModalVisible} destroyOnClose={true} onCancel={handleCancel} footer={[]}>
                <Form {...layout} initialValues={userToEdit} name="nest-messages" id='edituserform' onFinish={onFinish} validateMessages={validateMessages}>
                    <Form.Item name={['user', 'name']} label="Name" labelAlign='left' rules={[]}>
                        <Input autoFocus defaultValue={userToEdit.name} />
                    </Form.Item>
                    <Form.Item name={['user', 'email']} labelAlign='left' label="Email" rules={[]} >
                        <Text size="md">
                            {userToEdit.email}
                        </Text>
                    </Form.Item>
                    <Form.Item name={['user', 'role']} labelAlign='left' label="Role" rules={[]}>
                        <Select defaultValue={"Select Role"}>
                            {allRoles.map(item => (
                                <Select.Option key={item.key} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={['user', 'isActive']} labelAlign='left' label="IsActive" rules={[]}>
                        <Select defaultValue={"IsActive"}>
                            {['TRUE', 'FALSE'].map(item => (
                                <Select.Option key={item.key} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>



                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="secondary" htmlType="reset">
                            Reset
                        </Button>
                        <Button type="primary" key="edituserform" htmlType="submit" style={{ marginLeft: "20px" }}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>

        </div>
    );
};

export default AdminPanel;
