import {
  FETCH_CRYPTO_TXN_REQUEST,
  FETCH_CRYPTO_TXN_SUCCESS,
  FETCH_CRYPTO_TXN_FAILED,
  FETCH_SENDING_ADDRESSES_REQUEST,
  FETCH_SENDING_ADDRESSES_SUCCESS,
  FETCH_SENDING_ADDRESSES_FAILED,
  FETCH_RECEIVING_ADDRESSES_REQUEST,
  FETCH_RECEIVING_ADDRESSES_SUCCESS,
  FETCH_RECEIVING_ADDRESSES_FAILED,
  FETCH_CURRENCY_SETTINGS_REQUEST,
  FETCH_CURRENCY_SETTINGS_SUCCESS,
  FETCH_CURRENCY_SETTINGS_FAILED,
} from './cryptoActionTypes';
const initialState = {
  loadingCryptoTxnStatus: false,
  cryptoTxns: [],
  cryptoTxnsErrorMsg: '',
  loadingSendingAddressStatus: false,
  sendingAddresses: [],
  sendingAddressErrMsg: '',
  loadingReceivingAddressStatus: false,
  receivingAddresses: [],
  receivingAddressErrMsg: '',
  loadingCurrencySettings: false,
  currencySettings: [],
  currencySettingsErrorMsg: '',
};

const cryptoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CRYPTO_TXN_REQUEST:
      return {
        ...state,
        loadingCryptoTxnStatus: true,
        cryptoTxns: [],
        cryptoTxnsErrorMsg: '',
      };
    case FETCH_CRYPTO_TXN_SUCCESS:
      return {
        ...state,
        loadingCryptoTxnStatus: false,
        cryptoTxns: action.payload,
        cryptoTxnsErrorMsg: '',
      };
    case FETCH_CRYPTO_TXN_FAILED:
      return {
        ...state,
        loadingCryptoTxnStatus: false,
        cryptoTxns: [],
        cryptoTxnsErrorMsg: action.payload,
      };
    case FETCH_SENDING_ADDRESSES_REQUEST:
      return {
        ...state,
        loadingSendingAddressStatus: true,
        sendingAddresses: [],
      };
    case FETCH_SENDING_ADDRESSES_SUCCESS:
      return {
        ...state,
        loadingSendingAddressStatus: false,
        sendingAddresses: action.payload,
        sendingAddressErrMsg: '',
      };
    case FETCH_SENDING_ADDRESSES_FAILED:
      return {
        ...state,
        loadingSendingAddressStatus: false,
        sendingAddresses: [],
        sendingAddressErrMsg: action.payload,
      };
    case FETCH_RECEIVING_ADDRESSES_REQUEST:
      return {
        ...state,
        loadingReceivingAddressStatus: true,
        receivingAddresses: [],
        receivingAddressErrMsg: '',
      };
    case FETCH_RECEIVING_ADDRESSES_SUCCESS:
      return {
        ...state,
        loadingReceivingAddressStatus: false,
        receivingAddresses: action.payload,
        receivingAddressErrMsg: '',
      };
    case FETCH_RECEIVING_ADDRESSES_FAILED:
      return {
        ...state,
        loadingReceivingAddressStatus: false,
        receivingAddresses: [],
        receivingAddressErrMsg: action.payload,
      };
    case FETCH_CURRENCY_SETTINGS_REQUEST:
      return {
        ...state,
        loadingCurrencySettings: true,
        currencySettings: [],
        currencySettingsErrorMsg: '',
      };
    case FETCH_CURRENCY_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingCurrencySettings: false,
        currencySettings: action.payload,
        currencySettingsErrorMsg: '',
      };
    case FETCH_CURRENCY_SETTINGS_FAILED:
      return {
        ...state,
        loadingCurrencySettings: false,
        currencySettings: [],
        currencySettingsErrorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default cryptoReducer;
