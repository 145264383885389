export const SEARCH_USER_REQUEST = 'SEARCH_USER_REQUEST';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILED = 'SEARCH_USER_FAILED';
export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FAILED';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const UPDATE_QUERY_PARAMS = 'UPDATE_QUERY_PARAMS';
export const SET_CRYPTO_OUTGOING_TXN_REQUEST =
  'SET_CRYPTO_OUTGOING_TXN_REQUEST';
export const SET_CRYPTO_OUTGOING_TXN_SUCCESS =
  'SET_CRYPTO_OUTGOING_TXN_SUCCESS';
export const SET_CRYPTO_OUTGOING_TXN_FAILED = 'SET_CRYPTO_OUTGOING_TXN_FAILED';
export const SET_FIAT_OUTGOING_TXN_REQUEST = 'SET_FIAT_OUTGOING_TXN_REQUEST';
export const SET_FIAT_OUTGOING_TXN_SUCCESS = 'SET_FIAT_OUTGOING_TXN_SUCCESS';
export const SET_FIAT_OUTGOING_TXN_FAILED = 'SET_FIAT_OUTGOING_TXN_FAILED';
export const DISABLE_OUTGOING_TXN_REQUEST = 'DISABLE_OUTGOING_TXN_REQUEST';
export const DISABLE_OUTGOING_TXN_SUCCESS = 'DISABLE_OUTGOING_TXN_SUCCESS';
export const DISABLE_OUTGOING_TXN_FAILED = 'DISABLE_OUTGOING_TXN_FAILED';
export const DEACTIVATE_ACCOUNT_REQUEST = 'DEACTIVATE_ACCOUNT_REQUEST';
export const DEACTIVATE_ACCOUNT_SUCCESS = 'DEACTIVATE_ACCOUNT_SUCCESS';
export const DEACTIVATE_ACCOUNT_FAILED = 'DEACTIVATE_ACCOUNT_FAILED';
export const RESET_SETTING_STATES = 'RESET_SETTING_STATES';
export const SET_DEBIT_REQUEST = 'SET_DEBIT_REQUEST';
export const SET_DEBIT_SUCCESS = 'SET_DEBIT_SUCCESS';
export const SET_DEBIT_FAILED = 'SET_DEBIT_FAILED';
export const DISABLE_DEBIT_REQUEST = 'DISABLE_DEBIT_REQUEST';
export const DISABLE_DEBIT_SUCCESS = 'DISABLE_DEBIT_SUCCESS';
export const DISABLE_DEBIT_FAILED = 'DISABLE_DEBIT_FAILED';
export const EDIT_MOBILE_NUMBER_REQUEST = 'EDIT_MOBILE_NUMBER_REQUEST';
export const EDIT_MOBILE_NUMBER_SUCCESS = 'EDIT_MOBILE_NUMBER_SUCCESS';
export const EDIT_MOBILE_NUMBER_FAILED = 'EDIT_MOBILE_NUMBER_FAILED';
export const FETCH_ADMIN_REMARKS_REQUEST = 'FETCH_ADMIN_REMARKS_REQUEST';
export const FETCH_ADMIN_REMARKS_SUCCESS = 'FETCH_ADMIN_REMARKS_SUCCESS';
export const FETCH_ADMIN_REMARKS_FAILED = 'FETCH_ADMIN_REMARKS_FAILED';
export const GET_USER_LOGIN_HISTORY_REQUEST = 'GET_USER_LOGIN_HISTORY_REQUEST';
export const GET_USER_LOGIN_HISTORY_SUCCESS = 'GET_USER_LOGIN_HISTORY_SUCCESS';
export const GET_USER_LOGIN_HISTORY_FAILED = 'GET_USER_LOGIN_HISTORY_FAILED';
export const GET_RECENT_SIGNUP_LIST_SUCCESS = 'GET_RECENT_SIGNUP_LIST_SUCCESS';
export const GET_RECENT_SIGNUP_LIST_REQUEST = 'GET_RECENT_SIGNUP_LIST_REQUEST';
export const GET_RECENT_SIGNUP_LIST_FAILURE = 'GET_RECENT_SIGNUP_LIST_FAILURE';
export const FETCH_GLOBAL_SEARCH_LIST_FAILURE =
  'FETCH_GLOBAL_SEARCH_LIST_FAILURE';
export const FETCH_GLOBAL_SEARCH_LIST_SUCCESS =
  'FETCH_GLOBAL_SEARCH_LIST_SUCCESS';
export const FETCH_GLOBAL_SEARCH_LIST_REQUEST =
  'FETCH_GLOBAL_SEARCH_LIST_REQUEST';
export const GET_REJECTED_EXCEL_DETAILS_REQUEST =
  'GET_REJECTED_EXCEL_DETAILS_REQUEST';
export const GET_REJECTED_EXCEL_DETAILS_SUCCESS =
  'GET_REJECTED_EXCEL_DETAILS_SUCCESS';
export const GET_REJECTED_EXCEL_DETAILS_FAILURE =
  'GET_REJECTED_EXCEL_DETAILS_FAILURE';
