import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col,Form,Modal, Button,Skeleton,Input,Switch,InputNumber, Select,Radio } from 'antd';
import { PageHeaders } from 'components/molecules';
import {localStorageGetItem} from "services/LocalStorageService";
import axios from 'axios';
import WalletCards from './WalletCards';
import WalletDrawer from './WalletDrawer';
import { ENV_CONFIG } from '../../config';
import { Sprite } from 'components/atoms';
import { alertErrorMessage } from 'utils/ErrorAlerter';

const Wallets = () => {
  const [allBalance, setAllBalance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clickedData, setClickedData] = useState([]);
  const [activeId, setActiveId] = useState([]);
  const [drawerActive, setDrawerActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [assetsStore,setAssetsStore] = useState([]);
  const [loadedProviders, setLoadedProviders] = useState([]);
  const [utxbase, setUtxbase] = useState(1);
  const token =  localStorage.getItem('returnedToken');
  let walletTypeStore = localStorage.getItem("walletDataKey");
  const [value, setValue] = React.useState(1);

  const onChange = e => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const showModal = () => {
    setIsModalVisible(true);
    loadProviders();
    // getAssets();
  };
  const utxBaseHandle = () => {
    setUtxbase(!utxbase);
  }
  const successFormModal = () => {
    setIsSuccessModalVisible(true);
    success();
  };
  function success() {
    Modal.success({
      content: 'New wallet has been successfully added.',
    });
  }
  /*
* On loadProviders handler
* fetch response using axios and setLoadedProviders on loadProviders handler
* @return - null
*/
  const loadProviders = async () =>{
    try{
      const response = await axios.get("https://walletbackend.zebpay.co/wallets/providers", {
      headers: {
        Authorization: 'Bearer ' + token
      }
      });
      setLoadedProviders(response.data.data);
      console.log(response.data.data);
    }
    catch(err){
      console.log(err);
      alert(`${err}, ${err.response.data.message}`)
    }
  }
  const getAssets = async() =>{
    setLoading(true);
    const response = await axios.get(ENV_CONFIG.BASE_URL+ "/wallet/db-balance", {
      headers: {
        Authorization: 'Bearer ' + token
      }
      });
    setAssetsStore(response.data.wallet);
    //  console.log(response.data.wallet);
    setLoading(false);
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

/*
* On finish handler
* Add new wallet on finish handler
* @param walletFormData-form values
* @return - null
*/
  const onFinish = (walletFormData) => {
    console.log(walletFormData);
    addNewWallet(walletFormData.wallet);
    setIsModalVisible(false);
    successFormModal();
  };
  /*
* On Cancel handler
* Hide modal window on Cancel handler
* @return - null
*/
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  /*
* On addNewWallet handler
* Add new wallet on addNewWallet handler
* @paramformBody values
* @return - null
*/
  const addNewWallet = async (formBody) =>{
    try{
      await axios.post('https://walletbackend.zebpay.co/wallets/add', 
      formBody,{
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
    )
  }
    catch(err){
      console.log(err);
    }
  }

  const walletFormating = (data) =>{
    let walletMaster = {}
    // console.log(data);
    setLoading(true);
    data.map(item=>{
      // console.log(item);
      // console.log(walletMaster[item.walletType]);
      
      if(walletMaster[item.walletType.toLowerCase()]){
        const {coinType,displayName,recordTime,walletId,volume,balanceInUSD} = item;
        walletMaster[item.walletType.toLowerCase()].push({coinType,displayName,recordTime,walletId,volume,balanceInUSD});
      }else{
        // new type
        const {coinType,displayName,recordTime,walletId,volume,balanceInUSD} = item;
        walletMaster[item.walletType.toLowerCase()] = [{coinType,displayName,recordTime,walletId,volume,balanceInUSD}];
      }
    })
    // console.log('----');
    // console.log(walletMaster);
    
    localStorage.setItem("walletDataKey",JSON.stringify(walletMaster));
    let processedData= processData(walletMaster);
    // console.log(processedData);
    // setAllWallets(walletMaster);
    setAllBalance(processedData);
    setLoading(false);
  }
  let allWalletType = Object.keys(allBalance);
  var walletSum = 0;
  for (let i = 0; i < allWalletType.length; i++) {
    let val = allBalance[allWalletType[i]];
     walletSum = walletSum + val.totalBalanceInUsd;
  }
  // var walletSum = allWalletType.reduce((acc, curr) => acc + curr.totalBalanceInUsd, 0);
  // console.log(walletSum);
  // var walletSum = walletData.reduce((acc, curr) => acc + curr.totalWalletBalance, 0);
  const getWallets = async() =>{
    setLoading(true);
    
    const response = await axios.get(ENV_CONFIG.BASE_URL+ "/wallet/all-balance", {
      headers: {
        Authorization: 'Bearer ' + token
      }
      });
    // console.log(response.data.wallet);
    // setAllBalance(response.data.wallet);
    walletFormating(response.data.wallet);
    setLoading(false);
  }
  const processData = (data) =>{
    if(data){
      let processedData = {}
      for(let key in data){
        processedData[key] = {}
        processedData[key].totalBalanceInUsd = data[key].map(entry=>entry.balanceInUSD).reduce((total,current)=>total+current);
        processedData[key].coins = data[key].map(entry=>{return{coin:entry.coinType,volume:entry.volume}});
        processedData[key].coinLength = processedData[key].coins.length;
      }
     
      console.log(processedData);
      return processedData;
    }
  }
  const showWalletDetails = (key,details) =>{
    // console.log(key,details);
    const tempData = {name:key,data:details}
    setClickedData(tempData);
    setActiveId(tempData.name);
    setDrawerActive(true);
    
  }
  const closeDrawer = () =>{
    setDrawerActive(false);
  }
  // console.log(activeId);
  useEffect(() => {
    try {
      let storedArray = localStorage.getItem("walletDataKey");
      // console.log(storedArray);
      if(storedArray){
        setLoading(true);
        const walletMaster2 = JSON.parse(storedArray);
        // console.log(walletMaster2);
        let processedData= processData(walletMaster2);
        // console.log(processedData);
        // setAllWallets(walletMaster2);
        setAllBalance(processedData);
        setLoading(false);
      }
      else{
        setLoading(true);
        getWallets();
        setLoading(false);
      }  
    } catch (err) {
      // alert(`${err}\n${alertErrorMessage(err.response.status)}`)
      alert(`${err}, ${err.response.data.message}`)
    } 


     // POST request using fetch inside useEffect React hook
  //    const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ title: 'React Hooks POST Request Example' })
  // };
  // fetch('https://blockanalytics-api.zebpay.co/wallets/add', requestOptions)
  //     .then(response => response.json())
  //     .then(data => setPostId(data.id));

// empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  const cardsSkeleton = (
    <Row gutter={[20, 20]} wrap="false">
              <Col span={6}>
              <div className="z2-card padded"> 
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
              </Col>
              <Col span={6}>
              <div className="z2-card padded"> 
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
              </Col>
              <Col span={6}>
              <div className="z2-card padded"> 
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
              </Col>
              <Col span={6}>
              <div className="z2-card padded"> 
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
              </Col>
    </Row>
  )


// console.log(allWallets);

  return (
    <div>
      <Row gutter={[20]}>
        <Col span={12}>
          <PageHeaders
            section={'Accounts'}
            // subSection= {<CurrencyFormat value={walletSum} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
            subSection = {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(walletSum)}
          />
        </Col>
        {/* <Col span={6} push={6} style={{textAlign:'right'}}>
                  <Button type="primary" onClick={showModal}> Add New Wallet</Button>
        </Col> */}
      </Row>
      {/* {permissions[UI_ACCESS_SCOPES.readUser] ? ( */}
        <>
          <Row gutter={[20, 20]} wrap={false}>
            <Col flex="auto">
              <Row gutter={[20, 20]}>

              <Modal title="Add New Wallet" mask={true} visible={isModalVisible}  destroyOnClose={true} onCancel={handleCancel} footer={[
            // <Button key="submit" type="primary" loading={loading }  htmlType="submit" onClick={this.handleOk}>
            //   Submit
            // </Button>
          ]}>
                <Form {...layout} name="nest-messages" id='addNewWalletForm' onFinish={onFinish}  validateMessages={validateMessages}>
                    <Form.Item name={['wallet', 'walletId']} label="Wallet Id" labelAlign='left'  rules={[{ required: true }]}>
                      <Input autoFocus/>
                    </Form.Item>
                    <Form.Item name={['wallet', 'coinType']} labelAlign='left' label="Assets Symbol" rules={[{ required: true }]} >
                    {/* <Select  defaultValue={1}>
                        {assetsStore.map(item => (
                          <Option key={item.id} value={item.coinType}>
                            {item.coinType}
                          </Option>
                        ))}
                      </Select> */}
                       <Input />
                    </Form.Item>
                    <Form.Item name={['wallet', 'rootAddress']}  labelAlign='left'  label="Root Address"  rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item name={['wallet', 'walletType']}  labelAlign='left'  label="Wallet Type"  rules={[{ required: true }]}>
                      {/* <Input /> */}
                      <Select defaultValue={"Select Wallet Type"}>
                        {allWalletType.map(item => (
                          <Select.Option key={item.key} value={item}>
                            {item.toUpperCase()}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['wallet', 'provider']}  labelAlign='left' label="Provider"  rules={[{ required: true }]}>
                    <Select defaultValue={"Select Provider"}>
                        {loadedProviders.map(item => (
                          <Select.Option key={item.provider} value={item.provider}>
                            {item.providerName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={['wallet', 'rateProvider']}  labelAlign='left' label="Rate Provider"  rules={[{ required: true }]}>
                    <InputNumber defaultValue={"0"}  min="0"/>
                    {/* <Select defaultValue={"Select Rate Provider"}>
                          <Option key={1} value={1}>
                            1
                          </Option>
                          <Option key={2} value={2}>
                            2
                          </Option>
                          <Option key={3} value={3}>
                            3
                          </Option>
                        
                      </Select> */}
                    </Form.Item>
                    <Form.Item name={['wallet', 'isUTXObase']}  labelAlign='left' label="Is UTXO base" rules={[{ required: true }]}>
                    {/* <Switch checkedChildren="Yes" unCheckedChildren="No" defaultValue={utxbase} onChange={utxBaseHandle}/> */}
                      <Radio.Group onChange={onChange} value={value}>
                      <Radio value={0}>Yes</Radio>
                      <Radio value={1}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item name={['wallet', 'decimalPlaces']}  labelAlign='left' label="Decimal Places"  rules={[{ required: true }]}>
                    <InputNumber defaultValue={"0"}  min="0"/>
                    </Form.Item>
                    <Form.Item name={['wallet', 'displayName']}  labelAlign='left' label="Display Name"  rules={[{ required: true }]}>
                    <Input />
                    </Form.Item>
                    <Form.Item name={['wallet', 'notes']}  labelAlign='left' label="Notes"  rules={[{ required: true }]}>
                    <Input />
                    </Form.Item>
                    {/* <Form.Item>
                  
                    </Form.Item> */}
                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                      <Button type="secondary" htmlType="reset">
                        Reset
                      </Button>
                      <Button type="primary" key="addNewWalletForm" htmlType="submit" style={{marginLeft:"20px"}}>
                        Submit
                      </Button>
                    </Form.Item>
                
                  </Form>
              </Modal>
              
                <Col span={24}>       
                <div className="site-card-wrapper">
                    <Row gutter={16}>
                      {/* {console.log(allWallets)} */}
                      {loading?
                            <>
                            {cardsSkeleton}
                            </>
                            :
                      <WalletCards data={allBalance} currentId={activeId} onWalletSelect={showWalletDetails}/>
                      }
                    </Row>
                     </div>
                     <div className={ drawerActive? "visible side-drawer-custom" :"hidden side-drawer-custom"}>
                    <Row gutter={16}>
                      <span onClick={closeDrawer} className="closebtn"><Sprite id="cross" width={15} height={15} />  </span>
                      {/* {console.log(allWallets)} */}
                      <WalletDrawer data={clickedData} />
                    </Row>
                     </div>
                </Col>
              </Row>
            </Col>

          </Row>
        </>

    </div>
  );
};

export default Wallets;
