import React, { useEffect, useState } from "react";
import {Skeleton, Table} from "antd";
import { ENV_CONFIG } from '../../config';
import axios from "axios";
import { alertErrorMessage } from 'utils/ErrorAlerter';

const Refill = () =>{
    const [loading, setLoading]= useState(false);
    const [refillData, setRefillData] = useState([]);
    let temp =[];
    refillData.map(item=>{
      if(!temp[item.coinType]){
        temp[item.coinType.toUpperCase()] = item.coinType.toUpperCase();
      }else{
        return
      }
    })
    
    const [processedRefillData, setProcessedRefillData] = useState([]);
    /*
* On formatRefillData handler
* Process the data in walletTypeHot and walletTypeWarm array of objects on formatRefillData handler 
* @param refillData
* @return - null
*/
    const formatRefillData = (refillData) =>{
    for (let i=0; i < refillData.length; i++){
      let existingIndex = processedRefillData.findIndex((entry)=>{
       return entry.coinType.toUpperCase() === refillData[i].coinType.toUpperCase();
        // }
      })
      if(existingIndex >= 0 ){
        // if(refillData[i].isHotDisplay == 1){
          if(true){
        if(refillData[i].walletType == "WARM"){
          processedRefillData[existingIndex].walletTypeWarm = refillData[i];
        }
        else if(refillData[i].walletType == "HOT"){
          processedRefillData[existingIndex].walletTypeHot = refillData[i];
          processedRefillData[existingIndex].walletTypeHot.calculatedBalance = processedRefillData[existingIndex].walletTypeHot ? (processedRefillData[existingIndex].walletTypeHot.volume*100)/processedRefillData[existingIndex].walletTypeHot.HWThreshold : (processedRefillData[existingIndex].walletTypeWarm.volume*100)/processedRefillData[existingIndex].walletTypeWarm.HWThreshold ;
        }
      }
      }
      else{
        // if(refillData[i].isHotDisplay == 1){
          if(true){
        let newCoinEntry = {}
        newCoinEntry.coinType = refillData[i].coinType.toUpperCase();
         if(refillData[i].walletType == "WARM"){
          newCoinEntry.walletTypeWarm = refillData[i];
        }
        else if(refillData[i].walletType == "HOT"){
          newCoinEntry.walletTypeHot = refillData[i];
          newCoinEntry.walletTypeHot.calculatedBalance = newCoinEntry.walletTypeHot ? (newCoinEntry.walletTypeHot.volume*100)/newCoinEntry.walletTypeHot.HWThreshold : (newCoinEntry.walletTypeWarm.volume*100)/newCoinEntry.walletTypeWarm.HWThreshold;
        }
        processedRefillData.push(newCoinEntry);
      }
    }
    }
    setProcessedRefillData(processedRefillData);
    // console.log(processedRefillData);
  }

    const columns = [
        {
          title: 'Coin',
          dataIndex: 'coinType',
          key: 'coinType',
          filters: Object.keys(temp).map(item => ({ text: item, value: item })),
          onFilter: (value, record) => record.coinType.toUpperCase().includes(value.toUpperCase()),
          filterSearch: true,
          render: (text, row, index) => {
            return (     
            <b >{row.coinType}</b>
            );
        },
        },
        {
          title: 'Hot Wallet Name',
          dataIndex: 'displayName',
          key: 'hotdisplayName',
          render: (text, row, index) => {
            return (     
            <b >{row.walletTypeHot?row.walletTypeHot.displayName:row.coinType + " HOT"}</b>
            );
        },
        },
        {
            title: 'Current Balance',
            dataIndex: 'volume',
            key: 'hotvolume',
            align: 'right',
            render:(text, row, index)=>{
              return (
                // <b >{row.walletTypeHot ? new Intl.NumberFormat().format(row.walletTypeHot.volume) :  new Intl.NumberFormat().format(row.walletTypeWarm.volume)}</b>
                <b >{row.walletTypeHot ? new Intl.NumberFormat().format(row.walletTypeHot.volume) :  new Intl.NumberFormat().format(row.walletTypeWarm.volume)}</b>

              )
          }
        },
        {
            title: 'Threshold Balance',
            dataIndex: 'HWThreshold',
            key: 'HWThreshold',
            align: 'right',
            render: (text, row, index) => {
              return (     
              <b >{row.walletTypeHot?row.walletTypeHot.HWThreshold:row.walletTypeWarm.HWThreshold}</b>
              );
                           },
        },
        {
            title: 'Balance %',
            dataIndex: 'calculatedBalance',
            key: 'calculatedBalance',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => parseInt(a.walletTypeHot?a.walletTypeHot.calculatedBalance:0) - parseInt(b.walletTypeHot?b.walletTypeHot.calculatedBalance:0),
            // sorter: {
            //   multiple: 1,
            // },
            align: 'right',
            render: (text, row, index) => {
                const color = parseInt(row.walletTypeHot?row.walletTypeHot.calculatedBalance:0) > 100 ? 'green':'red';
              
                return (
                  <b style={{color:color}}>{new Intl.NumberFormat().format(row.walletTypeHot? row.walletTypeHot.calculatedBalance:0)} %</b>
               
                );
            },
        },
        {
            title: 'Refill Quantity',
            dataIndex: 'WWThreshold',
            key: 'WWThreshold',
            align: 'right',
            render: (text, row, index) => {
                return (     
                <b >{row.walletTypeHot?new Intl.NumberFormat().format(row.walletTypeHot.HWThreshold-row.walletTypeHot.volume):new Intl.NumberFormat().format(row.walletTypeWarm.HWThreshold-row.walletTypeWarm.volume)}</b>
                );
          },
        },
        {
            title: 'Warm Balance',
            dataIndex: 'volume',
            key: 'WWAlertUSD',
            align: 'right',
            render: (text, row, index) => {
              return (     
              <b >{row.walletTypeWarm ? new Intl.NumberFormat().format(row.walletTypeWarm.volume):0}</b>
              );
          },
        },
        {
            title: 'Warm Wallet Name',
            dataIndex: 'displayName',
            key: 'warmdisplayName',
            render: (text, row, index) => {
              return (     
              <b >{row.walletTypeWarm?row.walletTypeWarm.displayName:row.coinType + " WARM Fireblocks"}</b>
              );
          },
        }
    
      ];
      function onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
      }
      const skeleton = (
        <div className="z2-card padded" style={{minHeight: "400px"}} > 
          <Skeleton active paragraph={{ rows: 2 }} />
        </div>
      )

          /*
* On getRefillData handler
* setRefillData and formatRefillData and fetch response using axios on getRefillData handler 
* @return - null
*/
    const getRefillData = async() =>{
      try {
        setLoading(true);
        const token =  localStorage.getItem('returnedToken');
        const response = await axios.get(ENV_CONFIG.BASE_URL+"/wallet/balance", {
          headers: {
            Authorization: 'Bearer ' + token
          }
          });
        setRefillData(response.data.wallet);
        formatRefillData(response.data.wallet);
        // console.log(response.data.wallet);
        setLoading(false);
      } catch (err) {
        alert(`${err}\n${alertErrorMessage(err.response.status)}`) 
      }
        
  }
    useEffect(()=>{
        getRefillData();      
    },[]);
    
    return(
        <>
        {loading ?
            <>
            {skeleton}
            </>
            :             
            <Table columns={columns} loading={loading} dataSource={processedRefillData} pagination={{ defaultPageSize: 15}} onChange={ onChange}/> 
        }
        </>
    );
}
export default Refill;