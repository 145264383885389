import React, { useState, useCallback, useEffect } from 'react';
import classes from '../../organisms/Header/Header.module.scss';
import { Skeleton, Avatar, Input, AutoComplete, SelectProps } from 'antd';
import { Sprite } from 'components/atoms';
import { searchUsers } from 'redux/user/userActions';
import { jsonApiHeader, debounce } from 'utils/Utility';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { localStorageSetItem } from 'services/LocalStorageService';
import { STORAGE_KEYS } from 'constants/index';
const { Search } = Input;

const GlobalSearch = (props) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const {
    loadingFetchGlobalSearchList,
    globalSearchList,
    globalSearchListErrorMsg,
  } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const inputRules = {
    name: 3,
    phone: 6,
    userId: 7,
  };
  const [allSearchParams, setAllSearchParams] = useState({
    name: '',
    userId: '',
    email: '',
    phoneNumber: '',
  });
  const { Search } = Input;
  const [globalSearchValue, setglobalSearchValue] = useState('');
  const [options, setOptions] = useState(null);
  const handleUserSearch = async (values) => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    let params = {
      ...values,
      limit: 0,
      offset: 0,
    };
    dispatch(searchUsers(header, params, 'globalsearch'));
    // dispatch(updateQueryParams(params));
  };
  const debouncedSave = useCallback(
    debounce((nextValue) => handleUserSearch(nextValue), 1000),
    [] // will be created only once initially
  );
  function onSearch(target) {
    const value = target;
    setglobalSearchValue(value);
    let params = {
      name: '',
      phoneNumber: '',
      email: '',
      userId: '',
    };
    if (value && value.length > 2) {
      if (!isNaN(value) && value.length >= inputRules.phone) {
        params = {
          name: '',
          email: '',
          userId: '',
          phoneNumber: value,
        };
      } else if (value.includes('auth0|') && value.length > inputRules.userId) {
        params = {
          name: '',
          email: '',
          userId: value,
          phoneNumber: '',
        };
      } else if (value.includes('@')) {
        params = {
          name: '',
          userId: '',
          phoneNumber: '',
          email: value,
        };
      } else if (value.length >= inputRules.name) {
        params = {
          name: value,
          userId: '',
          phoneNumber: '',
          email: '',
        };
      }
      setAllSearchParams({
        name: params['name'],
        email: params['email'],
        phoneNumber: params['phoneNumber'],
        userId: params['userId'],
      });
      debouncedSave(params);
    }
  }

  useEffect(() => {
    if (globalSearchValue.length > 2) {
      if (loadingFetchGlobalSearchList) {
        setOptions([{ label: <Skeleton active /> }]);
      } else if (
        !loadingFetchGlobalSearchList &&
        globalSearchList.users?.length
      ) {
        const optionArray = globalSearchList.users?.slice(0, 3).map((user) => ({
          value: user.userId,
          label: (
            <div
              key={user?.id}
              className={`${classes.searchResultHover} word-break`}
            >
              <a className={`flex`}>
                <Avatar size={{ xs: 24, sm: 32, md: 40 }} src={user?.picture} />
                <div className={`ml10`}>
                  <div style={{ lineHeight: '20px' }}>
                    <span>{user?.name}</span>
                  </div>
                  <div>
                    <span className={`mb10`}>{user?.phoneNumber}</span>
                  </div>
                </div>
                <span style={{ position: 'absolute', right: '10px' }}>
                  <Sprite id="external-link" width={15} height={15} />
                </span>
              </a>
            </div>
          ),
        }));
        if (globalSearchList.users?.length > 3) {
          optionArray.push({
            value: 'See All Results',
            label: (
              <div style={{ lineHeight: 'normal', fontSize: '12px' }}>
                See all {globalSearchList?.count} results
                <span
                  style={{ position: 'absolute', right: '10px', top: '2px' }}
                >
                  <Sprite id="external-link" width={15} height={15} />
                </span>
              </div>
            ),
          });
        }
        setOptions(optionArray);
      } else if (globalSearchListErrorMsg) {
        setOptions([{ label: 'User Not Found!' }]);
      }
    } else {
      setOptions([]);
    }
  }, [loadingFetchGlobalSearchList, globalSearchValue]);

  window.addEventListener('click', function (e) {
    if (e.target.id !== 'input') {
      setglobalSearchValue('');
    }
  });

  const onSelectOption = (value) => {
    window.open(
      value.toLowerCase().includes('auth0|')
        ? `/customer/${value.substring(value.indexOf('|') + 1, value.length)}`
        : `/customers?uid=${allSearchParams['userId']}&n=${allSearchParams['name']}&p=${allSearchParams['phoneNumber']}&e=${allSearchParams['email']}`,
      '_blank'
    );
    setglobalSearchValue('');
    localStorageSetItem(STORAGE_KEYS.USERID, { userId: value });
  };

  return (
    <>
      <AutoComplete
        dropdownMatchSelectWidth={350}
        style={{ width: 350, position: 'relative', bottom: '5px' }}
        options={options}
        onSearch={onSearch}
        id="input"
        value={globalSearchValue}
        onSelect={onSelectOption}
        dropdownClassName={`pad-all-0`}
        className={`${classes.removeSidePadding}`}
      >
        <Search size="large" placeholder="Search User" enterButton />
      </AutoComplete>
    </>
  );
};

export default GlobalSearch;
