import React from 'react';
import PropTypes from 'prop-types';
import LoginButton from '../../components/google-login/LoginButton';
import BLOCKCHAIN_LOGO from 'assets/svgs/BLOCKCHAIN_LOGO.svg';

const NewLogin = props => {
    return (
        <div style={{backgroundColor: "#E5E5E5", height:"100vh"}}>
            
            <div className="loginButtonCont">
                <img src={BLOCKCHAIN_LOGO} />
                <LoginButton/>
            </div>
        </div>
    );
};


export default NewLogin;