export const alertErrorMessage = (statusCode) => {
    if(statusCode === 400){
       return 'Logout and Login again'
    }    
    else if(statusCode === 401){
        return 'Logout and Login again'
    }
    else if(statusCode === 403){
        return 'You are not authorized to access this resource. Contact Admin.'
    }
    else{
        return "Unknown error: Contact Admin"
    }
};
  