import React, { useCallback, useEffect, useState } from 'react';
import { Text, Title } from 'components/atoms';
import { Row, Col, Card, Modal,Button,Select, Skeleton  } from 'antd';

import { localStorageGetItem } from 'services/LocalStorageService';
// import {GlobalSearch} from 'components/molecules';
import axios from 'axios';
import { getConfig } from '@testing-library/dom';
import WalletsTable from './WalletsTable';
import { ENV_CONFIG } from '../../config';
import CardsList from './CardsList';
import { alertErrorMessage } from 'utils/ErrorAlerter';

const Assets = () => {
  const { Meta } = Card; 
  const { Option } = Select; 
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState([]);
  const [activeClass, setActiveClass] = useState([]);
  const [assetsStore,setAssetsStore] = useState([]);
  const [assetsList,setAssetsList] = useState([]);
  // const [allWallets, setAllWallets] = useState([]);
  const [showChild, toggleShowChild] = useState(false);
  const [searchString, setSearchString] = useState("1inch");
/*
* On getAssets handler
* Fetch assets using axios on getAssets handler
* setAssetStore and setAssetsList
* @return - null
*/
  const getAssets = async() =>{
   
    try{
    setLoading(true);
    const token =  localStorage.getItem('returnedToken');
    const response = await axios.get(ENV_CONFIG.BASE_URL+ "/wallet/db-balance", {
      headers: {
        Authorization: 'Bearer ' + token
      }
      });
    setAssetsStore(response.data.wallet);
    setAssetsList(response.data.wallet);
    //  console.log(response.data.wallet);
     setLoading(false);
    }
    catch(err){
      console.log(err);
      // alert(`${err}\n${alertErrorMessage(err.response.status)}`)
      alert(`${err}, ${err.response.data.message}`)
    }
   
  }
  /*
* On assetFilter handler
* Select the value in the selectbox on assetFilter handler
* @param value 
* @return - null
*/
  const assetFilter = value => {
    // console.log(value.value);
    
    if(value == undefined){
      setAssetsList(assetsStore);
      setActiveId("1inch");
      setSearchString("1inch");
      return;

    }
    setSearchString(value.value);
    const filterAssets = assetsStore.filter(item => item.coinType == value.value);
    setAssetsList(filterAssets);
    setActiveId(value.value);
  };
  /*
* On ClearAssets handler
* Clear the select box on clearAssets handler
* @return - null
*/
  const clearAssets = () =>{
    setAssetsList(assetsStore);
    setActiveId("1inch");
  }

  useEffect(() => {
    // console.log('useeffect');
    getAssets();
    setActiveId("1INCH");
  }, []);
/*
* On updateSelection handler
* setActiveId on updateSelection handler
* @param val
* @return - null
*/
const updateSelection = (val)=>{
  setActiveId(val);
}
const onChangeActive = (val)=>{
  // console.log("Onchange val",val);
  setActiveClass(val);
}
  const skeleton = (
    <div className="z2-card padded" style={{minHeight: "400px"}} > 
      <Skeleton active paragraph={{ rows: 2 }} />
    </div>
  )
  const cardsSkeleton = (
    <Row gutter={[20, 20]} wrap="false" style={{marginTop:"30px"}}>
              <Col span={6}>
              <div className="z2-card padded"> 
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
              </Col>
              <Col span={6}>
              <div className="z2-card padded"> 
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
              </Col>
              <Col span={6}>
              <div className="z2-card padded"> 
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
              </Col>
              <Col span={6}>
              <div className="z2-card padded"> 
                <Skeleton active paragraph={{ rows: 2 }} />
              </div>
              </Col>
    </Row>
  )

/*
* On countDown handler
* Hide the modal window after 5 sec on countDown handler
* @return - null
*/
  function countDown() {
    let secondsToGo = 5;
    const modal = Modal.warning({
      title: 'Under Development',
      content: `This functionality is Under Development`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: `This functionality is Under Development`,
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }

  return (
    <div>
      {/* {permissions[UI_ACCESS_SCOPES.readUser] ? ( */}
        <>
          <Row gutter={[20, 20]} wrap={false}>
            <Col flex="auto">
              <Row gutter={[20, 20]} wrap="false">
              <Col span={12}>
              <Select
                  showSearch
                  labelInValue
                  // defaultValue={{ value: '1inch' }}
                  bordered={false}
                  allowClear
                  onClear={clearAssets}
                  style={{ width: 200, borderBottom: "1px solid #ccc" }}
                  placeholder="Select Asset"
                  optionFilterProp="children"
                  // allowClear
                  value={{value:searchString,label:searchString}} 
                  onChange={assetFilter}
                  // onClear= {() => setSearchString("1inch")}
                  filterOption={(input, option) =>
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                  }
                >
                   {/* <Select.Option  value="All Assets">All Assets</Select.Option> */}
                    {assetsStore.map((val,index) => <Select.Option key={val.id} value={val.coinType} label={val.coinType}></Select.Option>)}
                </Select>
                {/* <Button onClick={()=>(history.push("/assets"))}>refresh</Button> */}
                </Col>
                <Col span={6} push={6} style={{textAlign:'right'}}>
                  <Button type="primary" onClick={countDown}>+ New Coin</Button>
                </Col>
              </Row>
              <Row gutter={[20, 20]} wrap="false">
                
                <Col span={24} className={loading? "":"horizontal-scroll-col"}> 
                          {loading?
                            <>
                            {cardsSkeleton}
                            </>
                            :
                            <CardsList data={assetsList} currentId={activeId} onCardSelect={updateSelection}/>
                          }
                </Col>
                  <Col span={24}  >      
                  {loading?
                            <>
                            {skeleton}
                            </>
                            :             
                      <WalletsTable assetFilter={activeId} id={activeId} />
                  }
                  </Col>
              </Row>
            </Col>
          </Row>
        </>
    
    </div>
  );
};

export default Assets;
