export const FETCH_FIAT_TXN_REQUEST = 'FETCH_FIAT_TXN_REQUEST';
export const FETCH_FIAT_TXN_SUCCESS = 'FETCH_FIAT_TXN_SUCCESS';
export const FETCH_FIAT_TXN_FAILED = 'FETCH_FIAT_TXN_FAILED';
export const FETCH_VIRTUAL_ACCOUNT_REQUEST = 'FETCH_VIRTUAL_ACCOUNT_REQUEST';
export const FETCH_VIRTUAL_ACCOUNT_SUCCESS = 'FETCH_VIRTUAL_ACCOUNT_SUCCESS';
export const FETCH_VIRTUAL_ACCOUNT_FAILED = 'FETCH_VIRTUAL_ACCOUNT_FAILED';
export const UPDATE_ADMIN_REMARKS_REQUEST = 'UPDATE_ADMIN_REMARKS_REQUEST';
export const UPDATE_ADMIN_REMARKS_SUCCESS = 'UPDATE_ADMIN_REMARKS_SUCCESS';
export const UPDATE_ADMIN_REMARKS_FAILURE = 'UPDATE_ADMIN_REMARKS_FAILURE';
export const FETCH_FIAT_ORDER_REQUEST = 'FETCH_FIAT_ORDER_REQUEST';
export const FETCH_FIAT_ORDER_SUCCESS = 'FETCH_FIAT_ORDER_SUCCESS';
export const FETCH_FIAT_ORDER_FAILURE = 'FETCH_FIAT_ORDER_FAILURE';
export const FETCH_ALL_FIAT_TXN_REQUEST = 'FETCH_ALL_FIAT_TXN_REQUEST';
export const FETCH_ALL_FIAT_TXN_SUCCESS = 'FETCH_ALL_FIAT_TXN_SUCCESS';
export const FETCH_ALL_FIAT_TXN_FAILURE = 'FETCH_ALL_FIAT_TXN_FAILURE';
export const FETCH_Z1_HYPTO_BALANCE_REQUEST = 'FETCH_Z1_HYPTO_BALANCE_REQUEST';
export const FETCH_Z1_HYPTO_BALANCE_SUCCESS = 'FETCH_Z1_HYPTO_BALANCE_SUCCESS';
export const FETCH_Z1_HYPTO_BALANCE_FAILURE = 'FETCH_Z1_HYPTO_BALANCE_FAILURE';
export const UPDATE_REFUND_STATUS_FAILURE = 'UPDATE_REFUND_STATUS_FAILURE';
export const UPDATE_REFUND_STATUS_SUCCESS = 'UPDATE_REFUND_STATUS_SUCCESS';
export const UPDATE_REFUND_STATUS_REQUEST = 'UPDATE_REFUND_STATUS_REQUEST';
export const CREDIT_DEPOSIT_REQUEST = 'CREDIT_DEPOSIT_REQUEST';
export const CREDIT_DEPOSIT_FAILURE = 'CREDIT_DEPOSIT_FAILURE';
export const CREDIT_DEPOSIT_SUCCESS = 'CREDIT_DEPOSIT_SUCCESS';
export const UPLOAD_BANK_FEED_REQUEST = 'UPLOAD_BANK_FEED_REQUEST';
export const UPLOAD_BANK_FEED_FAILURE = 'UPLOAD_BANK_FEED_FAILURE';
export const UPLOAD_BANK_FEED_SUCCESS = 'UPLOAD_BANK_FEED_SUCCESS';
export const GET_MATCHING_BANK_FEEDS_REQUEST =
  'GET_MATCHING_BANK_FEEDS_REQUEST';
export const GET_MATCHING_BANK_FEEDS_FAILURE =
  'GET_MATCHING_BANK_FEEDS_FAILURE';
export const GET_MATCHING_BANK_FEEDS_SUCCESS =
  'GET_MATCHING_BANK_FEEDS_SUCCESS';
export const BANK_CREDIT_DEPOSIT_REQUEST = 'BANK_CREDIT_DEPOSIT_REQUEST';
export const BANK_CREDIT_DEPOSIT_FAILURE = 'BANK_CREDIT_DEPOSIT_FAILURE';
export const BANK_CREDIT_DEPOSIT_SUCCESS = 'BANK_CREDIT_DEPOSIT_SUCCESS';
export const UPDATE_BANK_FEED_STATUS_REQUEST =
  'UPDATE_BANK_FEED_STATUS_REQUEST';
export const UPDATE_BANK_FEED_STATUS_FAILURE =
  'UPDATE_BANK_FEED_STATUS_FAILURE';
export const UPDATE_BANK_FEED_STATUS_SUCCESS =
  'UPDATE_BANK_FEED_STATUS_SUCCESS';
export const FETCH_PAYMENT_OPTIONS_REQUEST = 'FETCH_PAYMENT_OPTIONS_REQUEST';
export const FETCH_PAYMENT_OPTIONS_SUCCESS = 'FETCH_PAYMENT_OPTIONS_SUCCESS';
export const FETCH_PAYMENT_OPTIONS_FAILURE = 'FETCH_PAYMENT_OPTIONS_FAILURE';
export const FETCH_SCREENSHOTS_REQUEST = 'FETCH_SCREENSHOTS_REQUEST';
export const FETCH_SCREENSHOTS_SUCCESS = 'FETCH_SCREENSHOTS_SUCCESS';
export const FETCH_SCREENSHOTS_FAILURE = 'FETCH_SCREENSHOTS_FAILURE';
export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE';
export const CREATE_DEPOSIT_ORDER_REQUEST = 'CREATE_DEPOSIT_ORDER_REQUEST';
export const CREATE_DEPOSIT_ORDER_SUCCESS = 'CREATE_DEPOSIT_ORDER_SUCCESS';
export const CREATE_DEPOSIT_ORDER_FAILURE = 'CREATE_DEPOSIT_ORDER_FAILURE';
export const UPDATE_BANK_REFERENCE_REQUEST = 'UPDATE_BANK_REFERENCE_REQUEST';
export const UPDATE_BANK_REFERENCE_SUCCESS = 'UPDATE_BANK_REFERENCE_SUCCESS';
export const UPDATE_BANK_REFERENCE_FAILURE = 'UPDATE_BANK_REFERENCE_FAILURE';
export const REFUND_DEPOSIT_REQUEST = 'REFUND_DEPOSIT_REQUEST';
export const REFUND_DEPOSIT_SUCCESS = 'REFUND_DEPOSIT_SUCCESS';
export const REFUND_DEPOSIT_FAILURE = 'REFUND_DEPOSIT_FAILURE';
export const BANK_FEED_BY_ID_REQUEST = 'BANK_FEED_BY_ID_REQUEST';
export const BANK_FEED_BY_ID_SUCCESS = 'BANK_FEED_BY_ID_SUCCESS';
export const BANK_FEED_BY_ID_FAILURE = 'BANK_FEED_BY_ID_FAILURE';
export const SET_FILTER_QUERY_PARAMS = 'SET_FILTER_QUERY_PARAMS';
