import React, { useState, useEffect, useRef } from 'react';
import { Title, Text, Sprite } from 'components/atoms';
import {
  Row,
  Col,
  Button,
  Divider,
  Tabs,
  Table,
  Avatar,
  Modal,
  Form,
  Input,
  Space,
  Tooltip,
  Skeleton,
  Drawer,
} from 'antd';
import { UI_ACCESS_SCOPES, STORAGE_KEYS } from 'constants/index.js';
import { useSelector, useDispatch } from 'react-redux';
import { jsonApiHeader, formatDate, showScroll } from 'utils/Utility';
import { useAuth0 } from '@auth0/auth0-react';
import {
  fetchAllAdminPerRole,
  createNewRole,
  fetchListOfRoles,
  resetAssignRoleSuccessState,
  createNewRoleReset,
} from 'redux/admin/adminActions';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { fetchActivityLogs } from 'redux/admin/adminActions';
import PageHeaders from 'components/molecules/PageHeaders/PageHeaders';
import { localStorageGetItem } from 'services/LocalStorageService';
const { TextArea } = Input;

const AdminUsers = () => {
  const { TabPane } = Tabs;
  const formRef = useRef();
  const history = useHistory();
  const permissions = localStorageGetItem(STORAGE_KEYS.PERMISSIONS);
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const [createRoleModal, setCreateRoleModal] = useState(false);
  const [remainingRoles, setRemainingRoles] = useState([]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { loadingActivityLogs, activityLogs } = useSelector(
    (state) => state.adminReducer
  );
  const {
    adminsPerRole,
    loadingAdminPerRole,
    adminRoles,
    createNewRoleSuccess,
    removeRoleFromUserSuccess,
    assignRolesSuccess,
    newRoleData,
  } = useSelector((state) => state.adminReducer);
  const handleFetchAdminPerRole = async () => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    dispatch(fetchAllAdminPerRole(header));
  };
  const handleCreateNewRole = async (values) => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    dispatch(
      createNewRole(header, {
        name: values.rolename,
        description: values.description,
      })
    );
  };
  const handleFetchListOfRoles = async () => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    dispatch(fetchListOfRoles(header));
  };
  useEffect(() => {
    handleFetchAdminPerRole();
    handleFetchListOfRoles();
  }, []);
  useEffect(() => {
    if (assignRolesSuccess) {
      dispatch(resetAssignRoleSuccessState());
    }
  }, [assignRolesSuccess]);
  useEffect(() => {
    if (createNewRoleSuccess) {
      handleFetchListOfRoles();
      setCreateRoleModal(true);
    }
  }, [createNewRoleSuccess]);
  useEffect(() => {
    if (removeRoleFromUserSuccess) handleFetchAdminPerRole();
  }, [removeRoleFromUserSuccess]);
  useEffect(() => {
    if (adminsPerRole && Object.keys(adminsPerRole).length) {
      let dbroles = Object.keys(adminsPerRole);
      let res = adminRoles.filter(
        (role) => !dbroles.includes(role.name) && role.name !== 'User'
      );
      setRemainingRoles(res);
    }
  }, [adminRoles]);
  useEffect(() => {}, [adminsPerRole]);
  useEffect(() => {
    if (adminsPerRole && Object.keys(adminsPerRole).length) {
      let data = { ...adminsPerRole };
      let admins = Object.keys(data).map((key) => data[key]);
      setAllAdmins(admins.flat());
    }
  }, [adminsPerRole]);
  let searchInput = useRef();
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text, record) => text,
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Role',
      dataIndex: 'user_type',
      key: 'role',
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (val) => {
        return formatDate(val.updated_at_auth);
      },
    },
    {
      title: 'Permissions',
      key: 'permissions',
      render: (record) => {
        return (
          <Link
            to={{
              pathname: `/configs/roles/user`,
              state: record,
            }}
          >
            View Permissions
          </Link>
        );
      },
    },
  ];
  const getAvatarGroup = (data) => {
    return (
      <Avatar.Group
        maxCount={3}
        maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
      >
        {data.map((admin) => (
          <Tooltip title={admin.name} key={admin.id}>
            <Avatar size={{ xs: 24, sm: 32, md: 40 }} src={admin.image} />
          </Tooltip>
        ))}
      </Avatar.Group>
    );
  };
  const getTabs = () => {
    return (
      <Tabs defaultActiveKey="1" style={{ width: '100%' }} size={'large'}>
        <TabPane tab={`All (${allAdmins.length})`} key={1}>
          <Table
            onRow={(record) => {
              return {
                onClick: (event) => {
                  history.push({
                    pathname: `/configs/roles/user`,
                    state: record,
                  });
                },
              };
            }}
            dataSource={allAdmins}
            columns={columns}
            rowKey={'id'}
            pagination={allAdmins.length > 10}
          />
        </TabPane>
        {adminsPerRole &&
          Object.keys(adminsPerRole).length &&
          Object.keys(adminsPerRole).map((role, index) => {
            return (
              role?.length && (
                <TabPane
                  tab={`${role} (${adminsPerRole[role].length})`}
                  key={index + 2}
                >
                  <Table
                    onRow={(record) => {
                      return {
                        onClick: (event) => {
                          history.push({
                            pathname: `/configs/roles/user`,
                            state: record,
                          });
                        },
                      };
                    }}
                    dataSource={adminsPerRole[role]}
                    rowKey={'id'}
                    columns={columns}
                    pagination={adminsPerRole[role].length > 10}
                  />
                </TabPane>
              )
            );
          })}
        {adminsPerRole &&
          Object.keys(adminsPerRole).length &&
          Object.keys(adminsPerRole)
            .filter((role) => !role.trim().length)
            .map((role, index) => {
              return (
                <TabPane
                  tab={`No Role (${adminsPerRole[role].length})`}
                  key={Object.keys(adminsPerRole).length + 2}
                >
                  <Table
                    onRow={(record) => {
                      return {
                        onClick: (event) => {
                          history.push({
                            pathname: `/configs/roles/user`,
                            state: record,
                          });
                        },
                      };
                    }}
                    dataSource={adminsPerRole[role]}
                    rowKey={'id'}
                    columns={columns}
                    pagination={adminsPerRole[role].length > 10}
                  />
                </TabPane>
              );
            })}
      </Tabs>
    );
  };
  const handleAddRoleClick = () => {
    setCreateRoleModal(true);
  };
  const handleCancelNewRole = () => {
    setCreateRoleModal(false);
    dispatch(createNewRoleReset());
    handleReset();
  };
  const onFinish = (values) => {
    handleCreateNewRole(values);
    setCreateRoleModal(false);
  };
  const handleReset = () => {};
  // layout for form
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const handleActivityLogs = async () => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    dispatch(
      fetchActivityLogs(header, {
        moduleType: 'ADMIN',
      })
    );
  };
  const handleOpenDrawer = async () => {
    setOpenDrawer(true);
    handleActivityLogs();
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const getRoleFormModal = () => {
    return (
      <Modal
        style={{ width: '500px' }}
        title={createNewRoleSuccess ? 'Add Permissions' : 'Create Role'}
        visible={createRoleModal}
        onCancel={handleCancelNewRole}
        footer={null}
      >
        {createNewRoleSuccess && Object.keys(newRoleData).length ? (
          <>
            <Text>Created new role successfully.</Text>
            <Link
              to={{
                pathname: `/configs/roles/role`,
                state: {
                  role: newRoleData.name,
                  admins: {},
                },
              }}
            >
              {`Add Permissions to ${newRoleData.name}`}
            </Link>
          </>
        ) : (
          <Form
            ref={formRef}
            name="timeForm"
            {...layout}
            onFinish={onFinish}
            validateTrigger={'onSubmit'}
          >
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <Form.Item
                  name="rolename"
                  label="Role Name"
                  rules={[
                    {
                      required: true,
                      message: 'Required field.',
                    },
                    {
                      max: 128,
                      message: 'Only 128 characters allowed.',
                    },
                    {
                      min: 3,
                      message: 'Enter minimum 3 characters.',
                    },
                    {
                      pattern: new RegExp('^[a-zA-Z]*$'),
                      message: `Only string allowed.`,
                    },
                  ]}
                >
                  <Input placeholder="e.g. Payment Admin" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'Please add description!',
                    },
                    {
                      max: 256,
                      message: 'Only 256 characters allowed.',
                    },
                    {
                      min: 6,
                      message: 'Enter minimum 6 characters.',
                    },
                    {
                      whitespace: true,
                      message: 'No whitespace allowed.',
                    },
                  ]}
                >
                  <TextArea
                    rows={3}
                    showCount={true}
                    placeholder="e.g. Payment read/write rights"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ marginBottom: '0px' }}>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Create
                    </Button>
                    <Button type="link" onClick={handleReset}>
                      Clear
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  };
  return (
    <>
      {permissions[UI_ACCESS_SCOPES.readAdmin] ? (
        <>
          <Row gutter={[20]}>
            <Col span={24}>
              <PageHeaders
                section="Configurations"
                subSection="Roles & Permissions"
              />
            </Col>
          </Row>
          <Row justify="space-between" align="middle" gutter={[20, 20]}>
            <Col>
              <Title tag={'h3'} weight={500} spacing={'none'} align={'left'}>
                Administrator Roles
              </Title>
            </Col>
            <Col>
              <Space>
                {permissions[UI_ACCESS_SCOPES.writeAdmin] && (
                  <Button type="primary" onClick={handleAddRoleClick}>
                    + Add Role
                  </Button>
                )}
                <Button align="center" type="link" onClick={handleOpenDrawer}>
                  View Activity Logs
                </Button>
              </Space>
            </Col>
            {getRoleFormModal()}
            <Col
              id="sc"
              onMouseEnter={() => showScroll('sc', true)}
              onMouseLeave={() => showScroll('sc', false)}
              style={{ width: '100%', overflow: 'hidden' }}
            >
              {loadingAdminPerRole ? (
                <Row gutter={[10, 0]} wrap={false}>
                  <Col flex={'300px'}>
                    <div className="z2-card padded">
                      <Skeleton paragraph={{ rows: 1 }} active />
                    </div>
                  </Col>
                  <Col flex={'300px'}>
                    <div className="z2-card padded">
                      <Skeleton paragraph={{ rows: 1 }} active />
                    </div>
                  </Col>
                  <Col flex={'300px'}>
                    <div className="z2-card padded">
                      <Skeleton paragraph={{ rows: 1 }} active />
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row gutter={[10, 0]} wrap={false}>
                  <>
                    {adminsPerRole && Object.keys(adminsPerRole).length ? (
                      Object.keys(adminsPerRole).map((key, index) => {
                        return (
                          key?.length > 0 && (
                            <Col flex={'300px'} key={index}>
                              <div className="z2-card padded no-shadow hover-shadow">
                                {adminsPerRole[key] &&
                                  adminsPerRole[key].length && (
                                    <>
                                      <Row
                                        justify="space-between"
                                        gutter={[20, 0]}
                                      >
                                        <Col>
                                          <Text spacing="xs" theme="gray">
                                            {adminsPerRole[key].length} ACCOUNTS
                                          </Text>
                                        </Col>
                                        <Col>
                                          {getAvatarGroup(adminsPerRole[key])}
                                        </Col>
                                      </Row>
                                      <Row
                                        justify="space-between"
                                        gutter={[20, 0]}
                                      >
                                        <Col>
                                          <Text
                                            spacing="xs"
                                            size="md"
                                            capitalize={true}
                                          >
                                            {key
                                              .split('_')
                                              .join(' ')
                                              .toLowerCase()}
                                          </Text>
                                          <Link
                                            to={{
                                              pathname: `/configs/roles/role`,
                                              state: {
                                                role: key,
                                                admins: adminsPerRole[key],
                                              },
                                            }}
                                          >
                                            Manage
                                          </Link>
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                              </div>
                            </Col>
                          )
                        );
                      })
                    ) : (
                      <div>No roles found!</div>
                    )}
                  </>
                  {remainingRoles &&
                    remainingRoles.length > 0 &&
                    remainingRoles.map((role, index) => {
                      return (
                        <Col flex={'300px'} key={index}>
                          <div
                            className="z2-card padded no-shadow hover-shadow"
                            key={index}
                          >
                            <Row justify="space-between" gutter={[20, 0]}>
                              <Col>
                                <Text spacing="sm">0 ACCOUNTS</Text>
                              </Col>
                            </Row>
                            <Row justify="space-between" gutter={[20, 0]}>
                              <Col>
                                <Text spacing="xs" size="md">
                                  {role.name}
                                </Text>
                              </Col>
                            </Row>
                            <Row justify="space-between" gutter={[20, 0]}>
                              <Col>
                                <Link
                                  to={{
                                    pathname: `/configs/roles/role`,
                                    state: {
                                      role: role.name,
                                      admins: {},
                                    },
                                  }}
                                >
                                  Manage
                                </Link>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              )}
            </Col>
          </Row>
          <Divider style={{ marginTop: '10px' }} />

          <Row>
            <Col span={24}>
              <Title tag={'h3'} weight={500} spacing={'xs'} align={'left'}>
                Administrator Accounts
              </Title>
              <Text theme="gray">Find all zebb administrator accounts</Text>
            </Col>
          </Row>
          <div className="z2-container">
            <Row>{getTabs()}</Row>
          </div>
          <Drawer
            title="Admin Activity Log"
            width={450}
            onClose={handleCloseDrawer}
            visible={openDrawer}
            bodyStyle={{ paddingBottom: 80, paddingTop: '10px' }}
          >
            {activityLogs &&
              activityLogs.length > 0 &&
              activityLogs.map((item) => {
                return (
                  <div key={item.id}>
                    <Row>
                      <Col flex="20px">
                        <Sprite
                          id={'action'}
                          width={15}
                          height={15}
                          styles={{ marginRight: '5px' }}
                        />
                      </Col>
                      <Col flex="250px">
                        <Text spacing="none">{item['activity']}</Text>
                        <Text spacing="none" theme="gray" italics={true}>
                          {item['remarks']}
                        </Text>
                      </Col>
                      <Col flex="120px">
                        <Text align="right" spacing="none">
                          {item.adminData['email']}
                        </Text>
                        <Text
                          size="xs"
                          theme="lightgray"
                          align="right"
                          spacing="none"
                        >
                          {item['createdAt']
                            ? formatDate(activityLogs[0]['createdAt'])
                            : ''}
                        </Text>
                      </Col>
                    </Row>
                    <Divider
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    />
                  </div>
                );
              })}
          </Drawer>
        </>
      ) : (
        <Text size="md">You don't have permission to view admins.</Text>
      )}
    </>
  );
};

export default AdminUsers;
