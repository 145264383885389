
const ENVIRONMENTS = {
  test: {
    // REACT_APP_AUTH0_CLIENT_ID: 'nkk5ZrBeTtpJXnvkq3SYaNSiOqIdUTHE',
    // REACT_APP_AUTH0_DOMAIN: 'auth.zebpay.co',
    // REACT_APP_AUTH0_AUDIENCE:
    //   'https://ls5cyeqvc2.execute-api.ap-south-1.amazonaws.com/publicapitest',
    BASE_URL:
      // 'https://walletdashboard-api.zebpay.co',
      'https://walletbackend.zebpay.co',
    // BASE_URL: 'http://localhost:3003',
    // RESOURCE_SERVER_IDENTIFIER: 'https://bbz-adminapi.zebpay.co',
  },
  development: {
    // REACT_APP_AUTH0_CLIENT_ID: 'nkk5ZrBeTtpJXnvkq3SYaNSiOqIdUTHE',
    // REACT_APP_AUTH0_DOMAIN: 'auth.zebpay.co',
    // REACT_APP_AUTH0_AUDIENCE:
    //   'https://ls5cyeqvc2.execute-api.ap-south-1.amazonaws.com/publicapitest',
    // BASE_URL: 'https://walletdashboard-api.zebpay.co',
    BASE_URL: 'https://walletbackend.zebpay.co',
    // CLIENT_ID:"210444519303-f63559qmiit2c2nv0apdk3kgu3ip5oll.apps.googleusercontent.com",
    CLIENT_ID:'956054052063-ihastq9rpemmvmnt41b0db4usdjf371h.apps.googleusercontent.com'
    // existing was this 👇
    // CLIENT_ID: '460216326375-o9139q5cbddaikm1ebbr8echmiuqb1l6.apps.googleusercontent.com'
  //   CLIENT_ID: '460216326375-o9139q5cbddaikm1ebbr8echmiuqb1l6.apps.googleusercontent.com'
    // RESOURCE_SERVER_IDENTIFIER: 'https://bbz-adminapi.zebpay.co',
  },
  staging: {
    // REACT_APP_AUTH0_CLIENT_ID: '2FZpgvkFfbrcCCAbEODJFKiBGgy3uXP9',
    // REACT_APP_AUTH0_DOMAIN: 'auth.zebuat.com',
    // REACT_APP_AUTH0_AUDIENCE: 'https://bbz-adminapi.zebpay.co',
    // CLIENT_ID:"210444519303-f63559qmiit2c2nv0apdk3kgu3ip5oll.apps.googleusercontent.com",
    CLIENT_ID:'956054052063-ihastq9rpemmvmnt41b0db4usdjf371h.apps.googleusercontent.com',
     // existing was this 👇
    // CLIENT_ID: '460216326375-o9139q5cbddaikm1ebbr8echmiuqb1l6.apps.googleusercontent.com',
    BASE_URL:
      // 'https://walletdashboard-api.zebpay.co',
      'https://walletbackend.zebpay.co',
    // RESOURCE_SERVER_IDENTIFIER: 'https://bbz-adminapi.zebpay.co',
  },
  main: {
    // REACT_APP_AUTH0_CLIENT_ID: 'JjLmSAU0znzTQPyFK5p9kRwDIGMFVOjN',
    // REACT_APP_AUTH0_DOMAIN: 'auth.zebbadmin.rockingrhinos.trade',
    // REACT_APP_AUTH0_AUDIENCE: 'https://bbz-adminapi.zebpay.com',
    // CLIENT_ID:"210444519303-f63559qmiit2c2nv0apdk3kgu3ip5oll.apps.googleusercontent.com",
    CLIENT_ID:'956054052063-ihastq9rpemmvmnt41b0db4usdjf371h.apps.googleusercontent.com',
    // existing was this 👇
    // CLIENT_ID: '460216326375-o9139q5cbddaikm1ebbr8echmiuqb1l6.apps.googleusercontent.com',
    BASE_URL:
      // 'https://walletdashboard-api.zebpay.co',
      'https://walletbackend.zebpay.co',
    // RESOURCE_SERVER_IDENTIFIER: 'https://bbz-adminapi.zebpay.com',
  },
};
export const ENV_CONFIG = ENVIRONMENTS[process.env.REACT_APP_ENV];

