export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const FETCH_ACTIVITY_LOG_REQUEST = 'FETCH_ACTIVITY_LOG_REQUEST';
export const FETCH_ACTIVITY_LOG_SUCCESS = 'FETCH_ACTIVITY_LOG_SUCCESS';
export const FETCH_ACTIVITY_LOG_FAILED = 'FETCH_ACTIVITY_LOG_FAILED';
export const FETCH_ALL_ADMINS_REQUEST = 'FETCH_ALL_ADMINS_REQUEST';
export const FETCH_ALL_ADMINS_SUCCESS = 'FETCH_ALL_ADMINS_SUCCESS';
export const FETCH_ALL_ADMINS_FAILED = 'FETCH_ALL_ADMINS_FAILED';
export const ASSIGN_ROLES_REQUEST = 'ASSIGN_ROLES_REQUEST';
export const ASSIGN_ROLES_SUCCESS = 'ASSIGN_ROLES_SUCCESS';
export const ASSIGN_ROLES_FAILED = 'ASSIGN_ROLES_FAILED';
export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILED = 'FETCH_ROLES_FAILED';
export const FETCH_ALL_ADMINS_PER_ROLE_REQUEST =
  'FETCH_ALL_ADMINS_PER_ROLE_REQUEST';
export const FETCH_ALL_ADMINS_PER_ROLE_SUCCESS =
  'FETCH_ALL_ADMINS_PER_ROLE_SUCCESS';
export const FETCH_ALL_ADMINS_PER_ROLE_FAILED =
  'FETCH_ALL_ADMINS_PER_ROLE_FAILED';
export const FETCH_PERMISSIONS_FOR_ROLE_REQUEST =
  'FETCH_PERMISSIONS_FOR_ROLE_REQUEST';
export const FETCH_PERMISSIONS_FOR_ROLE_SUCCESS =
  'FETCH_PERMISSIONS_FOR_ROLE_SUCCESS';
export const FETCH_PERMISSIONS_FOR_ROLE_FAILURE =
  'FETCH_PERMISSIONS_FOR_ROLE_FAILURE';
export const ASSIGN_PERMISSIONS_TO_ROLE_REQUEST =
  'ASSIGN_PERMISSIONS_TO_ROLE_REQUEST';
export const ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS =
  'ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS';
export const ASSIGN_PERMISSIONS_TO_ROLE_FAILURE =
  'ASSIGN_PERMISSIONS_TO_ROLE_FAILURE';
export const REMOVE_PERMISSIONS_FROM_ROLE_REQUEST =
  'REMOVE_PERMISSIONS_FROM_ROLE_REQUEST';
export const REMOVE_PERMISSIONS_FROM_ROLE_SUCCESS =
  'REMOVE_PERMISSIONS_FROM_ROLE_SUCCESS';
export const REMOVE_PERMISSIONS_FROM_ROLE_FAILURE =
  'REMOVE_PERMISSIONS_FROM_ROLE_FAILURE';
export const FETCH_ALL_PERMISSIONS_REQUEST = 'FETCH_ALL_PERMISSIONS_REQUEST';
export const FETCH_ALL_PERMISSIONS_SUCCESS = 'FETCH_ALL_PERMISSIONS_SUCCESS';
export const FETCH_ALL_PERMISSIONS_FAILURE = 'FETCH_ALL_PERMISSIONS_FAILURE';
export const FETCH_DIRECT_PERMISSIONS_FOR_USER_REQUEST =
  'FETCH_DIRECT_PERMISSIONS_FOR_USER_REQUEST';
export const FETCH_DIRECT_PERMISSIONS_FOR_USER_SUCCESS =
  'FETCH_DIRECT_PERMISSIONS_FOR_USER_SUCCESS';
export const FETCH_DIRECT_PERMISSIONS_FOR_USER_FAILURE =
  'FETCH_DIRECT_PERMISSIONS_FOR_USER_FAILURE';
export const ASSIGN_DIRECT_PERMISSIONS_TO_USER_REQUEST =
  'ASSIGN_DIRECT_PERMISSIONS_TO_USER_REQUEST';
export const ASSIGN_DIRECT_PERMISSIONS_TO_USER_SUCCESS =
  'ASSIGN_DIRECT_PERMISSIONS_TO_USER_SUCCESS';
export const ASSIGN_DIRECT_PERMISSIONS_TO_USER_FAILURE =
  'ASSIGN_DIRECT_PERMISSIONS_TO_USER_FAILURE';
export const REMOVE_DIRECT_PERMISSIONS_FOR_USER_REQUEST =
  'REMOVE_DIRECT_PERMISSIONS_FOR_USER_REQUEST';
export const REMOVE_DIRECT_PERMISSIONS_FOR_USER_SUCCESS =
  'REMOVE_DIRECT_PERMISSIONS_FOR_USER_SUCCESS';
export const REMOVE_DIRECT_PERMISSIONS_FOR_USER_FAILURE =
  'REMOVE_DIRECT_PERMISSIONS_FOR_USER_FAILURE';

export const FETCH_ALL_USER_WITH_ROLE_REQUEST =
  'FETCH_ALL_USER_WITH_ROLE_REQUEST';
export const FETCH_ALL_USER_WITH_ROLE_SUCCESS =
  'FETCH_ALL_USER_WITH_ROLE_SUCCESS';
export const FETCH_ALL_USER_WITH_ROLE_FAILURE =
  'FETCH_ALL_USER_WITH_ROLE_FAILURE';

export const FETCH_ROLES_FOR_USER_REQUEST = 'FETCH_ROLES_FOR_USER_REQUEST';
export const FETCH_ROLES_FOR_USER_SUCCESS = 'FETCH_ROLES_FOR_USER_SUCCESS';
export const FETCH_ROLES_FOR_USER_FAILURE = 'FETCH_ROLES_FOR_USER_FAILURE';

export const REMOVE_ROLE_FROM_USER_REQUEST = 'REMOVE_ROLE_FROM_USER_REQUEST';
export const REMOVE_ROLE_FROM_USER_SUCCESS = 'REMOVE_ROLE_FROM_USER_SUCCESS';
export const REMOVE_ROLE_FROM_USER_FAILURE = 'REMOVE_ROLE_FROM_USER_FAILURE';

export const CREATE_NEW_ROLE_REQUEST = 'CREATE_NEW_ROLE_REQUEST';
export const CREATE_NEW_ROLE_SUCCESS = 'CREATE_NEW_ROLE_SUCCESS';
export const CREATE_NEW_ROLE_FAILURE = 'CREATE_NEW_ROLE_FAILURE';
export const CREATE_NEW_ROLE_RESET = 'CREATE_NEW_ROLE_RESET';

export const ASSIGN_USERS_TO_ROLE_REQUEST = 'ASSIGN_USERS_TO_ROLE_REQUEST';
export const ASSIGN_USERS_TO_ROLE_SUCCESS = 'ASSIGN_USERS_TO_ROLE_SUCCESS';
export const ASSIGN_USERS_TO_ROLE_FAILURE = 'ASSIGN_USERS_TO_ROLE_FAILURE';
export const RESET_ASSIGN_ROLES_STATE = 'RESET_ASSIGN_ROLES_STATE';
export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';
export const DELETE_ROLE_RESET = 'DELETE_ROLE_RESET';
export const GET_ADMIN_PROFILE_REQUEST = 'GET_ADMIN_PROFILE_REQUEST';
export const GET_ADMIN_PROFILE_SUCCESS = 'GET_ADMIN_PROFILE_SUCCESS';
export const GET_ADMIN_PROFILE_FAILURE = 'GET_ADMIN_PROFILE_FAILURE';
export const UPDATE_APP_CONFIG_REQUEST = 'UPDATE_APP_CONFIG_REQUEST';
export const UPDATE_APP_CONFIG_SUCCESS = 'UPDATE_APP_CONFIG_SUCCESS';
export const UPDATE_APP_CONFIG_FAILURE = 'UPDATE_APP_CONFIG_FAILURE';
export const FETCH_APP_CONFIG_REQUEST = 'FETCH_APP_CONFIG_REQUEST';
export const FETCH_APP_CONFIG_SUCCESS = 'FETCH_APP_CONFIG_SUCCESS';
export const FETCH_APP_CONFIG_FAILURE = 'FETCH_APP_CONFIG_FAILURE';
