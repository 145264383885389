import React from 'react';
import PropTypes from 'prop-types';
import classes from './Image.module.scss';
const Image = ({ isRound, containerClass, src, children }) => {
  return (
    <div
      className={`${classes.image} ${containerClass} ${
        isRound ? classes.rounded : ''
      }`}
    >
      <img src={src} alt={children} />
    </div>
  );
};

Image.defaultProps = {
  children: '',
};

Image.propTypes = {
  src: PropTypes.string,
  containerClass: PropTypes.string,
  isRound: PropTypes.bool,
};

export default Image;
