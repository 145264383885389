import React, { useEffect } from 'react';
import {Route, Redirect} from 'react-router-dom';
import { useGoogleAuth } from 'components/google-login/GoogleAuthProvider';
import axios from 'axios';
const PrivateRoute = ({component: Component, ...rest}) => {

    const { isSignedIn,googleUser } = useGoogleAuth();
    useEffect(()=>{
console.log(googleUser);
if(isSignedIn){
    axios({
             method:"Post",
             url: "https://walletbackend.zebpay.co/auth/login/google",
             data: {token: googleUser.tokenId}
           }).then(response => {
            localStorage.setItem('returnedToken', response.data.userToken);
        });
}
    },[isSignedIn]);

    return (
        <>
            <Route {...rest} render={props => (
                isSignedIn ?
                <Component {...props} />: 
                <Redirect exact to="/login" />
            )} />
        </>
    );
};

export default PrivateRoute;