import { Row, Col, Button, Select, Form } from 'antd';
import { Title, Sprite } from 'components/atoms';
import React, { useEffect, useRef, useState } from 'react';
import { updateAppConfig } from 'redux/admin/adminActions';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { jsonApiHeader } from 'utils/Utility';

import Moment from 'moment';
import { localStorageSetItem } from 'services/LocalStorageService';
import { STORAGE_KEYS } from '../../../constants';
const { Option } = Select;
const TimeTab = (props) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const formRef = useRef();
  const [initialFormData, setInitialFormData] = useState();
  const [submit, setSubmit] = useState(false);
  const { adminAppConfig } = useSelector((state) => state.adminReducer);
  const handleUpdateAppConfig = async (config) => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    dispatch(updateAppConfig(header, { userId: user.sub, config }));
  };
  const onFinish = (values) => {
    // let date = formRef.current.getFieldValue('dateFormat')
    if (values.dateFormat) {
      let config = { ...adminAppConfig };
      let configToUpdate = { ...config, dateFormat: values.dateFormat };
      handleUpdateAppConfig(configToUpdate);
      setSubmit(true);
      localStorageSetItem(STORAGE_KEYS.DATE_FORMAT, values.dateFormat);
      window.location.reload();
    }
    setSubmit(false);
    props.onClose(true);
  };
  const close = () => {
    setSubmit(false);
  };
  const showDate = (val) => {
    let date = Moment().format(val);
    // setFormattedTime(date)
  };
  const onReset = () => {
    formRef.current.resetFields();
  };
  useEffect(() => {
    // if (localStorage.getItem('dateFormat')) {
    // 	formRef.current.setFieldsValue({
    // 		dateFormat: localStorage.getItem('dateFormat'),
    // 	});
    // }
    if (adminAppConfig.dateFormat) {
      formRef.current.setFieldsValue({
        dateFormat: adminAppConfig.dateFormat,
      });
    }
  }, [props]);

  return (
    <div>
      <Row>
        <Col>
          <Title tag="h4" spacing="xs">
            Set Date & Time format
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form
            ref={formRef}
            name="timeForm"
            onFinish={onFinish}
            initialValues={initialFormData}
          >
            <Form.Item name="dateFormat">
              <Select
                style={{ width: '100%' }}
                onChange={showDate}
                placeholder="Select a format"
              >
                <Option value="YYYY/MM/DD HH:mm:ss">
                  {Moment().format('YYYY/MM/DD HH:mm:ss')}
                </Option>
                <Option value="YYYY/MM/DD LTS">
                  {Moment().format('YYYY/MM/DD LTS')}
                </Option>
                <Option value="DD/MM/YYYY LTS">
                  {Moment().format('DD/MM/YYYY LTS')}
                </Option>
                <Option value="DD/MM/YYYY HH:mm:ss">
                  {Moment().format('DD/MM/YYYY HH:mm:ss')}
                </Option>
                <Option value="DD/MM/YYYY">
                  {Moment().format('DD/MM/YYYY')}
                </Option>
                <Option value="YYYY/MM/DD">
                  {Moment().format('YYYY/MM/DD')}
                </Option>
              </Select>
            </Form.Item>
            {/* {formattedTime && (
								<div  style={{marginTop: '10px' }}>
										<Text size={'sm'} theme="gray" italics={true}>Sample Date : {formattedTime}</Text>
								</div>
							)} */}
            <Row justify="end">
              <Col>
                <Form.Item style={{ margin: '0' }}>
                  {submit ? (
                    <Button
                      type="primary"
                      icon={<Sprite id="spin" width={29} height={29} />}
                      loading
                    >
                      Update
                    </Button>
                  ) : (
                    <Button type="primary" onClick={onFinish} htmlType="submit">
                      Update
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default TimeTab;
