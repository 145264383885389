import { combineReducers } from 'redux';
import userReducer from 'redux/user/userReducer';
import kycReducer from 'redux/kyc/kycReducer';
import tradeReducer from 'redux/trading/tradeReducer';
import adminReducer from 'redux/admin/adminReducer';
import cryptoReducer from 'redux/crypto/cryptoReducer';
import paymentReducer from 'redux/payments/paymentReducer';
import buysellReducer from './trading/buysell/buysellReducer';
import screenOptionsReducer from './screenoptions/screenOptionsReducer';
import analyticsReducer from 'redux/analytics/analyticsReducer';
import referralReducer from 'redux/referral/referralReducer';
import verificationReducer from 'redux/verification/VerificationReducer';

const appReducer = combineReducers({
  userReducer: userReducer,
  kycReducer: kycReducer,
  cryptoReducer: cryptoReducer,
  tradeReducer: tradeReducer,
  adminReducer: adminReducer,
  paymentReducer: paymentReducer,
  buysellReducer: buysellReducer,
  screenOptionsReducer: screenOptionsReducer,
  analyticsReducer: analyticsReducer,
  referralReducer: referralReducer,
  verificationReducer: verificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
