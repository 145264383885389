import React from 'react';
import PropTypes from 'prop-types';
import classes from './Anchor.module.scss';

const Anchor = ({
  iconBefore,
  iconAfter,
  children,
  underlined,
  italics,
  extraClass,
  openInNewTab,
  onClick,
  href,
  ...props
}) => {
  return (
    <a
      target={`${openInNewTab ? '_blank' : ''}`}
      rel={`${openInNewTab ? 'noopener noreferrer' : ''}`}
      {...props}
      onClick={onClick}
      className={`${classes.anchor} ${underlined ? classes.underlined : ''} ${
        italics ? classes.italics : ''
      } ${extraClass}`}
      href={href || 'javascript:void(0)'}
    >
      {iconBefore && <span className={classes.iconBefore}>{iconBefore}</span>}

      {children}
      {iconAfter && <span className={classes.iconAfter}>{iconAfter}</span>}
    </a>
  );
};

Anchor.defaultProps = {};

Anchor.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string,
};

export default Anchor;
