import React, { useEffect, useState } from 'react';
import { Row, Col, Switch, Menu, Layout, Avatar, Dropdown, Button } from 'antd';
import { Sprite } from 'components/atoms';
import classes from './Header.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { updateSidebarCollapse } from 'redux/screenoptions/screenOptionsActions';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'antd/lib/modal/Modal';
import { TimeTab } from 'pages/Configuration/tabs';
import GlobalSearch from 'components/molecules/GlobalSearch/GlobalSearch.js';
import { updateAppConfig } from 'redux/admin/adminActions';
import { jsonApiHeader } from 'utils/Utility';
import { localStorageRemoveItem } from 'services/LocalStorageService';
import { STORAGE_KEYS } from '../../../constants';
import { useGoogleAuth } from 'components/google-login/GoogleAuthProvider';
import { Link } from 'react-router-dom';
const HeaderAnt = Layout.Header;

const Header = ({ isScrolled, ...props }) => {

  const { signOut } = useGoogleAuth();
  const  {googleUser} = useGoogleAuth();

  const [user,setUser]= useState();
  console.log(googleUser.profileObj);
  const { getAccessTokenSilently } = useAuth0();
  const { sidebarOptions } = useSelector((state) => state.screenOptionsReducer);
  const dispatch = useDispatch();
  const { adminAppConfig } = useSelector((state) => state.adminReducer);

  const { isCollapsed } = sidebarOptions;
  const [isDateTimeOpen, setIsDateTimeOpen] = useState(false);
  const toggleSidebar = () => {
    console.log(isCollapsed);
    dispatch(updateSidebarCollapse(!isCollapsed));
  };
  useEffect(() => {}, [sidebarOptions]);
  useEffect(()=>{
    localStorage.setItem('email', googleUser.profileObj.email);
  },[googleUser])

  const toggleDateModal = () => {
    setIsDateTimeOpen(true);
  };
  const handleOk = () => {};
  const handleUpdateAppConfig = async (config) => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    dispatch(updateAppConfig(header, { userId: user.sub, config }));
  };
  

  const menu = (
    <Menu>
      {/* <Menu.Item //hidden as we are not doing reset password
        icon={
          <Sprite
            id="password"
            width={12}
            height={14}
            styles={{ marginRight: '8px', marginBottom: '-2px' }}
          />
        }
      >
        <a
          style={{ color: 'var(--gray)' }}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          Change Password
        </a>
      </Menu.Item> */}
      <Menu.Item
        onClick={toggleDateModal}
        icon={
          <Sprite
            id="clock"
            width={12}
            height={14}
            styles={{ marginRight: '8px', marginBottom: '-2px' }}
          />
        }
      >
        <a style={{ color: 'var(--gray)' }}>Date & Time settings</a>
      </Menu.Item>
      <Menu.Item
        icon={
          <Sprite
            id="dashboard"
            width={12}
            height={14}
            styles={{ marginRight: '8px', marginBottom: '-2px' }}
          />
        }
      >
        <Link to="/admin-panel"><a style={{ color: 'var(--gray)' }}>Admin Panel</a></Link>
      </Menu.Item>
      <Menu.Item
        icon={
          <Sprite
            id="exit"
            width={14}
            height={14}
            styles={{ marginRight: '8px', marginBottom: '-2px' }}
          />
        }
        onClick={() => {
          localStorageRemoveItem(STORAGE_KEYS.AUTH);
          signOut();
        }}
      >
        <a type="text" style={{ color: 'var(--gray)' }}>
          Log out
        </a>
      </Menu.Item>
    </Menu>
  );
  function onThemeChange(checked) {
    console.log('Theme changed', checked);
    let config = { ...adminAppConfig };
    let configToUpdate = {
      ...config,
      'theme-dark': !adminAppConfig['theme-dark'],
    };
    handleUpdateAppConfig(configToUpdate);
    document.querySelector('body').classList.toggle('theme-dark');
  }
  
  return (
    <HeaderAnt
      className={`z2-admin-header ${isScrolled ? 'scrolled' : ''} ${
        isCollapsed ? 'expanded' : ''
      }`}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <a onClick={toggleSidebar}>
            <Sprite id="hamburger" width={20} height={20} />
          </a>
        </Col>
        <Col>
          {/* <GlobalSearch user={user} /> */}
        </Col>
        <Col>
          <div className={classes.headerOptions}>
            {/* <Switch
              unCheckedChildren={<Sprite id="night" width={15} height={22} />}
              checkedChildren={<Sprite id="day" width={15} height={22} />}
              defaultChecked
              onChange={onThemeChange}
            /> */}
            <div className={classes.user}>
              <Avatar size={{ xs: 24, sm: 32, md: 40 }} src={googleUser.profileObj?.imageUrl} />
              <span className={classes.name}>{googleUser.profileObj?.givenName}</span>
              <Dropdown overlay={menu} placement="bottomRight">
                <Button type="link" style={{ padding: 0, height: '20px' }}>
                  <Sprite
                    width={12}
                    height={12}
                    id="chevron-down"
                    style={{ color: 'var(--gray)' }}
                  />
                </Button>
              </Dropdown>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        title="Date & Time Settings"
        className={'noModalFooter'}
        visible={isDateTimeOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setIsDateTimeOpen(false)}
      >
        <TimeTab onClose={(v) => setIsDateTimeOpen(v)}></TimeTab>
      </Modal>
    </HeaderAnt>
  );
};

export default Header;
