import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col,Table,Select, Button,notification, Tag} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  KycAnalyticsCard,
  PageHeaders,
  PreviewUserCard,
} from 'components/molecules';
import { Sprite, Text, Title } from 'components/atoms';
import axios from 'axios';
import Meta from 'antd/lib/card/Meta';
// import styled from 'styled-components';
import {COINCONFIG} from "../../constants/coinconfig";
import moment from 'moment';
import {getBalanceSummary} from "services/Api";
import { useHistory } from 'react-router-dom';

var CurrencyFormat = require('react-currency-format');
var LOADEDCOINS = {};
const Dashboard = () => {
  const [walletData, setWalletData] = useState([]);
  const [walletInnerData, setWalletInnerData] = useState([]);
  const [expandedRowKeys,setExpandedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [internalLoading, setInternalLoading] = useState(false);

  const { Option } = Select;
  const history = useHistory();
  
  useEffect(() => {
    loadData();
  }, []);
  var today = new Date();
  const token =  localStorage.getItem('returnedToken');
  /*
* On loadData handler
* getBalanceSummary and setWalletData on loadData handler 
* @param res,err
* @return - null
*/
  const loadData = async() => {
    setLoading(true);
    getBalanceSummary((res,err)=>{
      if(err){
        console.log(err);
        notification['error']({
          message: err,
        });
      }
      if(res){
        console.log(res);
        setWalletData(res);
      }
      setLoading(false);
    })
  }
    /*
* On handleClick handler
* handle inner table collapse and fetch the response by passing coin and ts on handleClick handler 
* @param isOpen,row
* @return - null
*/
  const handleClick = async(isOpen,row) => {
    // console.log(isOpen);
    setInternalLoading(true);
    console.log(row);
    
    setExpandedRowKeys([row.Coin]);
   
    if(isOpen){
      setWalletInnerData([]);
      // keys.push(row.Coin);
      const date= moment(new Date).format("YYYY/MM/DD hh:mm:ss");
      // console.log(date)
      if(LOADEDCOINS[row.Coin]){
        setWalletInnerData(LOADEDCOINS[row.Coin])
        console.log('done from variable')
      }else{
        const selectedData = await axios.get(`https://walletbackend.zebpay.co/wallet/coin/current-balance?coin=${row.Coin}`, {
                      headers: {
                        Authorization: 'Bearer ' + token
                      }
                      });
                      // console.log(selectedData);
        setWalletInnerData(selectedData.data.data.walletBalance);
        console.log('done from api')
        LOADEDCOINS[row.Coin] = selectedData.data.data.walletBalance;
      }
      
    }
    
    setInternalLoading(false);       
  }

  /*Sum of all wallets*/
  var walletSum = walletData.reduce((acc, curr) => acc + curr.BalanceInUSD, 0);
  var dbBalanceSum = walletData.reduce((acc, curr) => acc + curr.dbBalanceinUSD, 0);
  var diffInUSDSum = walletData.reduce((acc, curr) => acc + curr.differenceinUSD, 0);

  const modifiedData = walletData.map(({body, ...item}) => ({
  ...item,
  key: item.id,
  }));

  const modifiedInnerData = walletInnerData.map(({body, ...item}) => ({
  ...item,
  key: item.id,
  }));
  const tempFilters = {};
 
  let temp =[];
  modifiedInnerData.map(item=>{
    if(!temp[item.walletType]){
      temp[item.walletType] = item.walletType;
    }else{
      return
    }
  })
    const expandedRowRender = (row,index) => {
      // console.log(row);
      // console.log(index);
      const walletColumns = [
        {
          title: 'Name',
          dataIndex: 'name',
        },
        {
          title: 'Wallet Type',
          dataIndex: 'walletType',
          filters: Object.keys(temp).map(item => ({ text: item, value: item })),
          onFilter: (value, record) => record.walletType.toLowerCase().includes(value.toLowerCase()),
          filterSearch: true,
          render: (text, row, index) => {
            // console.log(row);
          return (
            <div className="vertical-mid">
            <Sprite id={row.walletType.toLowerCase() + '-icon'} width={20} height={20} />  
            {row.walletType}
            </div>
            );
          },
        },
        // {
        //   title: 'Provider',
        //   dataIndex:'storageType'
        // },
        {
          title: 'Balance',
          dataIndex:'balance',
          defaultSortOrder: 'descend',
          align: 'right',
          sorter: (a, b) => a.balance - b.balance,
          render:(text, row, index)=>{
            return (
              <b>{new Intl.NumberFormat().format(text)}</b>
              // <b>{Math.round((text + Number.EPSILON) * 100) / 1000 }</b>
            )
          }
        },
        {
          title: 'Time - UTC',
          dataIndex:'ts',
          align: 'right',
        }
      
      ];

      return <div>
          <div className={"flex flex-sb"}>
            <Title tag="h3" spacing="none">
              All {row.Coin} Wallets
            </Title>
            <Button type="link" disabled={internalLoading?true:false} onClick={()=>(history.push(`/reports?coin=${row.Coin.toLowerCase()}&ts=${moment(new Date).format("YYYY/MM/DD hh:mm:ss")}`))} shape="circle" icon={<Sprite id="pie-chart" width={16} height={12} />}> View Reports </Button>            
          </div>
          <div className="z2-card bordered no-shadow">
            <Table bordered={true} columns={walletColumns} dataSource={modifiedInnerData} pagination={false} loading={internalLoading} />
          </div>
      </div>
    };
  const columns = [
    {
      title: 'Coin',
      dataIndex: 'Coin',
      key: 'Coin',
      // filterDropdown: ({setSelectedKeys, selectedKeys, confirm,clearFilters})=>{
      //   return(
      //     <div className="flex filerSearchDropdown">
      //     <Input 
      //     autoFocus 
      //     value= {selectedKeys[0]}
      //     placeholder="Search Coin" 
      //     onChange={(e)=>{
      //       setSelectedKeys(e.target.value?[e.target.value]:[]);
      //       // confirm({closeDropdown:false});
      //     }} 
      //     onPressEnter={()=>{confirm();}} 
      //     onBlur={()=>{confirm();}}
      //     />
      //     <Button type="primary" onClick= {() =>{
      //       confirm();
      //     }}>Search</Button>
      //     <Button type="secondary" onClick= {() =>{
      //       clearFilters({closeDropdown:false});
      //     }}>Reset</Button>
      //   </div>
      //   )
      // },
      // onFilter: (value, record)=>{
      //   return record.Coin.toLowerCase().includes(value.toLowerCase());
      // },
      // onFilter: (value, record) => record.address.startsWith(value),
      filters: modifiedData.map(item => ({ text: item.Coin, value: item.Coin })),
      onFilter: (value, record) => record.Coin.toLowerCase().includes(value.toLowerCase()),
      filterSearch: true,
      // render: (text, row, index) => {
      //   // console.log(row);
      //   const title  = COINCONFIG[row.Coin]? COINCONFIG[row.Coin].label:row.Coin;
      //   // const icon = COINCONFIG[row.Coin]? COINCONFIG[row.Coin].icon:<Avatar src='https://cdn.iconscout.com/icon/premium/png-256-thumb/crypto-coin-2432529-2015842.png' />;
      //   const icon =<Sprite id={[row.Coin]} type={'coin'} width={16} height={16} /> ;
      //   return (
      //     <Space>
      //         <span className="coin-icon-table">{icon}</span>
      //         <Text size={"20"} spacing={'none'}>{title}</Text>
      //       </Space>
          
      //     // <Meta avatar={icon} title={title}/>
      //     // <a  href={`https://walletbackend.zebpay.co/wallet/coin/balances?coin=${title}&ts=${currDate}`}><Meta avatar={icon} title={title}/></a>
      //   );
      // },
    },
    {
      title: 'DB Balance (No. of Coins)',
      dataIndex: 'dbBalance',
      key: 'dbBalance',
      align: 'right',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.dbBalance - b.dbBalance,
      render:(text, row, index)=>{
        return (
          <b>{new Intl.NumberFormat().format(text)}</b>
        )
      }
    },
    {
      title: 'LendingBalance (No. of Coins)',
      dataIndex: 'lendingBalance',
      key: 'lendingBalance',
      defaultSortOrder: 'descend',
      align: 'right',
      sorter: (a, b) => a.lendingBalance - b.lendingBalance,
      render:(text, row, index)=>{
        return (
          <b>{new Intl.NumberFormat().format(text)}</b>
          // <b>{Math.round((text + Number.EPSILON) * 100) / 1000 }</b>
        )
      }
    },
    {
      title: 'Wallet Balance (No. of Coins)',
      dataIndex: 'totalWalletBalance',
      key: 'totalWalletBalance',
      align: 'right',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.totalWalletBalance - b.totalWalletBalance,
      render: (text, row, index) => {
        return (
          <b>{new Intl.NumberFormat().format(text)}</b>
          // <b>{Math.round((text + Number.EPSILON) * 100) / 1000 }</b>
        );
      },
    },
    {
      title: 'Difference (No. of Coins)',
      dataIndex: 'difference',
      key: 'difference',
      align: 'right',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.difference - b.difference,
      render: (text, row, index) => {
        const color = parseInt(text) > 0 ? 'green':'red';
        return (
          <b style={{color:color}}>{new Intl.NumberFormat().format(text*(1))}</b>
        // <b style={{color:color}}>{Math.round((text + Number.EPSILON) * 100) / 1000 }</b>
        );
      },
    },
    {
      title: 'Difference (USD Value)',
      dataIndex: 'differenceinUSD',
      key: 'differenceinUSD',
      align: 'right',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.differenceinUSD - b.differenceinUSD,
      render: (text, row, index) => {
        const color = parseInt(text) > 0 ? 'green':'red';
        return (
          <b style={{color:color}}>{new Intl.NumberFormat().format(text*(1))}</b>
        // <b style={{color:color}}>{Math.round((text + Number.EPSILON) * 100) / 1000 }</b>
        );
      },
    }

  ];
  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  return (
    <div>
      <Row gutter={[20]}>
        <Col span={24}>
          <div>
          {/* <div style={{display: 'inline', marginRight: '5px'}}>
            <Tag style={{margin:'5px', padding:'5px', fontSize: '20px'}} color='blue'>DB Balance in USD: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dbBalanceSum)}</Tag>
          </div> */}

          <div style={{display: 'inline', marginRight: '5px'}}>
            <Tag style={{margin:'5px', padding:'5px', fontSize: '20px'}} color='blue'>Wallet Balance in USD: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(walletSum)}</Tag>
          </div>
          <div style={{display: 'inline', marginRight: '5px'}}>
            {
              diffInUSDSum >= 0 ? <Tag style={{margin:'5px', padding:'5px', fontSize: '20px'}} color='green'>Delta in USD: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(diffInUSDSum)}</Tag> : <Tag style={{margin:'5px',padding:'5px', fontSize: '20px'}} color='red'>Delta in USD: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(diffInUSDSum)}</Tag> 
            }
          </div>
          {/* <div style={{display: 'inline', marginRight: '5px'}}>
            {
              diffInUSDSum >= 0 ? <Tag style={{margin:'5px', padding:'5px', fontSize: '20px'}} color='green'>All good👍👍</Tag> : <Tag style={{margin:'5px',padding:'5px', fontSize: '20px'}} color='red'>Not Good👎👎</Tag> 
            }
          </div> */}
          </div>
        </Col>
      </Row>
      {/* {permissions[UI_ACCESS_SCOPES.readUser] ? ( */}
        <>
          <Row gutter={[20, 20]} wrap={false}>
            <Col flex="auto">
              <Row gutter={[20, 20]}>
                <Col span={24}>
                <Table 
                  columns={columns} 
                  expandedRowKeys={expandedRowKeys} 
                  onExpand={handleClick} 
                  className="assets-table components-table-demo-nested" 
                  rowKey="Coin" 
                  expandable={{ expandedRowRender }} 
                  dataSource={modifiedData} 
                  pagination={{ defaultPageSize: 15}} 
                  loading={loading}
                  />
                
                  {/* <NewUsersChart onSelectDateChange={onUserPreviewChange} /> */}
                </Col>
              </Row>
            </Col>
        
            {/* ) : null} */}
          </Row>
        </>
      {/* ) : ( */}
        {/* <Text size="md">You don't have permission to view this screen.</Text> */}
      {/* )} */}
    </div>
  );
};

export default Dashboard;
