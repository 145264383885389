import React from 'react';
import PropTypes from 'prop-types';
import classes from './label.module.scss';
const Label = (props) => {
  return (
    <p
      {...props}
      className={`${classes.text} ${
        props.spacing ? classes[props.spacing] : ''
      } 
            ${props.size ? `font${classes[props.size]}` : ` ${classes.fontsm}`}
            ${props.theme ? classes[props.theme] : 'dark'} ${
        props.align ? classes[props.align] : ''
      } ${props.capitalize ? 'capitalize' : ''}`}
      style={{ fontWeight: props.weight }}
    >
      {props.children}
    </p>
  );
};

Label.defaultProps = {
  children: '',
};

Label.propTypes = {
  children: PropTypes.any.isRequired,
  spacing: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'none']),
  align: PropTypes.oneOf(['left', 'right', 'center']),
  theme: PropTypes.oneOf(['red', 'light', 'dark', 'gray']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  weight: PropTypes.string,
};

export default Label;
