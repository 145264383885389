import React from 'react';
import { AdminWrapper } from 'components/organisms';
import 'styles/app.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
// import { Loader } from 'components/atoms';
import NewLogin from 'pages/LoginPages/NewLogin';

import PrivateRouter from "routes/PrivateRouter";
import PublicRouter from "routes/PublicRouter";
function App() {
  // const { isAuthenticated, isLoading } = useAuth0();

  // if (isLoading) {
  //   return isAuthenticated ? (
  //     <Loader text={'Loading...'} type={'basic'} />
  //   ) : (
  //     <Loader text={'Authenticating..'} type={'basic'} />
  //   );
  // }

  return (
    <div style={{ height: '100%' }}>
      <Router>
        {/* <Switch>
          <Route path="/login" component={Login} exact/>
          <Route path="/" component={AdminWrapper} />
        </Switch> */}
        <PrivateRouter path="/" component={AdminWrapper} />                
        <PublicRouter path="/login" component={NewLogin} exact/>
      </Router>
      <div id="tltpM" />
    </div>
  );
}

export default App;
