import { Button } from 'antd';
import React from 'react';
import { useGoogleAuth } from './GoogleAuthProvider';

const LoginButton = () => {
    const { signIn } = useGoogleAuth();

    return (
      <Button  type="primary" onClick={signIn}>Login</Button>
      );
};

export default LoginButton;