export const FETCH_BUYSELL_DATA_REQUEST = 'FETCH_BUYSELL_DATA_REQUEST';
export const FETCH_BUYSELL_DATA_SUCCESS = 'FETCH_BUYSELL_DATA_SUCCESS';
export const FETCH_BUYSELL_DATA_FAILURE = 'FETCH_BUYSELL_DATA_FAILURE';
export const FETCH_INSTANT_ORDER_DETAILS_REQUEST =
  'FETCH_INSTANT_ORDER_DETAILS_REQUEST';
export const FETCH_INSTANT_ORDER_DETAILS_SUCCESS =
  'FETCH_INSTANT_ORDER_DETAILS_SUCCESS';
export const FETCH_INSTANT_ORDER_DETAILS_FAILURE =
  'FETCH_INSTANT_ORDER_DETAILS_FAILURE';
export const FETCH_TRADE_VOLUME_DETAILS_REQUEST =
  'FETCH_TRADE_VOLUME_DETAILS_REQUEST';
export const FETCH_TRADE_VOLUME_DETAILS_SUCCESS =
  'FETCH_TRADE_VOLUME_DETAILS_SUCCESS';
export const FETCH_TRADE_VOLUME_DETAILS_FAILURE =
  'FETCH_TRADE_VOLUME_DETAILS_FAILURE';
export const FETCH_ALL_BUYSELL_DATA_SUCCESS = 'FETCH_ALL_BUYSELL_DATA_SUCCESS';
export const FETCH_ALL_BUYSELL_DATA_REQUEST = 'FETCH_ALL_BUYSELL_DATA_REQUEST';
export const FETCH_ALL_BUYSELL_DATA_FAILURE = 'FETCH_ALL_BUYSELL_DATA_FAILURE';
export const FETCH_ALL_RECENT_TXNS_SUCCESS = 'FETCH_ALL_RECENT_TXNS_SUCCESS';
export const FETCH_ALL_RECENT_TXNS_REQUEST = 'FETCH_ALL_RECENT_TXNS_REQUEST';
export const FETCH_ALL_RECENT_TXNS_FAILURE = 'FETCH_ALL_RECENT_TXNS_FAILURE';
export const SET_FILTER_QUERY_PARAMS = 'SET_FILTER_QUERY_PARAMS';
