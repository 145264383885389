export const FETCH_CRYPTO_TXN_REQUEST = 'FETCH_CRYPTO_TXN_REQUEST';
export const FETCH_CRYPTO_TXN_SUCCESS = 'FETCH_CRYPTO_TXN_SUCCESS';
export const FETCH_CRYPTO_TXN_FAILED = 'FETCH_CRYPTO_TXN_FAILED';
export const FETCH_SENDING_ADDRESSES_REQUEST =
  'FETCH_SENDING_ADDRESSES_REQUEST';
export const FETCH_SENDING_ADDRESSES_SUCCESS =
  'FETCH_SENDING_ADDRESSES_SUCCESS';
export const FETCH_SENDING_ADDRESSES_FAILED = 'FETCH_SENDING_ADDRESSES_FAILED';
export const FETCH_RECEIVING_ADDRESSES_REQUEST =
  'FETCH_RECEIVING_ADDRESSES_REQUEST';
export const FETCH_RECEIVING_ADDRESSES_SUCCESS =
  'FETCH_RECEIVING_ADDRESSES_SUCCESS';
export const FETCH_RECEIVING_ADDRESSES_FAILED =
  'FETCH_RECEIVING_ADDRESSES_FAILED';
export const FETCH_CURRENCY_SETTINGS_REQUEST =
  'FETCH_CURRENCY_SETTINGS_REQUEST';
export const FETCH_CURRENCY_SETTINGS_SUCCESS =
  'FETCH_CURRENCY_SETTINGS_SUCCESS';
export const FETCH_CURRENCY_SETTINGS_FAILED = 'FETCH_CURRENCY_SETTINGS_FAILED';
