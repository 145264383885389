import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from 'pages/Dashboard/Dashboard';
import Reports from 'pages/Reports/Reports';
import Wallets from 'pages/Wallets/Wallets';
import Assets from 'pages/Assets/Assets.jsx';
import Search from 'pages/Wallets/Search';
import Refill from 'pages/Refill/Refill';
import PrivateRoute from './PrivateRoute';
import AdminPanel from 'pages/AdminPanel/AdminPanel';
import UserBalance from 'pages/UserBalance/UserBalance';

const Routes = () => {
  console.log('loading routes');
  return (
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route exact path="/reports" component={Reports} />
      <Route exact path="/assets" component={Assets} />
      <Route exact path="/refill" component={Refill} />
      <Route exact path="/wallets/search" component={Search} />
      <Route exact path="/wallets/allwallets" component={Wallets} />
      <Route exact path="/admin-panel" component={AdminPanel} />
      <Route exact path="/userbalance" component={UserBalance} />
    </Switch>
  );
};

export default memo(Routes);
