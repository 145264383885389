import {
  REFERRAL_DETAILS_REQUEST,
  REFERRAL_DETAILS_SUCCESS,
  REFERRAL_DETAILS_FAILURE,
  TOTAL_EARNING_REQUEST,
  TOTAL_EARNING_SUCCESS,
  TOTAL_EARNING_FAILURE,
  BLOCK_REFERRAL_FAILURE,
  BLOCK_REFERRAL_REQUEST,
  BLOCK_REFERRAL_SUCCESS,
} from './referralActionTypes';

export const initialState = {
  loadingBlockReferral: false,
  blockReferralSuccess: false,
  blockReferralErrorMsg: '',

  loadingReferralDetails: false,
  referralDetails: false,
  referralDetailsErrorMsg: '',

  loadingTotalEarnings: false,
  totalEarnings: false,
  totalEarningsErrorMsg: '',
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFERRAL_DETAILS_REQUEST:
      return {
        ...state,
        loadingReferralDetails: true,
        referralDetails: false,
        referralDetailsErrorMsg: '',
      };
    case REFERRAL_DETAILS_SUCCESS:
      return {
        ...state,
        loadingReferralDetails: false,
        referralDetails: action.payload,
        referralDetailsErrorMsg: '',
      };
    case REFERRAL_DETAILS_FAILURE:
      return {
        ...state,
        loadingReferralDetails: false,
        referralDetails: false,
        referralDetailsErrorMsg: action.payload,
      };

    case TOTAL_EARNING_REQUEST:
      return {
        ...state,
        loadingTotalEarnings: true,
        totalEarnings: '',
        totalEarningsErrorMsg: '',
      };
    case TOTAL_EARNING_SUCCESS:
      return {
        ...state,
        loadingTotalEarnings: false,
        totalEarnings: action.payload,
        totalEarningsErrorMsg: '',
      };
    case TOTAL_EARNING_FAILURE:
      return {
        ...state,
        loadingTotalEarnings: false,
        totalEarnings: '',
        totalEarningsErrorMsg: action.payload,
      };

    case BLOCK_REFERRAL_REQUEST:
      return {
        ...state,
        loadingBlockReferral: true,
        blockReferralSuccess: false,
        blockReferralErrorMsg: '',
      };
    case BLOCK_REFERRAL_SUCCESS:
      return {
        ...state,
        loadingBlockReferral: false,
        blockReferralSuccess: true,
        blockReferralErrorMsg: '',
      };
    case BLOCK_REFERRAL_FAILURE:
      return {
        ...state,
        loadingBlockReferral: false,
        blockReferralSuccess: false,
        blockReferralErrorMsg: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default referralReducer;
