import { Sprite } from 'components/atoms';

export const MENUCONFIG = [
  {
    key: '/',
    label: 'Overview',
    path: '/',
    icon: <Sprite id="dashboard" width={14} height={14} />,
    children: [],
    level: 1,
  },
  {
    key: '/assets',
    label: 'Asset Wise',
    path: '/assets',
    icon: <Sprite id="payments" width={16} height={16} />,
    children: [],
    level: 1,
  },
  {
    key: '/wallets',
    label: 'Wallet Wise',
    path: '/wallets',
    icon: <Sprite id="wallet-icon" width={16} height={16} />,
    children: [
      {
        key: '/wallets/search',
        label: 'Search',
        path: '/wallets/search',
        icon: null,
        children: [],
        level: 2,
      },
      {
        key: '/wallets/allwallets',
        label: 'All Wallets',
        path: '/wallets/allwallets',
        icon: null,
        children: [],
        level: 2,
      },
    ],
    level: 1,
  },
  {
    key: '/refill',
    label: 'Refill',
    path: '/refill',
    icon: <Sprite id="wallet-icon" width={16} height={16} />,
    children: [],
    level: 1,
  },
  {
    key: '/reports',
    label: 'Reports',
    path: '/reports',
    icon: <Sprite id="dashboard" width={16} height={16} />,
    children: [],
    level: 1,
  },
  {
    key: '/userbalance',
    label: 'User Balance CSVs',
    path: '/userbalance',
    icon: <Sprite id="dashboard" width={16} height={16} />,
    children: [],
    level: 1,
  }
  // {
  //   key: '/configs',
  //   label: 'Configurations',
  //   path: '/configs',
  //   icon: <Sprite id="config" width={14} height={14} />,
  //   children: [
  //     {
  //       key: '/configs/roles',
  //       label: 'Roles & Permissions',
  //       path: '/configs/roles',
  //       icon: null,
  //       children: [],
  //       level: 2,
  //     },
  //     {
  //       key: '/configs/limits',
  //       label: 'Limits',
  //       path: '/configs/limits',
  //       icon: null,
  //       children: [],
  //       level: 2,
  //     },
  //   ],
  //   level: 1,
  // },
];
