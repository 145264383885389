import {
  FETCH_ACTIVE_SIP_REQUEST,
  FETCH_ACTIVE_SIP_SUCCESS,
  FETCH_ACTIVE_SIP_FAILED,
  FETCH_CLOSED_SIP_REQUEST,
  FETCH_CLOSED_SIP_SUCCESS,
  FETCH_CLOSED_SIP_FAILED,
  FETCH_SIP_EXECUTION_HISTORY_REQUEST,
  FETCH_SIP_EXECUTION_HISTORY_SUCCESS,
  FETCH_SIP_EXECUTION_HISTORY_FAILED,
  GET_USER_BALANCE_REQUEST,
  GET_USER_BALANCE_SUCCESS,
  GET_USER_BALANCE_FAILED,
  UPDATE_SIP_REQUEST,
  UPDATE_SIP_SUCCESS,
  UPDATE_SIP_FAILED,
  CANCEL_SIP_REQUEST,
  CANCEL_SIP_SUCCESS,
  CANCEL_SIP_FAILED,
  FETCH_TRADE_LIMIT_FOR_USER_REQUEST,
  FETCH_TRADE_LIMIT_FOR_USER_SUCCESS,
  FETCH_TRADE_LIMIT_FOR_USER_FAILED,
  FETCH_TRADE_LIMIT_BY_COUNTRY_REQUEST,
  FETCH_TRADE_LIMIT_BY_COUNTRY_SUCCESS,
  FETCH_TRADE_LIMIT_BY_COUNTRY_FAILED,
  FETCH_Z1_WALLET_BALANCE_REQUEST,
  FETCH_Z1_WALLET_BALANCE_SUCCESS,
  FETCH_Z1_WALLET_BALANCE_FAILED,
} from './tradeActionTypes';

const initialState = {
  loadingActiveSIPs: false,
  activeSIPs: [],
  activeSIPsErrorMsg: '',
  loadingClosedSIPs: false,
  closedSIPs: [],
  closedSIPsErrorMsg: '',
  loadingSIPHistory: false,
  sipExecutionHistory: [],
  sipHistoryErrorMsg: '',
  userBalance: [],
  loadingUserBalance: false,
  userBalanceErrorMsg: '',
  loadingUpdateSIP: false,
  sipUpdated: false,
  updateSIPErrorMsg: '',
  loadingCancelSIP: false,
  sipCancelled: false,
  cancelSIPErrorMsg: '',
  loadingTradeLimitsForCountry: false,
  tradeLimitForCountry: [],
  tradeLimitForCountryErrorMsg: '',
  loadingTradeLimitsForUser: false,
  tradeLimitForUser: [],
  tradeLimitForUserErrorMsg: '',
  loadingZ1WalletBalance: false,
  Z1WalletBalance: {},
  Z1WalletBalanceErrMsg: '',
};

const tradeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_SIP_REQUEST:
      return {
        ...state,
        loadingActiveSIPs: true,
        activeSIPs: [],
        activeSIPsErrorMsg: '',
      };
    case FETCH_ACTIVE_SIP_SUCCESS:
      return {
        ...state,
        loadingActiveSIPs: false,
        activeSIPs: action.payload,
        activeSIPsErrorMsg: '',
      };
    case FETCH_ACTIVE_SIP_FAILED:
      return {
        ...state,
        loadingActiveSIPs: false,
        activeSIPs: [],
        activeSIPsErrorMsg: action.payload,
      };
    case FETCH_CLOSED_SIP_REQUEST:
      return {
        ...state,
        loadingClosedSIPs: true,
        closedSIPs: [],
        closedSIPsErrorMsg: '',
      };
    case FETCH_CLOSED_SIP_SUCCESS:
      return {
        ...state,
        loadingClosedSIPs: false,
        closedSIPs: action.payload,
        closedSIPsErrorMsg: '',
      };
    case FETCH_CLOSED_SIP_FAILED:
      return {
        ...state,
        loadingClosedSIPs: false,
        closedSIPs: [],
        closedSIPsErrorMsg: action.payload,
      };
    case FETCH_SIP_EXECUTION_HISTORY_REQUEST:
      return {
        ...state,
        loadingSIPHistory: true,
        sipExecutionHistory: [],
        sipHistoryErrorMsg: '',
      };
    case FETCH_SIP_EXECUTION_HISTORY_SUCCESS:
      return {
        ...state,
        loadingSIPHistory: false,
        sipExecutionHistory: action.payload,
        sipHistoryErrorMsg: '',
      };
    case FETCH_SIP_EXECUTION_HISTORY_FAILED:
      return {
        ...state,
        loadingSIPHistory: false,
        sipExecutionHistory: [],
        sipHistoryErrorMsg: action.payload,
      };
    case GET_USER_BALANCE_REQUEST:
      return {
        ...state,
        loadingUserBalance: true,
        userBalance: [],
        userBalanceErrorMsg: '',
      };
    case GET_USER_BALANCE_SUCCESS:
      return {
        ...state,
        loadingUserBalance: false,
        userBalance: action.payload,
        userBalanceErrorMsg: '',
      };
    case GET_USER_BALANCE_FAILED:
      return {
        ...state,
        loadingUserBalance: false,
        userBalance: [],
        userBalanceErrorMsg: action.payload,
      };
    case UPDATE_SIP_REQUEST:
      return {
        ...state,
        loadingUpdateSIP: true,
        sipUpdated: false,
        updateSIPErrorMsg: '',
      };
    case UPDATE_SIP_SUCCESS:
      return {
        ...state,
        loadingUpdateSIP: false,
        sipUpdated: true,
        updateSIPErrorMsg: '',
      };
    case UPDATE_SIP_FAILED:
      return {
        ...state,
        loadingUpdateSIP: false,
        sipUpdated: false,
        updateSIPErrorMsg: action.payload,
      };
    case CANCEL_SIP_REQUEST:
      return {
        ...state,
        loadingCancelSIP: true,
        sipCancelled: false,
        cancelSIPErrorMsg: '',
      };
    case CANCEL_SIP_SUCCESS:
      return {
        ...state,
        loadingCancelSIP: false,
        sipCancelled: true,
        cancelSIPErrorMsg: '',
      };
    case CANCEL_SIP_FAILED:
      return {
        ...state,
        loadingCancelSIP: false,
        sipCancelled: false,
        cancelSIPErrorMsg: action.payload,
      };
    case FETCH_TRADE_LIMIT_BY_COUNTRY_REQUEST:
      return {
        ...state,
        loadingTradeLimitsForCountry: true,
        tradeLimitForCountry: [],
        tradeLimitForCountryErrorMsg: '',
      };
    case FETCH_TRADE_LIMIT_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        loadingTradeLimitsForCountry: false,
        tradeLimitForCountry: action.payload,
        tradeLimitForCountryErrorMsg: '',
      };
    case FETCH_TRADE_LIMIT_BY_COUNTRY_FAILED:
      return {
        ...state,
        loadingTradeLimitsForCountry: false,
        tradeLimitForCountry: [],
        tradeLimitForCountryErrorMsg: action.payload,
      };
    case FETCH_TRADE_LIMIT_FOR_USER_REQUEST:
      return {
        ...state,
        loadingTradeLimitsForUser: true,
        tradeLimitForUser: [],
        tradeLimitForUserErrorMsg: '',
      };
    case FETCH_TRADE_LIMIT_FOR_USER_SUCCESS:
      return {
        ...state,
        loadingTradeLimitsForUser: false,
        tradeLimitForUser: action.payload,
        tradeLimitForUserErrorMsg: '',
      };
    case FETCH_TRADE_LIMIT_FOR_USER_FAILED:
      return {
        ...state,
        loadingTradeLimitsForUser: false,
        tradeLimitForUser: [],
        tradeLimitForUserErrorMsg: action.payload,
      };
    case FETCH_Z1_WALLET_BALANCE_REQUEST:
      return {
        ...state,
        loadingZ1WalletBalance: true,
        Z1WalletBalance: {},
        Z1WalletBalanceErrMsg: '',
      };
    case FETCH_Z1_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        loadingZ1WalletBalance: false,
        Z1WalletBalance: action.payload,
        Z1WalletBalanceErrMsg: '',
      };
    case FETCH_Z1_WALLET_BALANCE_FAILED:
      return {
        ...state,
        loadingZ1WalletBalance: false,
        Z1WalletBalance: {},
        Z1WalletBalanceErrMsg: action.payload,
      };
    default:
      return state;
  }
};

export default tradeReducer;
