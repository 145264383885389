import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import logo from '../../../assets/svgs/logo.svg';
import { Sprite } from 'components/atoms';

import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
import { MENUCONFIG } from 'constants/menu.constant';
import { useSelector, useDispatch } from 'react-redux';
import LocalStorageService, {
  localStorageGetItem,
  localStorageRemoveItem,
  localStorageSetItem,
} from 'services/LocalStorageService';
import { STORAGE_KEYS } from '../../../constants';
import { useGoogleAuth } from 'components/google-login/GoogleAuthProvider';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {
  const { signOut } = useGoogleAuth();
  // const { logout } = useAuth0();
  const [selectedItems, setSelectedItems] = useState(['/']);
  const { sidebarOptions } = useSelector((state) => state.screenOptionsReducer);

  // const [lastOpened, setLastOpened] = useState(['/']);
  const [openKeys, setOpenKeys] = useState(['/']);
  const location = useLocation();
  const { pathname } = location;

  function menuChanged(v) {
    setSelectedItems([v.key]);
    // setLastOpened([...openKeys])
    localStorageSetItem(STORAGE_KEYS.LAST_OPENED, [...openKeys]);
    if (sidebarOptions.isCollapsed) {
      setOpenKeys(['/']);
    }
  }
  useEffect(() => {
    if (pathname.includes('customers')) {
      setSelectedItems(['/customers']);
    } else {
      setSelectedItems([pathname]);
    }
    if (pathname.includes('payments')) {
      setOpenKeys(['/payments']);
    } else if (pathname.includes('trading')) {
      setOpenKeys(['/trading']);
    }
  }, []);
  useEffect(() => {
    if (sidebarOptions.isCollapsed) {
      setOpenKeys(['/']);
    } else {
      const lslo = localStorageGetItem(STORAGE_KEYS.LAST_OPENED);
      if (lslo) {
        setOpenKeys(lslo);
      }
    }
  }, [sidebarOptions.isCollapsed]);

  const submenuClick = (v) => {
    if (v.level > 1) {
      if (openKeys[v.level - 1]) {
        openKeys.pop(v.key);
      } else {
        openKeys.push(v.key);
      }
      // setOpenKeys(openKey.push(v.key));
    } else {
      if (openKeys[0] != v.key) {
        setOpenKeys([v.key]);
      } else {
        setOpenKeys(['/']);
      }
    }
  };

  const generateSubMenu = (item) => {
    return (
      <SubMenu
        key={item.key}
        title={item.label}
        icon={item.icon}
        className="ant-menu-submenu-open"
        onTitleClick={() => submenuClick(item)}
      >
        {item.children.map((i) => {
          if (i.children.length) {
            return generateSubMenu(i);
          } else {
            return (
              <Menu.Item key={i.key} icon={i.icon}>
                <Link to={i.path}>{i.label}</Link>
              </Menu.Item>
            );
          }
        })}
      </SubMenu>
    );
  };
  return (
    <Sider
      className=" zeb-sider"
      theme="light"
      trigger={null}
      collapsible
      width="220"
      collapsed={sidebarOptions.isCollapsed}
    >
      <div className="logo">
        <img src={logo} />
      </div>

      <Menu
        mode="inline"
        style={{
          margin: '30px 0 0 0',
          background: 'transparent',
          border: 'none',
        }}
        inlineIndent={12}
        selectedKeys={selectedItems}
        onSelect={menuChanged}
        openKeys={openKeys}
      >
        {Object.keys(MENUCONFIG).map((key) => {
          const item = MENUCONFIG[key];
          if (item.children.length) {
            return generateSubMenu(item);
          } else {
            return (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.path}>{item.label}</Link>
              </Menu.Item>
            );
          }
        })}
        <Menu.Item
          className="logout-menu"
          key="6"
          icon={<Sprite id="exit" width={14} height={14} />}
        >
          <a
            type="text"
            onClick={() => {
              localStorageRemoveItem(STORAGE_KEYS.AUTH);
              signOut();
            }}
          >
            Log out
          </a>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
