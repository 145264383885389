import React , { useState,useEffect }from 'react';
import PropTypes from 'prop-types';
import { Card, Avatar } from 'antd';
import {COINCONFIG} from "../../constants/coinconfig";
let token = localStorage.getItem('returnedToken')
const CardList = (props)=> {
  const { Meta } = Card; 
  const [allWallets, setAllWallets] = useState([]);
  useEffect(()=>{
    fetch("https://walletbackend.zebpay.co/wallet/db-balance",{
      headers: {
        Authorization: 'Bearer ' + token
    }
    })
        .then((res) => res.json())
        .then((result) => {
        setAllWallets(allWallets);
        // console.log(result.wallet)
        }, []);
},[props.className]);

    return (
        <>
            {props.data.map(val =>  {
                            const {id, coinType,currentBalance, balanceInUSD} = val;
                            const title  = COINCONFIG[coinType]? COINCONFIG[coinType].label:coinType;
                            // console.log(COINCONFIG[coinType]?.label)
                            const icon = COINCONFIG[coinType]? COINCONFIG[coinType].icon:<Avatar src='https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png' />;
                          return(  
                            <>
                            <Card style={{ width: 250, minWidth:250, marginTop: 16, marginRight:20 }}  
                            onClick={()=>props.onCardSelect(coinType)} // pass the index
                            // onClick={handleChange} // pass the index
                            className={props.currentId === coinType ? "active hor-cards" : "hor-cards"} key={val.coinType.trim()}>
                              <Meta avatar={icon} title={title}/>
                              <div className="asset-bal">
                              <p className="current-bal"> {new Intl.NumberFormat().format(currentBalance)} {coinType}</p>
                              {/* <p className="bal-usd">${Math.round((balanceInUSD + Number.EPSILON) * 100) / 1000 } */}
                              <p className="bal-usd">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(balanceInUSD)}</p>
                              </div>
                            </Card>
                            

                          </>
                          )
                })}
        </>
    );
};

export default CardList;