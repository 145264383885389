import { Sprite } from 'components/atoms';

export const COINCONFIG = {
    '1INCH':{
        label: '1inch Network',
        icon: <Sprite id={"1INCH"} type={'coin'} width={16} height={16} />,
      },
      ALICE:{
        label: 'ALICE',
        icon: <Sprite id={"ALICE"} type={'coin'} width={16} height={16} />,
      },
      ANKR:{
        label: 'ANKR',
        icon: <Sprite id={"ANKR"} type={'coin'} width={16} height={16} />,
      },
      CAKE:{
        label: 'CAKE',
        icon: <Sprite id={"CAKE"} type={'coin'} width={16} height={16} />,
      },
      BTT:{
        label: 'BTT',
        icon: <Sprite id={"BTT"} type={'coin'} width={16} height={16} />,
      },
      BTTC:{
        label: 'BTTC',
        icon: <Sprite id={"BTTC"} type={'coin'} width={16} height={16} />,
      },
      CHR:{
        label: 'CHR',
        icon: <Sprite id={"CHR"} type={'coin'} width={16} height={16} />,
      },
      ENJ:{
        label: 'ENJ',
        icon: <Sprite id={"ENJ"} type={'coin'} width={16} height={16} />,
      },
      ENS:{
        label: 'ENS',
        icon: <Sprite id={"ENS"} type={'coin'} width={16} height={16} />,
      },
      GARI:{
        label: 'GARI',
        icon: <Sprite id={"GARI"} type={'coin'} width={16} height={16} />,
      },
      KEEP:{
        label: 'KEEP',
        icon: <Sprite id={"KEEP"} type={'coin'} width={16} height={16} />,
      },
      LRC:{
        label: 'LRC',
        icon: <Sprite id={"LRC"} type={'coin'} width={16} height={16} />,
      },
      SAND:{
        label: 'SAND',
        icon: <Sprite id={"SAND"} type={'coin'} width={16} height={16} />,
      },
      SPELL:{
        label: 'SPELL',
        icon: <Sprite id={"SPELL"} type={'coin'} width={16} height={16} />,
      },
      WIN:{
        label: 'WIN',
        icon: <Sprite id={"WIN"} type={'coin'} width={16} height={16} />,
      },
      FARM:{
        label: 'FARM',
        icon: <Sprite id={"FARM"} type={'coin'} width={16} height={16} />,
      },
    AAVE:{
        label: 'AAVE',
        icon: <Sprite id={"AAVE".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      ACH:{
        label: 'Alchemy Pay ',
        icon: <Sprite id={"ach".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      ADA:{
        label: 'Cardano',
        icon: <Sprite id={"ADA".toUpperCase()} type={'coin'} width={16} height={16} />,
      },

       AE:{
        label: 'Aeternity',
        icon: <Sprite id={"ae".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      AGLD:{
        label: 'Adventure Gold ',
        icon: <Sprite id={"agld".toUpperCase()} type={'coin'} width={16} height={16} />,
      },

      AMP:{
        label: 'AMP',
        icon: <Sprite id={"AMP".toUpperCase()} type={'coin'} width={16} height={16} />,
      }, 
      BNB:{
        label: 'BNB',
        icon: <Sprite id={"BNB".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      BAT:{
        label: 'BAT',
        icon: <Sprite id={"BAT".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      BCH:{
        label: 'BCH',
        icon: <Sprite id={"BCH".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      AXS:{
        label: 'AXS',
        icon: <Sprite id={"AXS".toUpperCase()} type={'coin'} width={16} height={16} />,
      }, 
      BTC:{
        label: 'BTC',
        icon: <Sprite id={"BTC".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      BUSD:{
        label: 'BUSD',
        icon: <Sprite id={"BUSD".toUpperCase()} type={'coin'} width={16} height={16} />,
      }, 
      LINK:{
        label: 'LINK',
        icon: <Sprite id={"LINK".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      BTG:{
        label: 'BTG',
        icon: <Sprite id={"BTG".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      COMP:{
        label: 'COMP',
        icon: <Sprite id={"COMP".toUpperCase()} type={'coin'} width={16} height={16} />,
      }, 
      DAI:{
        label: 'DAI',
        icon: <Sprite id={"DAI".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      ETC:{
        label: 'ETC',
        icon: <Sprite id={"ETC".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      DATA:{
        label: 'DATA',
        icon: <Sprite id={"DATA".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      CMT:{
        label: 'CMT',
        icon: <Sprite id={"CMT".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      CHZ:{
        label: 'CHZ',
        icon: <Sprite id={"CHZ".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      ENA:{
        label: 'ENA',
        icon: <Sprite id={"ENA".toUpperCase()} type={'coin'} width={16} height={16} />,
      },

      CELR:{
        label: 'CELR',
        icon: <Sprite id={"CELR".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      DOGE:{
        label: 'DOGE',
        icon: <Sprite id={"DOGE".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      EOS:{
        label: 'EOS',
        icon: <Sprite id={"EOS".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      IOST:{
        label: 'IOST',
        icon: <Sprite id={"IOST".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      DOT:{
        label: 'DOT',
        icon: <Sprite id={"DOT".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      FTM:{
        label: 'FTM',
        icon: <Sprite id={"FTM".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      GRT:{
        label: 'GRT',
        icon: <Sprite id={"GRT".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      HBAR:{
        label: 'HBAR',
        icon: <Sprite id={"HBAR".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      GNT:{
        label: 'GNT',
        icon: <Sprite id={"GNT".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      ETH:{
        label: 'ETH',
        icon: <Sprite id={"ETH".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      INJ:{
        label: 'INJ',
        icon: <Sprite id={"INJ".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      CRV:{
        label: 'CRV',
        icon: <Sprite id={"CRV".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      LTC:{
        label: 'LTC',
        icon: <Sprite id={"LTC".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      IQ:{
        label: 'IQ',
        icon: <Sprite id={"IQ".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      MATIC:{
        label: 'MATIC',
        icon: <Sprite id={"MATIC".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      OMG:{
        label: 'OMG',
        icon: <Sprite id={"OMG".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      LUNA:{
        label: 'LUNA',
        icon: <Sprite id={"LUNA".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      OXT:{
        label: 'OXT',
        icon: <Sprite id={"OXT".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      MKR:{
        label: 'MKR',
        icon: <Sprite id={"MKR".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      MANA:{
        label: 'MANA',
        icon: <Sprite id={"MANA".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      NCASH:{
        label: 'NCASH',
        icon: <Sprite id={"NCASH".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      TRX:{
        label: 'TRX',
        icon: <Sprite id={"TRX".toUpperCase()} type={'coin'} width={16} height={16} />,
      },

      PAXG:{
        label: 'PAXG',
        icon: <Sprite id={"PAXG".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      SHIB:{
        label: 'SHIB',
        icon: <Sprite id={"SHIB".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      REP:{
        label: 'REP',
        icon: <Sprite id={"REP".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      SOL:{
        label: 'SOL',
        icon: <Sprite id={"SOL".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      SNX:{
        label: 'SNX',
        icon: <Sprite id={"SNX".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      SUSHI:{
        label: 'SUSHISWAP',
        icon: <Sprite id={"SUSHI".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      STORJ:{
        label: 'STORJ',
        icon: <Sprite id={"STORJ".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      PUSH:{
        label: 'PUSH',
        icon: <Sprite id={"PUSH".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      USDT:{
        label: 'USDT',
        icon: <Sprite id={"USDT".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      POLYGON:{
        label: 'POLYGON',
        icon: <Sprite id={"POLYGON".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      TUSD:{
        label: 'TUSD-20',
        icon: <Sprite id={"TUSD-20".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      SXP:{
        label: 'SXP',
        icon: <Sprite id={"SXP".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      XLM:{
        label: 'XLM',
        icon: <Sprite id={"XLM-23".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      TUSD:{
        label: 'TUSD-26',
        icon: <Sprite id={"TUSD-26".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      XRP:{
        label: 'XRP',
        icon: <Sprite id={"XRP".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      UNI:{
        label: 'UNI',
        icon: <Sprite id={"UNI".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      XLM:{
        label: 'XLM-37',
        icon: <Sprite id={"XLM-37".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      ZRX:{
        label: 'ZRX',
        icon: <Sprite id={"ZRX".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      WRX:{
        label: 'WRX',
        icon: <Sprite id={"WRX".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      VEN:{
        label: 'VEN',
        icon: <Sprite id={"VEN".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      ZIL:{
        label: 'ZIL',
        icon: <Sprite id={"ZIL".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      YFI:{
        label: 'YFI',
        icon: <Sprite id={"YFI".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      AVAX:{
        label: 'AVAX',
        icon: <Sprite id={"AVAX".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      ATOM:{
        label: 'ATOM',
        icon: <Sprite id={"ATOM".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      INCH:{
        label: '1INCH',
        icon: <Sprite id={"1INCH".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      KNC:{
        label: 'KNC',
        icon: <Sprite id={"KNC".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      YFI:{
        label: 'YFI',
        icon: <Sprite id={"YFI".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      YFI:{
        label: 'YFI',
        icon: <Sprite id={"YFI".toUpperCase()} type={'coin'} width={16} height={16} />,
      },
      Blue_coins:{
        label: 'Blue_coins-58',
        icon: <Sprite id={"Blue_coins-58".toUpperCase()} type={'coin'} width={16} height={16} />,
      }

};
