import React from 'react';
import { Sprite, Title } from 'components/atoms';

const PageHeaders = ({title,section,subSection,level3}) => {
  document.title =
    title ||
    section +
      (subSection ? ' - ' + subSection : '') +
      (level3 ? ' - ' + level3 : '') +
      ' - Zebpay Admin';
  return (
    <Title tag={'h2'} spacing="xs">
      {section || title}&nbsp;
      {subSection ? (
        <>
          <Sprite id="chevron-right" width={15} height={15} />
          &nbsp;
          <span style={{ fontWeight: 400 }}>{subSection}</span>&nbsp;
        </>
      ) : null}
      {level3 ? (
        <>
          <Sprite id="chevron-right" width={15} height={15} />
          &nbsp;
          <span style={{ fontWeight: 400 }}>{level3}</span>
        </>
      ) : null}
    </Title>
  );
};

export default PageHeaders;
