import { Tag } from 'antd';

export const STORAGE_KEYS = {
  ADMIN: 'ADMIN',
  CURRENCY_CONFIG: 'CURRENCY_CONFIG',
  PERMISSIONS: 'PERMISSIONS',
  USERID: 'userId',
  AUTH: 'auth',
  FIAT_TXN: 'transactionsFiat',
  LAST_OPENED: 'lastOpened',
  TRADE_TXN: 'transactionsTrade',
  DATE_FORMAT: 'dateFormat',
};

export const ROUTES = {
  DASHBOARD: '/dashboard',
  ADMIN: '/admins',
  HOME: '/home',
  CUSTOMERS: '/customer',
};

export const RANGEPICKERDATEFORMAT = 'YYYY/MM/DD';

export const PAYMENTTABS = [
  { value: 'all', label: 'ALL' },
  { value: 'initiated', label: 'INITIATED' },
  { value: 'pending', label: 'PENDING' },
  { value: 'manual', label: 'MANUAL' },
  { value: 'completed', label: 'COMPLETED' },
  { value: 'failed', label: 'FAILED' },
  { value: 'refund_initiated', label: 'REFUND_INITIATED' },
  { value: 'refunded_man', label: 'REFUNDED_MAN' },
  { value: 'refund_failed', label: 'REFUND_FAILED' },
  { value: 'completed_man', label: 'COMPLETED_MAN' },
  { value: 'cancelled', label: 'CANCELLED' },
];

export const DEPOSITTABS = [
  {
    value: 'all',
    label: 'ALL',
  },
  {
    value: 'bank',
    label: 'BANK',
  },
  /* {
    value: 'bank_feed', // send a partnerId of 5
    label: 'BANK_FEED',
  }, */
  {
    value: 'instant',
    label: 'INSTANT',
  },
  {
    value: 'net_banking',
    label: 'NET_BANKING',
  },
  {
    value: 'upi',
    label: 'UPI',
  },
];

export const VIEW = {
  DASHBOARD: 'dashboard',
  USER: 'user',
  GLOBALSEARCH: 'globalsearch',
  BUYSELLTABLE: 'buyselltable',
  BUYSELLEXCEL: 'buysellexcel',
  RECENT_TXNS: 'RECENT_TXNS',
  PAYMENTTABLE: 'paymenttable',
  PAYMENTEXCEL: 'paymentexcel',
  REJECTEDEXCEL: 'rejectedexcel',
};

export const PG_TYPE = {
  PAYU_TRANSFER: 'PayU Transfer',
  CASHFREE_UPI: 'Cashfree UPI',
};

export const SCOPES = {
  readKYC: 'readKYC',
  writeKYC: 'writeKYC',
  readBank: 'readBank',
  writeBank: 'writeBank',
  readSIP: 'readSIP',
  writeSIP: 'writeSIP',
  readDeposits: 'readDeposits',
  writeDeposits: 'writeDeposits',
  readWithdrawals: 'readWithdrawals',
  writeWithdrawals: 'writeWithdrawals',
  readTrades: 'readTrades',
  uploadBankFeed: 'uploadBankFeed',
  readUser: 'readUser',
  writeUser: 'writeUser',
  readAdmin: 'readAdmin',
  writeAdmin: 'writeAdmin',
  readTxnSetting: 'readSettings',
  writeTxnSetting: 'writeTxnSetting',
  readAccountSetting: 'readAccountSetting',
  writeAccountSetting: 'writeAccountSetting',
  readVirtualAccounts: 'readVirtualAccounts',
  readStatements: 'readStatements',
  writeStatements: 'writeStatements',
  readRoles: 'readRoles',
  writeRoles: 'writeRoles',
  createDepositOrder: 'createDepositOrder',
  readReferral: 'readReferral',
  writeReferral: 'writeReferral',
  PAYU_TRANSFER: 'PayU Transfer',
  CASHFREE_UPI: 'Cashfree UPI',
};

export const KYC_status = [
  { label: 'NEW', status: 'NEW', color: 'default' },
  { label: 'IN PROGRESS', status: 'IN_PROGRESS', color: 'default' },
  { label: 'INITIATED', status: 'INITIATED', color: 'default' },
  { label: 'PENDING', status: 'PENDING', color: 'yellow' },
  { label: 'MANUAL', status: 'MANUAL', color: 'blue' },
  { label: 'ACCEPTED', status: 'ACCEPTED', color: 'green' },
  { label: 'VERIFIED', status: 'VERIFIED', color: 'green' },
  { label: 'RECAPTURE', status: 'RECAPTURE', color: 'purple' },
  { label: 'REJECTED', status: 'REJECTED', color: 'red' },
];

export const KYC_STATUS_ENUM = {
  NEW: 0,
  INITIATED: 1,
  PENDING: 2,
  MANUAL: 3,
  ACCEPTED: 4,
  RECAPTURE: 5,
  REJECTED: 6,
};

export const MODULE_TYPE = {
  ADMIN_MODULE: 'ADMIN',
  USER_MODULE: 'USER',
  KYC_MODULE: 'KYC',
  BANK_MODULE: 'BANK',
  TRADING_MODULE: 'TRADING',
  PAYMENTS_MODULE: 'PAYMENTS',
  CRYPTOWALLET_MODULE: 'CRYPTOWALLET',
  SIP_MODULE: 'SIP',
};

export const KYC_REJECT_REASONS = {
  DOCUMENT_ALREADY_IN_USE: 'Document already in use',
  IMAGE_NOT_CLEAR: 'Image not - clear/complete',
  INVALID_DOCUMENT: 'Invalid Document',
  UNSUPPORTED_COUNTRY: 'Unsupported Country',
  DOB_NOT_MENTIONED: 'DOB not mentioned',
  EXPIRED_DOCUMENT: 'Expired Document',
  NATIONALITY_MISSING: 'Nationality not mentioned',
  MINOR_DOCUMENT: 'Minor document',
  EXPIRED_VISA: 'Expired Visa',
  ALREADY_IN_USE: 'Already in use as Address proof',
  NON_ENGLISH_DOCUMENT: 'Non English Document',
  PHOTO_MISMATCH: 'Photo mismatch',
  NAME_MISMATCH: 'Name mismatch',
  UPLOAD_ORIGINAL_DOCUMENT: 'Upload Original Document',
  OTHERS: 'Others',
};

export const UPDATE_KYC_REMARKS = {
  DOCUMENT_ALREADY_IN_USE:
    'Document is already in use with another Zebpay account.',
  IMAGE_NOT_CLEAR: 'The image is unclear / incomplete.',
  INVALID_DOCUMENT: 'It is not part of our acceptable documents list.',
  UNSUPPORTED_COUNTRY: 'Currently we are not operational in the country.',
  DOB_NOT_MENTIONED: 'Please upload an ID proof that has the DOB updated.',
  EXPIRED_DOCUMENT: 'The document has crossed its validity date.',
  NATIONALITY_MISSING: 'Please upload an ID with nationality mentioned.',
  MINOR_DOCUMENT: 'Age as per submitted document is less than 18 years.',
  EXPIRED_VISA: 'The visa does not have a validity of a minimum of 6 months.',
  ALREADY_IN_USE_AS_ADDRESS_PROOF:
    'We have considered this document as residence proof. Please upload another document.',
  NON_ENGLISH_DOCUMENT:
    'Kindly submit an English transalated document to complete the verification.',
  PHOTO_MISMATCH:
    'The photo on the ID proof does not match with the other documents.',
  NAME_MISMATCH:
    'The name mentioned on the ID proof does not match with that on the other documents submitted.',
  UPLOAD_ORIGINAL_DOCUMENT:
    'Please upload the original document as photo copies are not accepted.',
  OTHERS: 'Specify other reasons...',
};

export const SIP_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
};

export const PAYMENTSTATUSCOLUMN = {
  title: 'Status',
  dataIndex: 'status',
  key: 'status',
  width: 150,
  render: (text) => PAYMENTSTATUSTAGS(text),
};

export const PAYMENTBANKNAMECOLUMN = {
  title: 'Bank Name',
  key: 'bankName',
  render: (txn) =>
    txn &&
    txn.fiatTransactionBankReference &&
    txn.fiatTransactionBankReference['bankName'],
};

export const PAYMENTSTATUSTAGS = (text) => (
  <Tag
    color={PAYMENT_STATUSES[text.toLowerCase()]?.color}
    style={{ margin: '5px' }}
  >
    {text}
  </Tag>
);

export const PAYMENT_TYPES = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
};

export const PAYMENT_STATUSES = {
  initiated: {
    label: 'INITIATED',
    value: 'initiated',
    color: 'default',
  },
  pending: {
    label: 'PENDING',
    value: 'pending',
    color: 'yellow',
  },
  completed: {
    label: 'COMPLETED',
    value: 'completed',
    color: 'green',
  },
  failed: {
    label: 'FAILED',
    value: 'failed',
    color: 'red',
  },
  manual: {
    label: 'MANUAL',
    value: 'manual',
    color: 'blue',
  },
  refund_failed: {
    label: 'REFUND_FAILED',
    value: 'refund_failed',
    color: 'red',
  },
  refund_initiated: {
    label: 'REFUND_INITIATED',
    value: 'refund_initiated',
    color: 'default',
  },
  refunded_man: {
    label: 'REFUNDED_MAN',
    value: 'refunded_man',
    color: 'purple',
  },
  completed_man: {
    label: 'COMPLETED_MAN',
    value: 'completed_man',
    color: 'green',
  },
  cancelled: {
    label: 'CANCELLED',
    value: 'cancelled',
    color: 'red',
  },
};

export const UPI_VERIFICATION_TAGS = {
  initiated: {
    label: 'INITIATED',
    value: 'initiated',
    color: 'default',
  },
  manual: {
    label: 'MANUAL',
    value: 'manual',
    color: 'blue',
  },
  pending: {
    label: 'PENDING',
    value: 'pending',
    color: 'yellow',
  },
  verified: {
    label: 'VERIFIED',
    value: 'verified',
    color: 'green',
  },
  rejected: {
    label: 'REJECTED',
    value: 'rejected',
    color: 'red',
  },
};

export const DOC_VERIFICATION_TAGS = {
  'in-progress': {
    label: 'IN PROGRESS',
    value: 'in_progress',
    color: 'purple',
  },
  'manual': {
    label: 'MANUAL',
    value: 'manual',
    color: 'blue',
  },
  'pending': {
    label: 'PENDING',
    value: 'pending',
    color: 'yellow',
  },
  'verified': {
    label: 'VERIFIED',
    value: 'verified',
    color: 'green',
  },
  'rejected': {
    label: 'REJECTED',
    value: 'rejected',
    color: 'red',
  },
  'failed': {
    label: 'FAILED',
    value: 'failed',
    color: 'red',
  },
};

export const DEPOSIT_BUTTONS = {
  INITIATE_REFUND: 'Initiate Refund',
  MOVE_TO_MANUAL: 'Move to Manual',
  MARK_AS_REFUNDED: 'Mark as Refunded',
  DEPOSIT_TO_WALLET: 'Deposit To Wallet',
  // MANUAL_CREDIT: 'Manual Deposit',
  MANUAL_CREDIT: 'View Details',
  REFUND_FAILED: 'Refund Failed',
  VIEW_DETAILS: 'View Details',
  CANCEL_ORDER: 'Cancel Order',
};

export const WITHDRAWAL_BUTTONS = {
  CANCEL_ORDER: 'Cancel Order',
  MARK_AS_PAID: 'Mark as Paid',
};

export const PERMISSION_MODULES = {
  KYC: {
    label: 'KYC',
    read: 'read:kyc',
    write: 'write:kyc',
  },
  BANK: {
    label: 'BANK',
    read: 'read:bank',
    write: 'write:bank',
  },
  SIP: {
    label: 'SIP',
    read: 'read:sip',
    write: 'write:sip',
  },
  DEPOSITS: {
    label: 'DEPOSITS',
    read: 'read:deposits',
    write: 'write:deposits',
  },
  CREATE_DEPOSIT_ORDER: {
    label: 'CREATE_DEPOSIT_ORDER',
    read: '',
    write: 'create:depositorder',
  },
  UPDATE_BANK_REFERENCE: {
    label: 'UPDATE BANK REFERENCE',
    read: '',
    write: 'update:bankreference',
  },
  WITHDRAWALS: {
    label: 'WITHDRAWALS',
    read: 'read:withdrawals',
    write: 'write:withdrawals',
  },
  TRADING: {
    label: 'TRADING',
    read: 'read:trades',
    write: '',
  },
  BANK_FEED: {
    label: 'UPLOAD BANK FEED',
    read: '',
    write: 'upload:bankfeed',
  },
  CUSTOMER_PROFILE: {
    label: 'CUSTOMER PROFILE AMENDMENT',
    read: 'read:user',
    write: 'write:user',
  },
  BLOCK_UNBLOCK_TXN: {
    label: 'BLOCK/UNBLOCK TRANSACTIONS',
    read: 'read:txnsettings',
    write: 'write:txnsettings',
  },
  ENABLE_DISABLE_ACCOUNT: {
    label: 'ENABLE/DISABLE USER ACCOUNT',
    read: 'read:accountsetting',
    write: 'write:accountsetting',
  },
  STATEMENTS: {
    label: 'TRADE STATEMENTS',
    read: 'read:statements',
    write: 'write:statements',
  },
  VIRTUAL_ACCOUNTS: {
    label: 'VIRTUAL ACCOUNTS',
    read: 'read:virtualaccount',
    write: '',
  },
  ROLES_AND_PERMISSIONS: {
    label: 'ROLES AND PERMISSIONS',
    read: 'read:roles',
    write: 'write:roles',
  },
  ADMIN_PROFILE: {
    label: 'ADMIN PROFILE AMENDMENT',
    read: 'read:admin',
    write: 'write:admin',
  },
  REFERRALS: {
    label: 'REFERRALS',
    read: 'read:referral',
    write: 'write:referral',
  },
};

// using a views contant config to make toggleable screens
export const BANK_VIEWS = {
  ADDBANK: 'addbank',
  VIEWACTIVITY: 'viewactivity',
  NONE: 'none',
};

export const FILE_TYPES_BANK = ['image/png', 'application/pdf', 'image/jpeg'];

export const BANK_REJECT_REASONS = {
  NAME_MISMATCHED: {
    REJECT_REASON: 'Name Mismatch',
    REMARK:
      'The name on your submitted Id document and the name on the bank account provided do not match.',
  },
  INVALID_BANK_NAME: {
    REJECT_REASON: 'Invalid bank Name',
    REMARK:
      'We were unable to complete your bank account verification as the name of the bank updated is incorrect.',
  },
  INVALID_BANK_ACCOUNT: {
    REJECT_REASON: 'Invalid Bank Account Number',
    REMARK:
      'We were unable to complete your bank account verification as the account number updated is invalid.',
  },
  INVALID_IFSC: {
    REJECT_REASON: 'Invalid IFSC',
    REMARK:
      'We were unable to complete your bank account verification as the IFSC code entered into the app is either incorrect or is missing.',
  },
  MISSING_DETAILS: {
    REJECT_REASON: 'Missing Details',
    REMARK:
      'The proof you have submitted does not have either your name printed on it, the account number, IFSC code , bank name or all the above.',
  },
  UPLOAD_ORIGINAL_DOCUMENT: {
    REJECT_REASON: 'Upload Original Document',
    REMARK:
      'Please upload the original document as photocopies are not accepted.',
  },
  UNCLEAR_IMAGE: {
    REJECT_REASON: 'Unclear/ Incomplete Image',
    REMARK:
      'The image is unclear / incomplete. Please upload a clear and complete copy of the bank proof which shows the bank name, account number, account holder name and IFSC.',
  },
  INVALID_DOCUMENT: {
    REJECT_REASON: 'Invalid Document',
    REMARK:
      'It is not part of our acceptable list of documents for bank verification. Please upload a cancelled cheque or bank statement dated within the last 3 months which shows the bank name, account number,account holder name and IFSC/BSB/IBAN as applicable.',
  },
  OLD_BANK_STATEMENT: {
    REJECT_REASON: 'Bank Statement Older Than 3 months',
    REMARK: 'Please upload a bank statement dated within the last 3 months.',
  },
  KYC_INCOMPLETE: {
    REJECT_REASON: 'KYC Incomplete',
    REMARK:
      'Please upload your address proof and AML declaration on the app before we can review your bank documents.',
  },
  OTHER: { REJECT_REASON: 'Other', REMARK: 'Other reason...' },
};

export const AUTH0_SCOPES = [
  { key: 'read:sip', flag: 'readSIP' },
  { key: 'write:sip', flag: 'writeSIP' },
  { key: 'read:user', flag: 'readUser' },
  { key: 'write:user', flag: 'writeUser' },
  { key: 'read:virtualaccount', flag: 'readVirtualAccounts' },
  { key: 'read:kyc', flag: 'readKYC' },
  { key: 'write:kyc', flag: 'writeKYC' },
  { key: 'read:bank', flag: 'readBank' },
  { key: 'write:bank', flag: 'writeBank' },
  { key: 'read:admin', flag: 'readAdmin' },
  { key: 'write:admin', flag: 'writeAdmin' },
  { key: 'upload:bankfeed', flag: 'uploadBankFeed' },
  { key: 'read:statements', flag: 'readStatements' },
  { key: 'write:statements', flag: 'writeStatements' },
  { key: 'read:txnsettings', flag: 'readTxnSetting' },
  { key: 'write:txnsettings', flag: 'writeTxnSetting' },
  { key: 'read:accountsetting', flag: 'readAccountSetting' },
  { key: 'write:accountsetting', flag: 'writeAccountSetting' },
  { key: 'read:trades', flag: 'readTrades' },
  { key: 'read:withdrawals', flag: 'readWithdrawals' },
  { key: 'write:withdrawals', flag: 'writeWithdrawals' },
  { key: 'read:deposits', flag: 'readDeposits' },
  { key: 'write:deposits', flag: 'writeDeposits' },
  { key: 'read:roles', flag: 'readRoles' },
  { key: 'write:roles', flag: 'writeRoles' },
  { key: 'create:depositorder', flag: 'createDepositOrder' },
  { key: 'read:referral', flag: 'readReferral' },
  { key: 'write:referral', flag: 'writeReferral' },
  { key: 'update:bankreference', flag: 'updateBankReference' },
  { key: 'read:verification', flag: 'readVerification' },
  { key: 'write:verification', flag: 'writeVerification' },
];

export const UI_ACCESS_SCOPES = {
  readKYC: 'readKYC',
  writeKYC: 'writeKYC',
  readBank: 'readBank',
  writeBank: 'writeBank',
  readSIP: 'readSIP',
  writeSIP: 'writeSIP',
  readDeposits: 'readDeposits',
  writeDeposits: 'writeDeposits',
  readWithdrawals: 'readWithdrawals',
  writeWithdrawals: 'writeWithdrawals',
  readTrades: 'readTrades',
  uploadBankFeed: 'uploadBankFeed',
  readUser: 'readUser',
  writeUser: 'writeUser',
  readAdmin: 'readAdmin',
  writeAdmin: 'writeAdmin',
  readTxnSetting: 'readSettings',
  writeTxnSetting: 'writeTxnSetting',
  readAccountSetting: 'readAccountSetting',
  writeAccountSetting: 'writeAccountSetting',
  readVirtualAccounts: 'readVirtualAccounts',
  readStatements: 'readStatements',
  writeStatements: 'writeStatements',
  readRoles: 'readRoles',
  writeRoles: 'writeRoles',
  createDepositOrder: 'createDepositOrder',
  readReferral: 'readReferral',
  writeReferral: 'writeReferral',
  updateBankReference: 'updateBankReference',
  readVerification: 'readVerification',
  writeVerification: 'writeVerification',
};

export const FORBIDDEN_PERMISSIONS = {
  'read:sip': [],
  'write:sip': [],
  'read:user': [],
  'write:user': [],
  'read:virtualaccount': [],
  'read:kyc': [],
  'write:kyc': [],
  'read:bank': [],
  'write:bank': [],
  'read:admin': [],
  'write:admin': [],
  'upload:bankfeed': ['write:deposits'],
  'read:statements': [],
  'write:statements': [],
  'read:txnsettings': [],
  'write:txnsettings': [],
  'read:accountsetting': [],
  'write:accountsetting': [],
  'read:trades': [],
  'read:withdrawals': [],
  'write:withdrawals': [],
  'read:deposits': [],
  'write:deposits': ['create:depositorder', 'upload:bankfeed'],
  'read:roles': [],
  'write:roles': [],
  'create:depositorder': ['write:deposits'],
  'read:referral': [],
  'write:referral': [],
  'update:bankreference': ['create:depositorder'],
};

export const IMAGE_UPLOADS = {
  ADD_BANK: 'ADD_BANK',
  CREATE_BANK_DEPOSIT: 'CREATE_BANK_DEPOSIT',
  UPDATE_BANK_REFERENCE: 'UPDATE_BANK_REFERENCE',
};

export const BANK_ACC_STATUSES = {
  verified: {
    label: 'verified',
    value: 'Verify',
    color: 'green',
  },
  deleted: {
    label: 'deleted',
    value: 'Delete',
    color: 'red',
  },
  rejected: {
    label: 'rejected',
    value: 'Reject',
    color: 'red',
  },
  disabled: {
    label: 'disabled',
    value: 'Disable',
    color: 'red',
  },
};

export const REFERRAL_STATUSES = {
  PENDING: {
    label: 'PENDING',
    value: 'Pending',
    color: 'yellow',
    iconClass: 'cancel-filled',
  },
  COMPLETED: {
    label: 'COMPLETED',
    value: 'Completed',
    color: 'green',
    iconClass: 'ok-circle-filled',
  },
  REJECTED: {
    label: 'REJECTED',
    value: 'Rejected',
    color: 'red',
    iconClass: 'cancel-filled',
  },
  FAILED: {
    label: 'FAILED',
    value: 'Failed',
    color: 'red',
    iconClass: 'cancel-filled',
  },
};
