import { notification, message, Skeleton } from 'antd';
import Moment from 'moment';
import { AUTH0_SCOPES, UI_ACCESS_SCOPES, STORAGE_KEYS } from 'constants/index';
import {
  localStorageGetItem,
  localStorageSetItem,
} from 'services/LocalStorageService';
import { v4 as uuidv4 } from 'uuid';

export const jsonApiHeader = (
  accessToken,
  contentType = 'application/json'
) => {
  return {
    'Authorization': accessToken ? `Bearer ${accessToken}` : '',
    'Content-Type': contentType,
    // 'request-id': '1',
  };
};

export const capitalize = (s) => {
  if (s) {
    if (s.length === 0) return '';
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
};

export const formatDate = (...response) => {
  let formattedResponse = response[0]; // date value, can be array of dates or single date
  let defaultFormat = 'YYYY/MM/DD HH:MM:SS';
  let format = 'YYYY/MM/DD';
  if (localStorageGetItem(STORAGE_KEYS.DATE_FORMAT)) {
    format = localStorageGetItem(STORAGE_KEYS.DATE_FORMAT);
  }
  if (response[1]) {
    // this is for particular date type
    if (response[1] === 'epoch') {
      formattedResponse = Moment.unix(formattedResponse).format(format);
      return formattedResponse;
    }
  } else {
    if (Array.isArray(formattedResponse)) {
      formattedResponse.map((obj) => {
        for (let key in obj) {
          if (key === 'createdAt' || key === 'updatedAt') {
            const date = obj[key]
              ? Moment(obj[key], defaultFormat).format(format)
              : '';
            obj[key] = date;
          }
          if (key === 'lastLogin') {
            const date = obj[key]
              ? Moment(obj[key], defaultFormat).format(format)
              : '';
            obj[key] = date;
          }
        }
      });
      return formattedResponse;
    } else {
      formattedResponse = Moment(formattedResponse).format(format);
      return formattedResponse;
    }
  }
};

export const alert = (message, type = 'error') => {
  return notification[type]({
    message: capitalize(type),
    description: message,
    duration: 3,
  });
};

export const alert2 = (msg, type) => {
  return message[type](msg);
};

export const getDateFormat = (date, time = false) => {
  const inputDate = new Date(date);
  let month = String(inputDate?.getMonth() + 1);
  let day = String(inputDate?.getDate());
  const year = String(inputDate?.getFullYear());
  let hours = '';
  let minutes = '';
  let seconds = '';

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  if (time) {
    hours = String(inputDate?.getHours());
    minutes = String(inputDate?.getMinutes());
    seconds = String(inputDate?.getSeconds());
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;
    if (seconds.length < 2) seconds = '0' + seconds;
  }

  return `${year}/${month}/${day}${
    time ? ` ${hours}:${minutes}:${seconds}` : ``
  }`;
};

export const signUpDateFormat = (date, time = false) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (getDateFormat(date) === getDateFormat(today)) {
    return 'Today';
  } else if (getDateFormat(date) === getDateFormat(yesterday)) {
    return 'Yesterday';
  } else {
    return getDateFormat().split('/').reverse().join('/'); // will return ''dd/mm/yyyy' format
  }
};

export const getYesterdayTodayDate = (limit = 1) => {
  let endDate = new Date();
  let startDate = new Date(endDate);

  startDate.setDate(startDate.getDate() - limit);

  endDate = getDateFormat(endDate).trim();
  startDate = getDateFormat(startDate).trim();
  return [startDate, endDate];
};

// Value formatting based on decimal points

// const getFormattedValue = function(value,decimalPoint){
// 	console.log(value);
// 	console.log(decimalPoint);
// }
function padWithZeros(number, length) {
  var my_string = '' + number;
  while (my_string.length < length) {
    my_string = '0' + my_string;
  }

  return my_string;
}
export const getDecimalPoint = function (currency) {
  const config = localStorageGetItem(STORAGE_KEYS.CURRENCY_CONFIG);

  const obj = config.filter(
    (v) => v.currencyShortName.toLowerCase() == currency
  )[0];
  return obj.displayDecimalPlaces;
};
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};
export const getValueInLowerDenomination = (currency, value) => {
  const decimalPoint = getDecimalPoint(currency.toLowerCase());

  // let a = value.toString().split('');
  // // a.insert(-decimalPoint,'.');
  // if(decimalPoint > a.length){
  //   a = padWithZeros(value,decimalPoint).toString().split('');
  // }
  // a.insert(-decimalPoint,'.')
  // let i = (a.length - decimalPoint);
  // if(a[0]== '.'){
  //   a.insert(0,'0')
  //   // a.insert(0,'0')
  // }
  // const result = a.join("");

  return value / Math.pow(10, decimalPoint);
};

export const formatInrCurrency = (value) => {
  var val = Math.abs(value);
  if (val >= 10000000) {
    value = (value / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    value = (value / 100000).toFixed(2) + ' Lac';
  } else if (value >= 1000) value = (value / 1000).toFixed(2) + ' K';
  else value = val;
  return value;
};
export const debounce = (func, wait, immediate) => {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  alert2('copied', 'success');
};

export const disabledDate = (current) => {
  const disableBeforeDate = new Date(current._i).setMonth(
    new Date(current._i).getMonth() - 6
  );
  if (
    current &&
    current <
      Moment(
        new Date(disableBeforeDate)
          .toLocaleDateString()
          .split('/')
          .reverse()
          .join('/'),
        'YYYY/MM/DD'
      )
  ) {
    return true;
  } else if (current && current > Moment(new Date(), 'YYYY/MM/DD')) {
    return true;
  } else {
    return false;
  }
};

export function modifyName(val) {
  switch (val) {
    case 'INR':
      return 'Balance';
    case 'BTC':
      return 'Bitcoin';
    case 'ETH':
      return 'Ether';
    default:
      return '---';
  }
}

export function showScroll(elementId, v) {
  if (
    document.querySelector(`#${elementId} > div`).scrollWidth >
    document.querySelector(`#${elementId}`).clientWidth
  ) {
    if (v) {
      document.querySelector(`#${elementId}`).style.overflow = 'auto';
      document.querySelector(`#${elementId}`).style.paddingBottom = '12px';
    } else {
      document.querySelector(`#${elementId}`).style.overflow = 'hidden';
      document.querySelector(`#${elementId}`).style.paddingBottom = '20px';
    }
  }
}

export function setPermissions(authPermissions) {
  let permissions = {};
  for (const key in UI_ACCESS_SCOPES) {
    permissions = { ...permissions, [key]: false };
  }
  AUTH0_SCOPES.forEach(
    (scope) => (permissions[scope.flag] = authPermissions.includes(scope.key))
  );
  localStorageSetItem(STORAGE_KEYS.PERMISSIONS, permissions);
}

export function getSkeleton() {
  return (
    <>
      <div className="z2-card padded mb10">
        <Skeleton paragraph={{ rows: 1 }} title={false} />
      </div>
      <div className="z2-card padded  mb10">
        <Skeleton paragraph={{ rows: 1 }} title={false} />
      </div>
      <div className="z2-card padded  mb10">
        <Skeleton paragraph={{ rows: 1 }} title={false} />
      </div>
    </>
  );
}

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const getUUID = () => {
  return uuidv4();
};

export const getObjectKey = (obj, val) =>
  Object.keys(obj).find((key) => obj[key] === val);
