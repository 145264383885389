import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Select } from 'antd';
import {
    PageHeaders,
} from 'components/molecules';
import axios from 'axios';
import FileDownload from 'js-file-download'

const UserBalance = () => {

    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    
    async function getAllCsvFolders(){
        try {
            let response = await axios.get("https://walletbackend.zebpay.co/user-balance-csv/folders", {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
                }
            });

            response = response.data.data.folders
            let temp = []
            for(let folder of response){
                temp.push({
                    'name': folder
                })
            }
            setFolders(temp)
        } catch (err) {
            alert(err)
        }
    }

    async function getAllCsvFiles(folderName){
        try {
            if(localStorage.getItem(folderName)){
                setFiles(JSON.parse(JSON.parse(localStorage.getItem(folderName))))
            }
            else{
                let response = await axios.get("https://walletbackend.zebpay.co/user-balance-csv/files?folderName=" + folderName, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
                    }
                });
    
                response = response.data.data.files
                let temp = []
                for(let file of response){
                    temp.push({
                        'name': file.split(folderName)[1]
                    })
                }
    
                localStorage.setItem(folderName, JSON.stringify(JSON.stringify(temp)))
                setFiles(temp)
            }
        } catch (err) {
            console.log(err);
            alert(err)
        }
    }

    async function downloadCSV(fileName){
        try {    
            const res = await axios.get("https://walletbackend.zebpay.co/user-balance-csv/download?fileName=" + (selectedFolder) + (fileName), {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
                }
            });

            FileDownload(res.data, selectedFolder + fileName)

        } catch (err) {
            console.log(err);
            alert(err)
        }
    }

    useEffect(async () => {
        await getAllCsvFolders()
    }, []);

    async function onFolderChange(value){
        setSelectedFolder(value)
        setSelectedFile(null)
        setFiles([])
        await getAllCsvFiles(value)
    }

    function onFileChange(value){
        setSelectedFile(value)
    }
    
    return (
        <div>
            <Row gutter={[20]}>
                <Col span={24}>
                    <PageHeaders
                        title={'Welcome - Zebpay Admin'}
                        section={'User Balance'}
                        subSection={'Download CSVs'}
                    />
                </Col>
            </Row>

            <>

          {/* FOLDERS */}
          <Row gutter={[20, 20]} wrap={false}>
            <Col flex="auto">
              <Row gutter={[20, 20]}>
                <Col style={{minWidth:"50%", width:'100%', paddingLeft: '0px', paddingRight: '0px'}}>
                  <div className={'z2-card padded no-shadow'}>
                  <Select
                  showSearch
                  style={{ width: 250}}
                  placeholder="Select Folder"
                  optionFilterProp="children"
                  onChange={onFolderChange}
                  value={selectedFolder}
                  >
                    {folders.map((val,index) =>  {
                  return(
                      <Select.Option key={val.name} value={val.name}>{val.name}</Select.Option>
                   )})}
                </Select>
                  </div>
                </Col>
                <Col span={24}>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* FILES */}
            {
                (selectedFolder !== null) && (

                    <Row gutter={[20, 20]} wrap={false}>
                    <Col flex="auto">
                      <Row gutter={[20, 20]}>
                        <Col style={{minWidth:"50%", width:'100%', paddingLeft: '0px', paddingRight: '0px'}}>
                          <div className={'z2-card padded no-shadow'}>
                          <Select
                          showSearch
                          style={{ width: 250}}
                          placeholder="Select File"
                          optionFilterProp="children"
                          onChange={onFileChange}
                          value={selectedFile}
                          >
                            {files.map((val,index) =>  {
                          return(
                              <Select.Option key={val.name} value={val.name}>{val.name}</Select.Option>
                           )})}
                        </Select>
        
                            {(selectedFile !== null && selectedFolder !== null) && (<Button type="primary" onClick = {()=>downloadCSV(selectedFile)} style={{marginLeft:"20px"}}>DOWNLOAD CSV</Button>)}
                          </div>
                        </Col>
                        <Col span={24}>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

            )}
        </>
        </div>
    );
};

export default UserBalance;
