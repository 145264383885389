import React from 'react'
import { useEffect } from "react";
import { useGoogleLogin } from 'react-use-googlelogin'
import {ENV_CONFIG} from "../../config";
import { gapi } from "gapi-script"

export const GoogleAuthContext = React.createContext() // Not necessary, but recommended. global variabbl

const CLIENT_ID = ENV_CONFIG.CLIENT_ID;


// Provider of context 
export const GoogleAuthProvider = ({ children }) => {

  // used to bypass error need to change google auth method 
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_PUBLIC_GOOGLE_CLIENT_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  const googleAuth = useGoogleLogin({
    clientId: CLIENT_ID, // Your clientID from Google.
  })
  console.log(googleAuth);
  return (
    <GoogleAuthContext.Provider value={googleAuth}>
      {/* The rest of your app */}
      {children}
    </GoogleAuthContext.Provider>
  )
}

// provides custom hook to use GoogleAuthProvider's Context
export const useGoogleAuth = () => React.useContext(GoogleAuthContext)


