export const localStorageSetItem = (key, value) => {
  localStorage.setItem(key, typeof(value) === 'string' ? value: JSON.stringify(value));
};
export const localStorageGetItem = (key) => {
  const data = localStorage.getItem(key);
  if (data !== null) {
    if(data[0]==="{" ||data[0]==="["){
      return JSON.parse(data);
    }else{
      return data
    }
  }
  return null;
};
export const localStorageRemoveItem = (key) => {
  localStorage.removeItem(key);
};
