import { notification } from 'antd';
import axios from 'axios';
import { ENV_CONFIG } from 'config';
import { alert } from 'utils/Utility';
import {
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  FETCH_ACTIVITY_LOG_REQUEST,
  FETCH_ACTIVITY_LOG_SUCCESS,
  FETCH_ACTIVITY_LOG_FAILED,
  FETCH_ALL_ADMINS_REQUEST,
  FETCH_ALL_ADMINS_SUCCESS,
  FETCH_ALL_ADMINS_FAILED,
  ASSIGN_ROLES_REQUEST,
  ASSIGN_ROLES_SUCCESS,
  ASSIGN_ROLES_FAILED,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILED,
  FETCH_ALL_ADMINS_PER_ROLE_REQUEST,
  FETCH_ALL_ADMINS_PER_ROLE_SUCCESS,
  FETCH_ALL_ADMINS_PER_ROLE_FAILED,
  FETCH_PERMISSIONS_FOR_ROLE_FAILURE,
  FETCH_PERMISSIONS_FOR_ROLE_REQUEST,
  FETCH_PERMISSIONS_FOR_ROLE_SUCCESS,
  ASSIGN_PERMISSIONS_TO_ROLE_REQUEST,
  ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS,
  ASSIGN_PERMISSIONS_TO_ROLE_FAILURE,
  REMOVE_PERMISSIONS_FROM_ROLE_REQUEST,
  REMOVE_PERMISSIONS_FROM_ROLE_SUCCESS,
  REMOVE_PERMISSIONS_FROM_ROLE_FAILURE,
  FETCH_ALL_PERMISSIONS_REQUEST,
  FETCH_ALL_PERMISSIONS_SUCCESS,
  FETCH_ALL_PERMISSIONS_FAILURE,
  FETCH_DIRECT_PERMISSIONS_FOR_USER_REQUEST,
  FETCH_DIRECT_PERMISSIONS_FOR_USER_SUCCESS,
  FETCH_DIRECT_PERMISSIONS_FOR_USER_FAILURE,
  ASSIGN_DIRECT_PERMISSIONS_TO_USER_REQUEST,
  ASSIGN_DIRECT_PERMISSIONS_TO_USER_SUCCESS,
  ASSIGN_DIRECT_PERMISSIONS_TO_USER_FAILURE,
  REMOVE_DIRECT_PERMISSIONS_FOR_USER_REQUEST,
  REMOVE_DIRECT_PERMISSIONS_FOR_USER_SUCCESS,
  REMOVE_DIRECT_PERMISSIONS_FOR_USER_FAILURE,
  FETCH_ALL_USER_WITH_ROLE_REQUEST,
  FETCH_ALL_USER_WITH_ROLE_SUCCESS,
  FETCH_ALL_USER_WITH_ROLE_FAILURE,
  FETCH_ROLES_FOR_USER_REQUEST,
  FETCH_ROLES_FOR_USER_SUCCESS,
  FETCH_ROLES_FOR_USER_FAILURE,
  REMOVE_ROLE_FROM_USER_REQUEST,
  REMOVE_ROLE_FROM_USER_SUCCESS,
  REMOVE_ROLE_FROM_USER_FAILURE,
  CREATE_NEW_ROLE_REQUEST,
  CREATE_NEW_ROLE_SUCCESS,
  CREATE_NEW_ROLE_FAILURE,
  CREATE_NEW_ROLE_RESET,
  ASSIGN_USERS_TO_ROLE_REQUEST,
  ASSIGN_USERS_TO_ROLE_SUCCESS,
  ASSIGN_USERS_TO_ROLE_FAILURE,
  RESET_ASSIGN_ROLES_STATE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_RESET,
  GET_ADMIN_PROFILE_REQUEST,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_ADMIN_PROFILE_FAILURE,
  UPDATE_APP_CONFIG_REQUEST,
  UPDATE_APP_CONFIG_SUCCESS,
  UPDATE_APP_CONFIG_FAILURE,
  FETCH_APP_CONFIG_REQUEST,
  FETCH_APP_CONFIG_SUCCESS,
  FETCH_APP_CONFIG_FAILURE,
} from './adminActionTypes';
const BASE_URL = ENV_CONFIG.BASE_URL;
export const addUserRequest = () => {
  return { type: ADD_USER_REQUEST };
};

export const addUserSuccess = (data) => {
  return {
    type: ADD_USER_SUCCESS,
    payload: data,
  };
};

export const addUserFailed = (error) => {
  return {
    type: ADD_USER_FAILED,
    payload: error,
  };
};

export const fetchActivityLogRequest = () => {
  return {
    type: FETCH_ACTIVITY_LOG_REQUEST,
  };
};

export const fetchActivityLogSuccess = (data) => {
  return {
    type: FETCH_ACTIVITY_LOG_SUCCESS,
    payload: data,
  };
};

export const fetchActivityLogFailure = (error) => {
  return {
    type: FETCH_ACTIVITY_LOG_FAILED,
    payload: error,
  };
};

export const fetchAllAdminsRequest = () => {
  return {
    type: FETCH_ALL_ADMINS_REQUEST,
  };
};
export const fetchAllAdminsSuccess = (data) => {
  return {
    type: FETCH_ALL_ADMINS_SUCCESS,
    payload: data,
  };
};
export const fetchAllAdminsFailed = (error) => {
  return {
    type: FETCH_ALL_ADMINS_FAILED,
    payload: error,
  };
};

export const assignRolesRequest = () => {
  return {
    type: ASSIGN_ROLES_REQUEST,
  };
};
export const assignRolesSuccess = (data) => {
  return {
    type: ASSIGN_ROLES_SUCCESS,
    payload: data,
  };
};
export const assignRolesFailed = (error) => {
  return {
    type: ASSIGN_ROLES_FAILED,
    payload: error,
  };
};
export const resetAssignRoleSuccessState = () => {
  return {
    type: RESET_ASSIGN_ROLES_STATE,
  };
};
export const fetchRolesRequest = () => {
  return {
    type: FETCH_ROLES_REQUEST,
  };
};
export const fetchRolesSuccess = (data) => {
  return {
    type: FETCH_ROLES_SUCCESS,
    payload: data,
  };
};
export const fetchRolesFailed = (error) => {
  return {
    type: FETCH_ROLES_FAILED,
    payload: error,
  };
};
export const fetchAllAdminPerRoleRequest = () => {
  return {
    type: FETCH_ALL_ADMINS_PER_ROLE_REQUEST,
  };
};
export const fetchAllAdminPerRoleSuccess = (data) => {
  return {
    type: FETCH_ALL_ADMINS_PER_ROLE_SUCCESS,
    payload: data,
  };
};
export const fetchAllAdminPerRoleFailed = (error) => {
  return {
    type: FETCH_ALL_ADMINS_PER_ROLE_FAILED,
    payload: error,
  };
};
export const fetchPermissionsForRoleRequest = () => {
  return {
    type: FETCH_PERMISSIONS_FOR_ROLE_REQUEST,
  };
};
export const fetchPermissionsForRoleSuccess = (data) => {
  return {
    type: FETCH_PERMISSIONS_FOR_ROLE_SUCCESS,
    payload: data,
  };
};
export const fetchPermissionsForRoleFailure = (error) => {
  return {
    type: FETCH_PERMISSIONS_FOR_ROLE_FAILURE,
    payload: error,
  };
};
export const assignPermissionsToRoleRequest = () => {
  return {
    type: ASSIGN_PERMISSIONS_TO_ROLE_REQUEST,
  };
};
export const assignPermissionsToRoleSuccess = (data) => {
  return {
    type: ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS,
    payload: data,
  };
};
export const assignPermissionsToRoleFailure = (error) => {
  return {
    type: ASSIGN_PERMISSIONS_TO_ROLE_FAILURE,
    payload: error,
  };
};
export const removePermissionsFromRoleRequest = () => {
  return {
    type: REMOVE_PERMISSIONS_FROM_ROLE_REQUEST,
  };
};
export const removePermissionsFromRoleSuccess = (data) => {
  return {
    type: REMOVE_PERMISSIONS_FROM_ROLE_SUCCESS,
    payload: data,
  };
};
export const removePermissionsFromRoleFailure = (error) => {
  return {
    type: REMOVE_PERMISSIONS_FROM_ROLE_FAILURE,
    payload: error,
  };
};
export const fetchAllPermissionsRequest = () => {
  return {
    type: FETCH_ALL_PERMISSIONS_REQUEST,
  };
};
export const fetchAllPermissionsSuccess = (data) => {
  return {
    type: FETCH_ALL_PERMISSIONS_SUCCESS,
    payload: data,
  };
};
export const fetchAllPermissionsFailed = (error) => {
  return {
    type: FETCH_ALL_PERMISSIONS_FAILURE,
    payload: error,
  };
};
export const fetchDirectPermissionsForUserRequest = () => {
  return {
    type: FETCH_DIRECT_PERMISSIONS_FOR_USER_REQUEST,
  };
};
export const fetchDirectPermissionsForUserSuccess = (data) => {
  return {
    type: FETCH_DIRECT_PERMISSIONS_FOR_USER_SUCCESS,
    payload: data,
  };
};
export const fetchDirectPermissionsForUserFailed = (error) => {
  return {
    type: FETCH_DIRECT_PERMISSIONS_FOR_USER_FAILURE,
    payload: error,
  };
};
export const assingDirectPermissionsToUserRequest = () => {
  return {
    type: ASSIGN_DIRECT_PERMISSIONS_TO_USER_REQUEST,
  };
};
export const assingDirectPermissionsToUserSuccess = (data) => {
  return {
    type: ASSIGN_DIRECT_PERMISSIONS_TO_USER_SUCCESS,
    payload: data,
  };
};
export const assingDirectPermissionsToUserFailed = (error) => {
  return {
    type: ASSIGN_DIRECT_PERMISSIONS_TO_USER_FAILURE,
    payload: error,
  };
};
export const removeDirectPermissionsForUserRequest = () => {
  return {
    type: REMOVE_DIRECT_PERMISSIONS_FOR_USER_REQUEST,
  };
};
export const removeDirectPermissionsForUserSuccess = (data) => {
  return {
    type: REMOVE_DIRECT_PERMISSIONS_FOR_USER_SUCCESS,
    payload: data,
  };
};
export const removeDirectPermissionsForUserFailed = (error) => {
  return {
    type: REMOVE_DIRECT_PERMISSIONS_FOR_USER_FAILURE,
    payload: error,
  };
};
export const fetchAllUsersWithRoleRequest = () => {
  return {
    type: FETCH_ALL_USER_WITH_ROLE_REQUEST,
  };
};
export const fetchAllUsersWithRoleSuccess = (data) => {
  return {
    type: FETCH_ALL_USER_WITH_ROLE_SUCCESS,
    payload: data,
  };
};
export const fetchAllUsersWithRoleFailed = (error) => {
  return {
    type: FETCH_ALL_USER_WITH_ROLE_FAILURE,
    payload: error,
  };
};

export const fetchRoleForUserRequest = () => {
  return {
    type: FETCH_ROLES_FOR_USER_REQUEST,
  };
};
export const fetchRoleForUserSuccess = (data) => {
  return {
    type: FETCH_ROLES_FOR_USER_SUCCESS,
    payload: data,
  };
};
export const fetchRoleForUserFailed = (error) => {
  return {
    type: FETCH_ROLES_FOR_USER_FAILURE,
    payload: error,
  };
};

export const removeRoleFromUserRequest = () => {
  return {
    type: REMOVE_ROLE_FROM_USER_REQUEST,
  };
};
export const removeRoleFromUserSuccess = (data) => {
  return {
    type: REMOVE_ROLE_FROM_USER_SUCCESS,
    payload: data,
  };
};
export const removeRoleFromUserFailure = (error) => {
  return {
    type: REMOVE_ROLE_FROM_USER_FAILURE,
    payload: error,
  };
};

export const createNewRoleRequest = () => {
  return {
    type: CREATE_NEW_ROLE_REQUEST,
  };
};
export const createNewRoleSuccess = (data) => {
  return {
    type: CREATE_NEW_ROLE_SUCCESS,
    payload: data,
  };
};
export const createNewRoleFailure = (error) => {
  return {
    type: CREATE_NEW_ROLE_FAILURE,
    payload: error,
  };
};
export const createNewRoleReset = () => {
  return {
    type: CREATE_NEW_ROLE_RESET,
  };
};

export const assignUsersToRoleRequest = () => {
  return {
    type: ASSIGN_USERS_TO_ROLE_REQUEST,
  };
};
export const assignUsersToRoleSuccess = (data) => {
  return {
    type: ASSIGN_USERS_TO_ROLE_SUCCESS,
    payload: data,
  };
};
export const assignUsersToRoleFailed = (error) => {
  return {
    type: ASSIGN_USERS_TO_ROLE_FAILURE,
    payload: error,
  };
};
export const deleteRoleRequest = () => {
  return {
    type: DELETE_ROLE_REQUEST,
  };
};
export const deleteRoleSuccess = (data) => {
  return {
    type: DELETE_ROLE_SUCCESS,
    payload: data,
  };
};
export const deleteRoleFailed = (error) => {
  return {
    type: DELETE_ROLE_FAILURE,
    payload: error,
  };
};
export const deleteRoleReset = () => {
  return {
    type: DELETE_ROLE_RESET,
  };
};

export const getAdminProfileSuccess = (data) => {
  return {
    type: GET_ADMIN_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getAdminProfileFailed = (error) => {
  return {
    type: GET_ADMIN_PROFILE_FAILURE,
    payload: error,
  };
};
export const getAdminProfileRequest = () => {
  return {
    type: GET_ADMIN_PROFILE_REQUEST,
  };
};

export const updateAppConfigSuccess = (data) => {
  return {
    type: UPDATE_APP_CONFIG_SUCCESS,
    payload: data,
  };
};
export const updateAppConfigFailed = (error) => {
  return {
    type: UPDATE_APP_CONFIG_FAILURE,
    payload: error,
  };
};
export const updateAppConfigRequest = () => {
  return {
    type: UPDATE_APP_CONFIG_REQUEST,
  };
};
export const fetchAppConfigSuccess = (data) => {
  return {
    type: FETCH_APP_CONFIG_SUCCESS,
    payload: data,
  };
};
export const fetchAppConfigFailed = (error) => {
  return {
    type: FETCH_APP_CONFIG_FAILURE,
    payload: error,
  };
};
export const fetchAppConfigRequest = () => {
  return {
    type: FETCH_APP_CONFIG_REQUEST,
  };
};
export const addUser = (headers, body) => (dispatch, getState) => {
  dispatch(addUserRequest());
  axios
    .post(`${BASE_URL}/admin/add`, body, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(addUserFailed(data.message));
        return;
      }
      if (
        data &&
        data.error === '0' &&
        data.data &&
        (data.data.length || Object.keys(data.data).length)
      )
        dispatch(addUserSuccess(data.data));
      else {
        let message = 'Unable to add admin!';
        dispatch(addUserFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(addUserFailed(errMsg));
    });
};

export const fetchActivityLogs = (headers, params) => (dispatch, getState) => {
  dispatch(fetchActivityLogRequest());
  axios
    .post(`${BASE_URL}/user/getactivitylog`, params, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(fetchActivityLogFailure(data.message));
        return;
      }
      if (data && data.error === '0' && data.data)
        dispatch(fetchActivityLogSuccess(data.data));
      else {
        let message = 'No activity logs found!';
        dispatch(fetchActivityLogFailure(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(fetchActivityLogFailure(errMsg));
    });
};

export const fetchAllAdmins = (headers) => (dispatch, getState) => {
  dispatch(fetchAllAdminsRequest());
  axios
    .get(`${BASE_URL}/admin/getalladmins`, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(fetchAllAdminsFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data)
        dispatch(fetchAllAdminsSuccess(data.data));
      else {
        let message = 'No admins found!';
        alert(message, 'error');
        dispatch(fetchAllAdminsFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(fetchAllAdminsFailed(errMsg));
    });
};

export const fetchListOfRoles = (headers) => (dispatch, getState) => {
  dispatch(fetchRolesRequest());
  axios
    .get(`${BASE_URL}/admin/fetchlistofroles`, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(fetchRolesFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data)
        dispatch(fetchRolesSuccess(data.data));
      else {
        let message = 'No roles found!';
        alert(message, 'error');
        dispatch(fetchRolesFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(fetchRolesFailed(errMsg));
    });
};

export const assignRoles = (headers, body) => (dispatch, getState) => {
  dispatch(assignRolesRequest());
  axios
    .post(`${BASE_URL}/admin/assignroles`, body, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(assignRolesFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data) {
        notification.destroy();
        alert('Roles for the admin updated successfully. ', 'success');
        dispatch(assignRolesSuccess(data.data));
      } else {
        let message = 'Unable to assign role!';
        alert(message, 'error');
        dispatch(assignRolesFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      alert('Failed to update the roles. ', 'error');
      dispatch(assignRolesFailed(errMsg));
    });
};

export const fetchAllAdminPerRole = (headers) => (dispatch, getState) => {
  dispatch(fetchAllAdminPerRoleRequest());
  axios
    .get(`${BASE_URL}/admin/fetchUsersWithRoleFromDB`, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(fetchAllAdminPerRoleFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data)
        dispatch(fetchAllAdminPerRoleSuccess(data.data));
      else {
        let message = 'No admins found!';
        alert(message, 'error');
        dispatch(fetchAllAdminPerRoleFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(fetchAllAdminPerRoleFailed(errMsg));
    });
};

export const fetchPermissionsForRole =
  (headers, params) => (dispatch, getState) => {
    dispatch(fetchPermissionsForRoleRequest());
    axios
      .get(`${BASE_URL}/admin/fetchPermissionsForRole`, { headers, params })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          dispatch(fetchPermissionsForRoleFailure(data.message));
          return;
        }
        if (data && data.error === '0' && data.data)
          dispatch(fetchPermissionsForRoleSuccess(data.data));
        else {
          let message = 'Error in fetching!';
          alert(message, 'error');
          dispatch(fetchPermissionsForRoleFailure(message));
        }
      })
      .catch((e) => {
        const errMsg = e.message;
        dispatch(fetchPermissionsForRoleFailure(errMsg));
      });
  };

export const assignPermissionsToRole =
  (headers, body) => (dispatch, getState) => {
    dispatch(assignPermissionsToRoleRequest());
    axios
      .post(`${BASE_URL}/admin/assignPermissionsToRole`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(assignPermissionsToRoleFailure(data.message));
          return;
        }
        if (data && data.error === '0' && data.data) {
          alert('Assigned permissions to the role successfully. ', 'success');
          dispatch(assignPermissionsToRoleSuccess(data.data));
        } else {
          let message = 'Error in assigning!';
          alert(message, 'error');
          dispatch(assignPermissionsToRoleFailure(message));
        }
      })
      .catch((e) => {
        const errMsg = e.message;
        dispatch(assignPermissionsToRoleFailure(errMsg));
      });
  };

export const removePermissionsFromRole =
  (headers, body) => (dispatch, getState) => {
    dispatch(removePermissionsFromRoleRequest());
    axios
      .post(`${BASE_URL}/admin/removePermissionsFromRole`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(removePermissionsFromRoleFailure(data.message));
          return;
        }
        if (data && data.error === '0' && data.data) {
          alert('Removed permissions from the role successfully. ', 'success');
          dispatch(removePermissionsFromRoleSuccess(data.data));
        } else {
          let message = 'Error in removing!';
          alert(message, 'error');
          dispatch(removePermissionsFromRoleFailure(message));
        }
      })
      .catch((e) => {
        const errMsg = e.message;
        dispatch(removePermissionsFromRoleFailure(errMsg));
      });
  };
export const fetchAllPermissions = (headers) => (dispatch, getState) => {
  dispatch(fetchAllPermissionsRequest());
  axios
    .get(`${BASE_URL}/admin/fetchAllPermissions`, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(fetchAllPermissionsFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data && data.data.scopes)
        dispatch(fetchAllPermissionsSuccess(data.data.scopes));
      else {
        let message = 'Error in fetching!';
        alert(message, 'error');
        dispatch(fetchAllPermissionsFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(fetchAllPermissionsFailed(errMsg));
    });
};
export const fetchDirectPermissionsForUser =
  (headers, params) => (dispatch, getState) => {
    dispatch(fetchDirectPermissionsForUserRequest());
    axios
      .get(`${BASE_URL}/admin/fetchdirectpermissionsforuser`, {
        headers,
        params,
      })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(fetchDirectPermissionsForUserFailed(data.message));
          return;
        }
        if (data && data.error === '0' && data.data && data.data)
          dispatch(fetchDirectPermissionsForUserSuccess(data.data));
        else {
          let message = 'Error in fetching!';
          alert(message, 'error');
          dispatch(fetchDirectPermissionsForUserFailed(message));
        }
      })
      .catch((e) => {
        const errMsg = e.message;
        dispatch(fetchDirectPermissionsForUserFailed(errMsg));
      });
  };
export const assignDirectPermissionsToUser =
  (headers, body) => (dispatch, getState) => {
    dispatch(assingDirectPermissionsToUserRequest());
    axios
      .post(`${BASE_URL}/admin/assigndirectpermissions`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(assingDirectPermissionsToUserFailed(data.message));
          return;
        }
        if (data && data.error === '0' && data.data) {
          alert('Assigned permissions to the user successfully. ', 'success');
          dispatch(assingDirectPermissionsToUserSuccess(data.data));
        } else {
          let message = 'Error in assigning!';
          alert(message, 'error');
          dispatch(assingDirectPermissionsToUserFailed(message));
          dispatch(fetchRoleForUserFailed(message));
        }
      })
      .catch((e) => {
        const errMsg = e.message;
        dispatch(assingDirectPermissionsToUserFailed(errMsg));
      });
  };
export const removeDirectPermissionsForUser =
  (headers, body) => (dispatch, getState) => {
    dispatch(removeDirectPermissionsForUserRequest());
    axios
      .post(`${BASE_URL}/admin/removedirectpermissions`, body, { headers })
      .then(({ data }) => {
        if (data && data.error !== '0' && data.message) {
          alert(data.message, 'error');
          dispatch(removeDirectPermissionsForUserFailed(data.message));
          return;
        }
        if (data && data.error === '0' && data.data) {
          alert('Removed permissions from the user successfully. ', 'success');
          dispatch(removeDirectPermissionsForUserSuccess(data.data));
        } else {
          let message = 'Error in removing!';
          alert(message, 'error');
          dispatch(removeDirectPermissionsForUserFailed(message));
        }
      })
      .catch((e) => {
        const errMsg = e.message;
        dispatch(removeDirectPermissionsForUserFailed(errMsg));
      });
  };
export const fetchAllUsersWithRole = (headers) => (dispatch, getState) => {
  dispatch(fetchAllUsersWithRoleRequest());
  axios
    .get(`${BASE_URL}/admin/fetchalluserswithrole`, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(fetchAllUsersWithRoleFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data && data.data.scopes)
        dispatch(fetchAllUsersWithRoleSuccess(data.data.scopes));
      else {
        let message = 'Error in fetching!';
        alert(message, 'error');
        dispatch(fetchAllUsersWithRoleFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(fetchAllUsersWithRoleFailed(errMsg));
    });
};
export const fetchRoleForUser = (headers) => (dispatch, getState) => {
  dispatch(fetchRoleForUserRequest());
  axios
    .get(`${BASE_URL}/admin/fetchroleforuser`, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(fetchRoleForUserFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data && data.data.scopes)
        dispatch(fetchRoleForUserSuccess(data.data.scopes));
      else {
        let message = 'Error in fetching!';
        alert(message, 'error');
        dispatch(fetchRoleForUserFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(fetchRoleForUserFailed(errMsg));
    });
};
export const removeRoleFromUser = (headers, body) => (dispatch, getState) => {
  dispatch(removeRoleFromUserRequest());
  axios
    .post(`${BASE_URL}/admin/removerolefromuser`, body, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(removeRoleFromUserFailure(data.message));
        return;
      }
      if (data && data.error === '0' && data.data) {
        alert('Removed role from the user successfully. ', 'success');
        dispatch(removeRoleFromUserSuccess(data.data));
      } else {
        let message = 'Error in removing!';
        alert(message, 'error');
        dispatch(removeRoleFromUserFailure(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      alert(errMsg, 'error');
      dispatch(removeRoleFromUserFailure(errMsg));
    });
};
export const createNewRole = (headers, body) => (dispatch, getState) => {
  dispatch(createNewRoleRequest());
  axios
    .post(`${BASE_URL}/admin/createnewrole`, body, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(createNewRoleFailure(data.message));
        return;
      }
      if (data && data.error === '0' && data.data) {
        // alert('Created new role successfully.', 'success');
        dispatch(createNewRoleSuccess(data.data));
      } else {
        let message = 'Error in creating!';
        alert(message, 'error');
        dispatch(createNewRoleFailure(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(createNewRoleFailure(errMsg));
    });
};
export const assignUsersToRole = (headers, body) => (dispatch, getState) => {
  dispatch(assignUsersToRoleRequest());
  axios
    .post(`${BASE_URL}/admin/assignUsersToARole`, body, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(assignUsersToRoleFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data) {
        alert('Assigned users to the role successfully.', 'success');
        dispatch(assignUsersToRoleSuccess(data.data));
      } else {
        let message = 'Error while assigning users to the role!';
        alert(message, 'error');
        dispatch(assignUsersToRoleFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(assignUsersToRoleFailed(errMsg));
    });
};
export const deleteRole = (headers, params) => (dispatch, getState) => {
  dispatch(deleteRoleRequest());
  axios
    .get(`${BASE_URL}/admin/deleterole`, { headers, params })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        alert(data.message, 'error');
        dispatch(deleteRoleFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data) {
        alert('Deleted role successfully.', 'success');
        dispatch(deleteRoleSuccess(data.data));
      } else {
        let message = 'Error while deleting the role!';
        alert(message, 'error');
        dispatch(deleteRoleFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(deleteRoleFailed(errMsg));
    });
};

export const fetchAdminProfile = (headers, params) => (dispatch, getState) => {
  dispatch(getAdminProfileRequest());
  axios
    .get(`${BASE_URL}/admin/getdetails`, { headers, params })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        dispatch(getAdminProfileFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data)
        dispatch(getAdminProfileSuccess(data.data));
      else {
        let message = 'No admin profile found!';
        alert(message, 'error');
        dispatch(getAdminProfileFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(getAdminProfileFailed(errMsg));
    });
};

export const updateAppConfig = (headers, body) => (dispatch, getState) => {
  dispatch(updateAppConfigRequest());
  axios
    .post(`${BASE_URL}/admin/updateAppConfig`, body, { headers })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        dispatch(updateAppConfigFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data)
        dispatch(updateAppConfigSuccess(data.data));
      else {
        let message = 'No admin profile found!';
        dispatch(updateAppConfigFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(updateAppConfigFailed(errMsg));
    });
};
export const fetchAppConfig = (headers, params) => (dispatch, getState) => {
  dispatch(fetchAppConfigRequest());
  axios
    .get(`${BASE_URL}/admin/fetchAppConfig`, { headers, params })
    .then(({ data }) => {
      if (data && data.error !== '0' && data.message) {
        dispatch(fetchAppConfigFailed(data.message));
        return;
      }
      if (data && data.error === '0' && data.data)
        dispatch(fetchAppConfigSuccess(data.data?.appConfig || {}));
      else {
        let message = 'No app config found for user!';
        dispatch(fetchAppConfigFailed(message));
      }
    })
    .catch((e) => {
      const errMsg = e.message;
      dispatch(fetchAppConfigFailed(errMsg));
    });
};
export const getPresignedUrl = async (headers, params) => {
  const response = axios.get(`${BASE_URL}/admin/getsignedurl`, {
    headers,
    params,
  });
  return response;
};
