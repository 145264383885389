import { Row, Col, Button, Select, Form, Divider, Space, Radio } from 'antd';
import { Text, Title } from 'components/atoms';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { jsonApiHeader } from 'utils/Utility';
import { useAuth0 } from '@auth0/auth0-react';
import {
  fetchAllAdmins,
  fetchListOfRoles,
  assignRoles,
} from 'redux/admin/adminActions';
import { UI_ACCESS_SCOPES, STORAGE_KEYS } from 'constants/index.js';
import { localStorageGetItem } from 'services/LocalStorageService';
const { Option } = Select;
const ManageAdmins = (props) => {
  const permissions = localStorageGetItem(STORAGE_KEYS.PERMISSIONS);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { user, getAccessTokenSilently } = useAuth0();
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [defaultRoles, setDefaultRoles] = useState([]);
  const [prevRoles, setPrevRoles] = useState([]);
  const { adminList, adminRoles, assignRolesSuccess } = useSelector(
    (state) => state.adminReducer
  );
  const handleFetchListOfAdmins = async () => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    dispatch(fetchAllAdmins(header));
  };
  const handleFetchListOfRoles = async () => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    dispatch(fetchListOfRoles(header));
  };
  useEffect(() => {
    handleFetchListOfAdmins();
    handleFetchListOfRoles();
  }, []);
  useEffect(() => {
    if (adminList && adminList.length) {
      let roles = adminList.map((item) => {
        return { [item.user_id]: item.user_type };
      });
      if (roles.length) setDefaultRoles(roles[0]);
    }
  }, [adminList]);
  const handleSelectedAdmin = (value) => {
    if (!(adminRoles && adminRoles.length)) handleFetchListOfRoles();
    let admin = adminList.filter((item) => {
      return item.user_id === value;
    });
    if (admin.length > 0) setSelectedAdmin(admin[0]);
  };
  const handleRadioChange = ({ target }) => {
    let roles = [...adminRoles];
    let filteredRoles = roles.filter((role) => {
      return target.value === role.name;
    });
    setSelectedRoles(filteredRoles);
  };
  const handleAssingRoles = async () => {
    const accessToken = await getAccessTokenSilently();
    const header = jsonApiHeader(accessToken);
    dispatch(
      assignRoles(header, {
        userId: selectedAdmin.user_id,
        roles: selectedRoles,
        prevRoles: prevRoles,
      })
    );
    // setSelectedAdmin('');
    // setSelectedRoles([]);
    // handleReset();
  };
  const onFinish = (values) => {
    handleAssingRoles();
  };
  const handleReset = () => {
    formRef.current.resetFields();
  };
  const handleCancel = () => {
    setSelectedAdmin({});
    setSelectedRoles([]);
    handleReset();
  };
  useEffect(() => {
    if (assignRolesSuccess) {
      setSelectedAdmin({});
      setSelectedRoles([]);
      handleReset();
      handleFetchListOfAdmins();
      handleFetchListOfRoles();
    }
  }, [assignRolesSuccess]);
  useEffect(() => {
    let roles = adminRoles.filter((item) => {
      return item.name !== 'User';
    });
    setFilteredRoles(roles);
  }, [adminRoles]);
  useEffect(() => {
    if (Object.keys(selectedAdmin)?.length && adminRoles?.length) {
      let roles = adminRoles.filter((item) => {
        return item.name === selectedAdmin.user_type;
      });
      let ids = roles.map((role) => {
        return role.id;
      });
      setPrevRoles(ids);
    }
  }, [selectedAdmin]);
  return (
    <div>
      <Row>
        <Col>
          <Title tag="h4" spacing="xs">
            Assign Roles To an Admin
          </Title>
        </Col>
      </Row>
      {permissions[UI_ACCESS_SCOPES.writeAdmin] ? (
        <Row>
          <Col span={12}>
            <Form ref={formRef} name="timeForm" onFinish={onFinish}>
              <Form.Item name="dateFormat">
                {adminList && adminList.length ? (
                  <>
                    <Select
                      style={{ width: '100%' }}
                      onChange={handleSelectedAdmin}
                      placeholder="Select an admin to assign role to..."
                    >
                      {adminList.map((admin, index) => {
                        return (
                          <Option key={index} value={admin.user_id}>
                            {admin.email}
                          </Option>
                        );
                      })}
                    </Select>
                  </>
                ) : (
                  <>
                    <Text>Unable to load the admins!</Text>
                    <Button onClick={handleFetchListOfAdmins}>
                      Try reloading
                    </Button>
                  </>
                )}
                <Divider />
                {selectedAdmin?.user_id &&
                  (filteredRoles && filteredRoles.length ? (
                    <>
                      <Title>Select Roles:</Title>
                      {/* <Checkbox.Group
											style={{ width: '100%' }}
											onChange={handleCheckboxChange}
											defaultValue={defaultRoles[selectedAdmin.user_id]}
										>
											<Row>
												{filteredRoles.map((role, index) => {
													return (
														<Col key={index} span={8}>
															<Checkbox value={role.name}>{role.name}</Checkbox>
														</Col>
													);
												})}
											</Row>
										</Checkbox.Group> */}
                      {console.log(
                        'ROLE > ',
                        defaultRoles[selectedAdmin.user_id],
                        selectedRoles
                      )}
                      <Radio.Group
                        onChange={handleRadioChange}
                        defaultValue={defaultRoles[selectedAdmin.user_id]}
                      >
                        <Row>
                          {filteredRoles.map((role, index) => {
                            return (
                              <Col key={index} span={12}>
                                <Radio value={role.name}>{role.name}</Radio>
                              </Col>
                            );
                          })}
                        </Row>
                      </Radio.Group>
                    </>
                  ) : (
                    <>
                      <Text>Unable to load the roles!</Text>
                      <Button onClick={handleFetchListOfRoles}>
                        Try reloading
                      </Button>
                    </>
                  ))}
              </Form.Item>

              <Row justify="end">
                <Col>
                  <Form.Item style={{ margin: '0' }}>
                    <Space>
                      <Button
                        type="primary"
                        disabled={
                          !(
                            Object.keys(selectedAdmin).length &&
                            selectedRoles?.length &&
                            selectedRoles[0].name !==
                              defaultRoles[selectedAdmin.user_id]
                          )
                        }
                        htmlType="submit"
                      >
                        Update Role
                      </Button>
                      <Button type="secondary" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        <Text size="md">You don't have permission to assign roles.</Text>
      )}
    </div>
  );
};

export default ManageAdmins;
