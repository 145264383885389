export const REFERRAL_DETAILS_REQUEST = 'REFERRAL_DETAILS_REQUEST';
export const REFERRAL_DETAILS_SUCCESS = 'REFERRAL_DETAILS_SUCCESS';
export const REFERRAL_DETAILS_FAILURE = 'REFERRAL_DETAILS_FAILURE';

export const TOTAL_EARNING_REQUEST = 'TOTAL_EARNING_REQUEST';
export const TOTAL_EARNING_SUCCESS = 'TOTAL_EARNING_SUCCESS';
export const TOTAL_EARNING_FAILURE = 'TOTAL_EARNING_FAILURE';

export const BLOCK_REFERRAL_REQUEST = 'BLOCK_REFERRAL_REQUEST';
export const BLOCK_REFERRAL_SUCCESS = 'BLOCK_REFERRAL_SUCCESS';
export const BLOCK_REFERRAL_FAILURE = 'BLOCK_REFERRAL_FAILURE';
