import {
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  FETCH_ACTIVITY_LOG_REQUEST,
  FETCH_ACTIVITY_LOG_SUCCESS,
  FETCH_ACTIVITY_LOG_FAILED,
  FETCH_ALL_ADMINS_REQUEST,
  FETCH_ALL_ADMINS_SUCCESS,
  FETCH_ALL_ADMINS_FAILED,
  ASSIGN_ROLES_REQUEST,
  ASSIGN_ROLES_SUCCESS,
  ASSIGN_ROLES_FAILED,
  RESET_ASSIGN_ROLES_STATE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILED,
  FETCH_ALL_ADMINS_PER_ROLE_REQUEST,
  FETCH_ALL_ADMINS_PER_ROLE_SUCCESS,
  FETCH_ALL_ADMINS_PER_ROLE_FAILED,
  FETCH_PERMISSIONS_FOR_ROLE_FAILURE,
  FETCH_PERMISSIONS_FOR_ROLE_SUCCESS,
  FETCH_PERMISSIONS_FOR_ROLE_REQUEST,
  ASSIGN_PERMISSIONS_TO_ROLE_FAILURE,
  ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS,
  ASSIGN_PERMISSIONS_TO_ROLE_REQUEST,
  REMOVE_PERMISSIONS_FROM_ROLE_FAILURE,
  REMOVE_PERMISSIONS_FROM_ROLE_SUCCESS,
  REMOVE_PERMISSIONS_FROM_ROLE_REQUEST,
  FETCH_ALL_PERMISSIONS_REQUEST,
  FETCH_ALL_PERMISSIONS_SUCCESS,
  FETCH_ALL_PERMISSIONS_FAILURE,
  FETCH_DIRECT_PERMISSIONS_FOR_USER_REQUEST,
  FETCH_DIRECT_PERMISSIONS_FOR_USER_SUCCESS,
  FETCH_DIRECT_PERMISSIONS_FOR_USER_FAILURE,
  ASSIGN_DIRECT_PERMISSIONS_TO_USER_REQUEST,
  ASSIGN_DIRECT_PERMISSIONS_TO_USER_SUCCESS,
  ASSIGN_DIRECT_PERMISSIONS_TO_USER_FAILURE,
  REMOVE_DIRECT_PERMISSIONS_FOR_USER_REQUEST,
  REMOVE_DIRECT_PERMISSIONS_FOR_USER_SUCCESS,
  REMOVE_DIRECT_PERMISSIONS_FOR_USER_FAILURE,
  FETCH_ALL_USER_WITH_ROLE_REQUEST,
  FETCH_ALL_USER_WITH_ROLE_SUCCESS,
  FETCH_ALL_USER_WITH_ROLE_FAILURE,
  FETCH_ROLES_FOR_USER_REQUEST,
  FETCH_ROLES_FOR_USER_SUCCESS,
  FETCH_ROLES_FOR_USER_FAILURE,
  REMOVE_ROLE_FROM_USER_REQUEST,
  REMOVE_ROLE_FROM_USER_SUCCESS,
  REMOVE_ROLE_FROM_USER_FAILURE,
  CREATE_NEW_ROLE_REQUEST,
  CREATE_NEW_ROLE_SUCCESS,
  CREATE_NEW_ROLE_FAILURE,
  CREATE_NEW_ROLE_RESET,
  ASSIGN_USERS_TO_ROLE_REQUEST,
  ASSIGN_USERS_TO_ROLE_SUCCESS,
  ASSIGN_USERS_TO_ROLE_FAILURE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  DELETE_ROLE_RESET,
  GET_ADMIN_PROFILE_REQUEST,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_ADMIN_PROFILE_FAILURE,
  UPDATE_APP_CONFIG_REQUEST,
  UPDATE_APP_CONFIG_SUCCESS,
  UPDATE_APP_CONFIG_FAILURE,
  FETCH_APP_CONFIG_REQUEST,
  FETCH_APP_CONFIG_SUCCESS,
  FETCH_APP_CONFIG_FAILURE,
} from './adminActionTypes';

const initialState = {
  loadingAddUser: null,
  addUserSuccess: false,
  loadingActivityLogs: false,
  activityLogs: [],
  activityLogErrorMsg: '',
  loadingAdminList: false,
  adminList: [],
  adminListErrorMsg: '',
  assigningRoles: false,
  assignRolesSuccess: false,
  assignRolesErrorMsg: '',
  loadingListOfRoles: false,
  adminRoles: [],
  adminRolesErrorMsg: '',
  loadingAdminPerRole: false,
  adminsPerRole: {},
  adminPerRoleErrorMsg: '',
  loadingAssignPermissionsToRole: false,
  assignPermissionsToRoleSuccess: false,
  assignPermissionsToRoleErrorMsg: '',
  loadingPermissionsForRole: false,
  permissionsForRole: {},
  permissionsForRoleErrorMsg: '',
  loadingRemovePermissionsForRole: false,
  removePermissionsFromRoleSuccess: false,
  removePermissionsForRoleErrorMsg: '',
  loadingAllPermissions: false,
  allPermissions: [],
  allPermissionsErrorMsg: '',
  loadingDirectPermissionsForUser: false,
  directPermissionsForUser: [],
  directPermissionsForUserErrMsg: '',
  assigningDirectPermissionsToUser: false,
  assigningDirectPermissionsToUserSuccess: false,
  assigningDirectPermissionsToUserErrMsg: '',
  removingDirectPermissionsFromUser: false,
  removingDirectPermissionsFromUserSuccess: false,
  removingDirectPermissionsFromUserErrMsg: '',
  loadingAllUserWithRole: false,
  allUserWithRole: [],
  allUserWithRoleErrorMsg: '',
  loadingRolesForUser: false,
  rolesForUser: [],
  rolesForUserErrorMsg: '',
  loadingRemoveRoleFromUser: false,
  removeRoleFromUserSuccess: false,
  removeRoleFromUserErrorMsg: '',
  loadingCreateNewRole: false,
  createNewRoleSuccess: false,
  newRoleData: {},
  createNewRoleErrorMsg: '',
  assigningUsersToRole: false,
  assigningUsersToRoleSuccess: false,
  assigningUsersToRoleErrMsg: '',
  deletingRole: false,
  deleteRoleSuccess: false,
  deleteRoleErrorMsg: '',
  loadingAdminProfile: false,
  adminProfile: {},
  updatingAppConfig: false,
  appConfigUpdated: false,
  loadingAppConfig: false,
  adminAppConfig: {},
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_REQUEST:
      return {
        ...state,
        loadingAddUser: true,
        addUserSuccess: false,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loadingAddUser: false,
        addUserSuccess: true,
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        loadingAddUser: false,
        addUserSuccess: false,
      };
    case FETCH_ACTIVITY_LOG_REQUEST:
      return {
        ...state,
        loadingActivityLogs: true,
        activityLogs: [],
        activityLogErrorMsg: '',
      };
    case FETCH_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        loadingActivityLogs: false,
        activityLogs: action.payload,
        activityLogErrorMsg: '',
      };
    case FETCH_ACTIVITY_LOG_FAILED:
      return {
        ...state,
        loadingActivityLogs: false,
        activityLogs: [],
        activityLogErrorMsg: action.payload,
      };
    case FETCH_ALL_ADMINS_REQUEST:
      return {
        ...state,
        loadingAdminList: true,
        adminList: [],
        adminListErrorMsg: '',
      };
    case FETCH_ALL_ADMINS_SUCCESS:
      return {
        ...state,
        loadingAdminList: false,
        adminList: action.payload,
        adminListErrorMsg: '',
      };
    case FETCH_ALL_ADMINS_FAILED:
      return {
        ...state,
        loadingAdminList: false,
        adminList: [],
        adminListErrorMsg: action.payload,
      };
    case ASSIGN_ROLES_REQUEST:
      return {
        ...state,
        assigningRoles: true,
        assignRolesSuccess: false,
        assignRolesErrorMsg: '',
      };
    case ASSIGN_ROLES_SUCCESS:
      return {
        ...state,
        assigningRoles: false,
        assignRolesSuccess: true,
        assignRolesErrorMsg: '',
      };
    case ASSIGN_ROLES_FAILED:
      return {
        ...state,
        assigningRoles: false,
        assignRolesSuccess: false,
        assignRolesErrorMsg: action.payload,
      };
    case RESET_ASSIGN_ROLES_STATE:
      return {
        ...state,
        assigningRoles: false,
        assignRolesSuccess: false,
        assignRolesErrorMsg: '',
      };
    case FETCH_ROLES_REQUEST:
      return {
        ...state,
        loadingListOfRoles: true,
        adminRoles: [],
        adminRolesErrorMsg: '',
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        loadingListOfRoles: false,
        adminRoles: action.payload,
        adminRolesErrorMsg: '',
      };
    case FETCH_ROLES_FAILED:
      return {
        ...state,
        loadingListOfRoles: false,
        adminRoles: [],
        adminRolesErrorMsg: action.payload,
      };
    case FETCH_ALL_ADMINS_PER_ROLE_REQUEST:
      return {
        ...state,
        loadingAdminPerRole: true,
        adminsPerRole: {},
        adminPerRoleErrorMsg: '',
      };
    case FETCH_ALL_ADMINS_PER_ROLE_SUCCESS:
      return {
        ...state,
        loadingAdminPerRole: false,
        adminsPerRole: action.payload,
        adminPerRoleErrorMsg: '',
      };
    case FETCH_ALL_ADMINS_PER_ROLE_FAILED:
      return {
        ...state,
        loadingAdminPerRole: false,
        adminsPerRole: {},
        adminPerRoleErrorMsg: action.payload,
      };
    case FETCH_PERMISSIONS_FOR_ROLE_REQUEST:
      return {
        ...state,
        loadingPermissionsForRole: true,
        permissionsForRole: {},
        permissionsForRoleErrorMsg: '',
      };
    case FETCH_PERMISSIONS_FOR_ROLE_SUCCESS:
      return {
        ...state,
        loadingPermissionsForRole: false,
        permissionsForRole: action.payload,
        permissionsForRoleErrorMsg: '',
      };
    case FETCH_PERMISSIONS_FOR_ROLE_FAILURE:
      return {
        ...state,
        loadingPermissionsForRole: false,
        permissionsForRole: {},
        permissionsForRoleFailure: action.payload,
      };
    case ASSIGN_PERMISSIONS_TO_ROLE_REQUEST:
      return {
        ...state,
        loadingAssignPermissionsToRole: true,
        assignPermissionsToRoleSuccess: false,
        assignPermissionsToRoleErrorMsg: '',
      };
    case ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS:
      return {
        ...state,
        loadingAssignPermissionsToRole: false,
        assignPermissionsToRoleSuccess: true,
        assignPermissionsToRoleErrorMsg: '',
      };
    case ASSIGN_PERMISSIONS_TO_ROLE_FAILURE:
      return {
        ...state,
        loadingAssignPermissionsToRole: false,
        assignPermissionsToRoleSuccess: false,
        assignPermissionsToRoleErrorMsg: action.payload,
      };
    case REMOVE_PERMISSIONS_FROM_ROLE_REQUEST:
      return {
        ...state,
        loadingRemovePermissionsForRole: true,
        removePermissionsFromRoleSuccess: false,
        removePermissionsForRoleErrorMsg: '',
      };
    case REMOVE_PERMISSIONS_FROM_ROLE_SUCCESS:
      return {
        ...state,
        loadingRemovePermissionsForRole: false,
        removePermissionsFromRoleSuccess: true,
        removePermissionsForRoleErrorMsg: '',
      };
    case REMOVE_PERMISSIONS_FROM_ROLE_FAILURE:
      return {
        ...state,
        loadingRemovePermissionsForRole: false,
        removePermissionsFromRoleSuccess: false,
        removePermissionsForRoleErrorMsg: action.payload,
      };
    case FETCH_ALL_PERMISSIONS_REQUEST:
      return {
        ...state,
        loadingAllPermissions: true,
        allPermissions: [],
        allPermissionsErrorMsg: '',
      };
    case FETCH_ALL_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loadingAllPermissions: false,
        allPermissions: action.payload,
        allPermissionsErrorMsg: '',
      };
    case FETCH_ALL_PERMISSIONS_FAILURE:
      return {
        ...state,
        loadingAllPermissions: false,
        allPermissions: [],
        allPermissionsErrorMsg: action.payload,
      };
    case FETCH_DIRECT_PERMISSIONS_FOR_USER_REQUEST:
      return {
        ...state,
        loadingDirectPermissionsForUser: true,
        directPermissionsForUser: [],
        directPermissionsForUserErrMsg: '',
      };
    case FETCH_DIRECT_PERMISSIONS_FOR_USER_SUCCESS:
      return {
        ...state,
        loadingDirectPermissionsForUser: false,
        directPermissionsForUser: action.payload,
        directPermissionsForUserErrMsg: '',
      };
    case FETCH_DIRECT_PERMISSIONS_FOR_USER_FAILURE:
      return {
        ...state,
        loadingDirectPermissionsForUser: false,
        directPermissionsForUser: [],
        directPermissionsForUserErrMsg: action.payload,
      };
    case ASSIGN_DIRECT_PERMISSIONS_TO_USER_REQUEST:
      return {
        ...state,
        assigningDirectPermissionsToUser: true,
        assigningDirectPermissionsToUserSuccess: false,
        assigningDirectPermissionsToUserErrMsg: '',
      };
    case ASSIGN_DIRECT_PERMISSIONS_TO_USER_SUCCESS:
      return {
        ...state,
        assigningDirectPermissionsToUser: false,
        assigningDirectPermissionsToUserSuccess: true,
        assigningDirectPermissionsToUserErrMsg: '',
      };
    case ASSIGN_DIRECT_PERMISSIONS_TO_USER_FAILURE:
      return {
        ...state,
        assigningDirectPermissionsToUser: false,
        assigningDirectPermissionsToUserSuccess: false,
        assigningDirectPermissionsToUserErrMsg: action.payload,
      };
    case REMOVE_DIRECT_PERMISSIONS_FOR_USER_REQUEST:
      return {
        ...state,
        removingDirectPermissionsFromUser: true,
        removingDirectPermissionsFromUserSuccess: false,
        removingDirectPermissionsFromUserErrMsg: '',
      };
    case REMOVE_DIRECT_PERMISSIONS_FOR_USER_SUCCESS:
      return {
        ...state,
        removingDirectPermissionsFromUser: false,
        removingDirectPermissionsFromUserSuccess: true,
        removingDirectPermissionsFromUserErrMsg: '',
      };
    case REMOVE_DIRECT_PERMISSIONS_FOR_USER_FAILURE:
      return {
        ...state,
        removingDirectPermissionsFromUser: false,
        removingDirectPermissionsFromUserSuccess: false,
        removingDirectPermissionsFromUserErrMsg: action.payload,
      };

    case FETCH_ALL_USER_WITH_ROLE_REQUEST:
      return {
        ...state,
        loadingAllUserWithRole: true,
        allUserWithRole: [],
        allUserWithRoleErrorMsg: '',
      };
    case FETCH_ALL_USER_WITH_ROLE_SUCCESS:
      return {
        ...state,
        loadingAllUserWithRole: false,
        allUserWithRole: action.payload,
        allUserWithRoleErrorMsg: '',
      };
    case FETCH_ALL_USER_WITH_ROLE_FAILURE:
      return {
        ...state,
        loadingAllUserWithRole: false,
        allUserWithRole: [],
        allUserWithRoleErrorMsg: action.payload,
      };

    case FETCH_ROLES_FOR_USER_REQUEST:
      return {
        ...state,
        loadingRolesForUser: true,
        rolesForUser: [],
        rolesForUserErrorMsg: '',
      };
    case FETCH_ROLES_FOR_USER_SUCCESS:
      return {
        ...state,
        loadingRolesForUser: false,
        rolesForUser: action.payload,
        rolesForUserErrorMsg: '',
      };
    case FETCH_ROLES_FOR_USER_FAILURE:
      return {
        ...state,
        loadingRolesForUser: false,
        rolesForUser: [],
        rolesForUserErrorMsg: action.payload,
      };
    case REMOVE_ROLE_FROM_USER_REQUEST:
      return {
        ...state,
        loadingRemoveRoleFromUser: true,
        removeRoleFromUserSuccess: false,
        removeRoleFromUserErrorMsg: '',
      };
    case REMOVE_ROLE_FROM_USER_SUCCESS:
      return {
        ...state,
        loadingRemoveRoleFromUser: false,
        removeRoleFromUserSuccess: true,
        removeRoleFromUserErrorMsg: '',
      };
    case REMOVE_ROLE_FROM_USER_FAILURE:
      return {
        ...state,
        loadingRemoveRoleFromUser: false,
        removeRoleFromUserSuccess: false,
        removeRoleFromUserErrorMsg: action.payload,
      };
    case CREATE_NEW_ROLE_REQUEST:
      return {
        ...state,
        loadingCreateNewRole: true,
        createNewRoleSuccess: false,
        newRoleData: {},
        createNewRoleErrorMsg: '',
      };
    case CREATE_NEW_ROLE_SUCCESS:
      return {
        ...state,
        loadingCreateNewRole: false,
        createNewRoleSuccess: true,
        newRoleData: action.payload,
        createNewRoleErrorMsg: '',
      };
    case CREATE_NEW_ROLE_FAILURE:
      return {
        ...state,
        loadingCreateNewRole: false,
        createNewRoleSuccess: false,
        newRoleData: {},
        createNewRoleErrorMsg: action.payload,
      };
    case CREATE_NEW_ROLE_RESET:
      return {
        ...state,
        loadingCreateNewRole: false,
        createNewRoleSuccess: false,
        newRoleData: {},
        createNewRoleErrorMsg: '',
      };
    case ASSIGN_USERS_TO_ROLE_REQUEST:
      return {
        ...state,
        assigningUsersToRole: true,
        assigningUsersToRoleSuccess: false,
        assigningUsersToRoleErrMsg: '',
      };
    case ASSIGN_USERS_TO_ROLE_SUCCESS:
      return {
        ...state,
        assigningUsersToRole: false,
        assigningUsersToRoleSuccess: true,
        assigningUsersToRoleErrMsg: '',
      };
    case ASSIGN_USERS_TO_ROLE_FAILURE:
      return {
        ...state,
        assigningUsersToRole: false,
        assigningUsersToRoleSuccess: false,
        assigningUsersToRoleErrMsg: action.payload,
      };
    case DELETE_ROLE_REQUEST:
      return {
        ...state,
        deletingRole: true,
        deleteRoleSuccess: false,
        deleteRoleErrorMsg: '',
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deletingRole: false,
        deleteRoleSuccess: true,
        deleteRoleErrorMsg: '',
      };
    case DELETE_ROLE_FAILURE:
      return {
        ...state,
        deletingRole: false,
        deleteRoleSuccess: false,
        deleteRoleErrorMsg: action.payload,
      };
    case DELETE_ROLE_RESET:
      return {
        ...state,
        deletingRole: false,
        deleteRoleSuccess: false,
        deleteRoleErrorMsg: '',
      };
    case GET_ADMIN_PROFILE_REQUEST:
      return {
        ...state,
        loadingAdminProfile: true,
        adminProfile: {},
      };
    case GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        loadingAdminProfile: false,
        adminProfile: action.payload,
      };
    case GET_ADMIN_PROFILE_FAILURE:
      return {
        ...state,
        loadingAdminProfile: false,
        adminProfile: {},
      };
    case UPDATE_APP_CONFIG_REQUEST:
      return {
        ...state,
        updatingAppConfig: true,
        appConfigUpdated: false,
      };
    case UPDATE_APP_CONFIG_SUCCESS:
      return {
        ...state,
        updatingAppConfig: false,
        appConfigUpdated: true,
      };
    case UPDATE_APP_CONFIG_FAILURE:
      return {
        ...state,
        updatingAppConfig: false,
        appConfigUpdated: false,
      };
    case FETCH_APP_CONFIG_REQUEST:
      return {
        ...state,
        loadingAppConfig: true,
        adminAppConfig: {},
      };
    case FETCH_APP_CONFIG_SUCCESS:
      return {
        ...state,
        loadingAppConfig: false,
        adminAppConfig: action.payload,
      };
    case FETCH_APP_CONFIG_FAILURE:
      return {
        ...state,
        loadingAppConfig: false,
        adminAppConfig: {},
      };
    default:
      return state;
  }
};

export default adminReducer;
