import { MENU_SIDEBAR, PREFERENCES } from './screenOptionsTypes';

const initialState = {
  sidebarOptions: {
    isCollapsed: false,
  },
  dateTimeSetting: '',
};

const screenOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_SIDEBAR.GET:
      return {
        ...state,
        sidebarOptions: {
          isCollapsed: action.payload,
          ...state.sidebarOptions,
        },
      };
    case MENU_SIDEBAR.UPDATE.COLLAPSE:
      return {
        ...state,
        sidebarOptions: {
          ...state.sidebarOptions,
          isCollapsed: action.payload,
        },
      };

    case PREFERENCES.DATE:
      return {
        ...state,
        dateTimeSetting: action.payload,
      };
    default:
      return state;
  }
};

export default screenOptionsReducer;
