const environments = {
  development: {
    BASE_URL: 'http://localhost:3002',
  },
  production: {
    BASE_URL:
      'http://ac7a09409ef3443d5be16772d9beaffd-ae380a182f204980.elb.ap-south-1.amazonaws.com/admin-backend-service',
  },
  test: {
    BASE_URL:
      'http://ac7a09409ef3443d5be16772d9beaffd-ae380a182f204980.elb.ap-south-1.amazonaws.com/admin-backend-service',
  },
};

export default environments[process.env.REACT_APP_ENV] ||
  environments[Object.keys(environments)[2]];
