import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Select, Table, Input, Form, Modal, InputNumber, Radio } from 'antd';
import {
    PageHeaders,
} from 'components/molecules';
import { Text } from 'components/atoms';
import axios from 'axios';
import { useGoogleAuth } from 'components/google-login/GoogleAuthProvider';

const AddManualPaperBalance = (props) => {

    const [allCoins, setAllCoins] = useState([]);
    const [selectedCoin, setSelectedCoin] = useState(null)
    const { googleUser } = useGoogleAuth();

    const getAllCoins = async () => {
        try {
            let response = await axios.get("https://walletbackend.zebpay.co/wallet/coin/coins", {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
                }
            });

            response = response.data.data
            let temp = []
            for (let obj of response) {
                temp.push(obj.coinType)
            }
            setAllCoins(temp)
        } catch (err) {
            alert(err)
        }
    }

    const addManualPaperBalance = async (balance) => {
        let userName = googleUser.profileObj.name
        let userEmail = localStorage.getItem('email')

        const res = await axios.post('https://walletbackend.zebpay.co/provider/addManualPaperBalance', {
            coinType: balance.coinType,
            type: balance.type,
            label: balance.label,
            balance: parseFloat(balance.paperBalance),
            updatedBy: userName ? userName : userEmail
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('returnedToken')
            }
        })
    }

    useEffect(async () => {
        await getAllCoins();
    }, []);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };

    const handleCancelForBalanceModal = () => {
        setSelectedCoin(null)
        props.onData(false) // setting balance modal visible as false
    }

    const onFinishBalanceModal = async (formdata) => {
        try {
            let allDetailsFilled = true
            for (let key of ['type', 'label', 'coinType', 'paperBalance']) {
                if (formdata.balance[key] === undefined) {
                    allDetailsFilled = false
                    alert('Fill all the details')
                    break
                }
            }

            if (allDetailsFilled) {
                await addManualPaperBalance(formdata.balance)
                alert('Manual Paper balance added')
                setSelectedCoin(null)
                props.onData(false)
            }
        } catch (err) {
            console.log(err.message);
            console.log(err.data);
            alert('some error occured. not able to add paper balance. please try again')
        }
    }

    const handleResetBalanceModal = () => {
        setSelectedCoin(null)
    }

    return (
        <div>
            <Modal title="Add Manual Paper Balance" mask={true} visible={props.isModalVisible} destroyOnClose={true} onCancel={handleCancelForBalanceModal} footer={[]}>
                <Form {...layout} name="nest-messages" id='addmanualbalance' onFinish={onFinishBalanceModal} validateMessages={validateMessages}>
                    <Form.Item name={['balance', 'type']} label="Type" labelAlign='left' rules={[]}>
                        <Select defaultValue={"Select type"}>
                            {['MANUAL'].map(item => (
                                <Select.Option key={item.key} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={['balance', 'label']} label="Label" labelAlign='left' rules={[]}>
                        <Select defaultValue={"Select label"}>
                            {['OTC PAPER BALANCE'].map(item => (
                                <Select.Option key={item.key} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={['balance', 'coinType']} label="Coin Type" labelAlign='left' rules={[]}>
                        <Select onSelect={(coin) => { setSelectedCoin(coin) }} showSearch defaultValue={"Select Coin Type"}>
                            {allCoins.map(item => (
                                <Select.Option key={item.key} value={item}>
                                    {item.toUpperCase()}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={['balance', 'paperBalance']} label="Paper Balance" labelAlign='left' rules={[]}>
                        {selectedCoin !== null ? <Input type='number' autoFocus /> : <Text size="md">
                            {''}
                        </Text>}
                    </Form.Item>




                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button onClick={handleResetBalanceModal} type="secondary" htmlType="reset">
                            Reset
                        </Button>
                        <Button type="primary" key="addmanualbalance" htmlType="submit" style={{ marginLeft: "20px" }}>
                            Submit
                        </Button>
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default AddManualPaperBalance;
