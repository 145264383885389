import {
  FETCH_ALL_VPA_FAILURE,
  FETCH_ALL_VPA_SUCCESS,
  REJECT_VPA_FAILURE,
  REJECT_VPA_SUCCESS,
  REQUEST_FETCH_ALL_VPA,
  REQUEST_REJECT_VPA,
  REQUEST_VERIFY_VPA,
  VERIFY_VPA_FAILURE,
  VERIFY_VPA_SUCCESS,
} from './VerificationActionTypes';

const initialState = {
  loadingVerifyRequest: false,
  verifyRequest: false,
  loadingRejectRequest: false,
  rejectRequest: false,
  loadingVpaData: false,
  vpaData: {},
};

const verificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_REJECT_VPA:
      return {
        ...state,
        loadingRejectRequest: true,
        rejectRequest: false,
      };
    case REJECT_VPA_SUCCESS:
    case REJECT_VPA_FAILURE:
      return {
        ...state,
        loadingRejectRequest: false,
        rejectRequest: action.payload,
      };
    case REQUEST_VERIFY_VPA:
      return {
        ...state,
        loadingVerifyRequest: true,
        verifyRequest: false,
      };
    case VERIFY_VPA_SUCCESS:
    case VERIFY_VPA_FAILURE:
      return {
        ...state,
        loadingVerifyRequest: false,
        verifyRequest: action.payload,
      };
    case REQUEST_FETCH_ALL_VPA:
      return {
        ...state,
        loadingVpaData: true,
        vpaData: {},
      };
    case FETCH_ALL_VPA_SUCCESS:
    case FETCH_ALL_VPA_FAILURE:
      return {
        ...state,
        loadingVpaData: false,
        vpaData: action.payload,
      };
    default:
      return state;
  }
};

export default verificationReducer;
