import {
  FETCH_BUYSELL_DATA_FAILURE,
  FETCH_BUYSELL_DATA_REQUEST,
  FETCH_BUYSELL_DATA_SUCCESS,
  FETCH_INSTANT_ORDER_DETAILS_REQUEST,
  FETCH_INSTANT_ORDER_DETAILS_SUCCESS,
  FETCH_INSTANT_ORDER_DETAILS_FAILURE,
  FETCH_TRADE_VOLUME_DETAILS_FAILURE,
  FETCH_TRADE_VOLUME_DETAILS_REQUEST,
  FETCH_TRADE_VOLUME_DETAILS_SUCCESS,
  FETCH_ALL_BUYSELL_DATA_FAILURE,
  FETCH_ALL_BUYSELL_DATA_REQUEST,
  FETCH_ALL_BUYSELL_DATA_SUCCESS,
  FETCH_ALL_RECENT_TXNS_REQUEST,
  FETCH_ALL_RECENT_TXNS_FAILURE,
  FETCH_ALL_RECENT_TXNS_SUCCESS,
  SET_FILTER_QUERY_PARAMS,
} from './buysellActionTypes';
const initialState = {
  loadingBuysellData: false,
  buysellData: [],
  buysellErrorMsg: '',
  loadingOrderDetails: false,
  orderDetails: {},
  orderDetailsErrorMsg: '',
  loadingTradeVolumeDetails: false,
  tradeVolumeDetails: [],
  tradeVolumeErrorMsg: '',
  loadingAllBuySellData: false,
  allBuySellTxn: [],
  allBuySellErrorMsg: '',
  loadingRecentTxns: false,
  recentTxns: [],
  recentTxnsErrorMsg: '',
  filterQueryParams: {},
};

const buysellReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUYSELL_DATA_REQUEST:
      return {
        ...state,
        loadingBuysellData: true,
        buysellData: [],
        buysellErrorMsg: '',
      };
    case FETCH_BUYSELL_DATA_SUCCESS:
      return {
        ...state,
        loadingBuysellData: false,
        buysellData: action.payload,
        buysellErrorMsg: '',
      };
    case FETCH_BUYSELL_DATA_FAILURE:
      return {
        ...state,
        loadingBuysellData: false,
        buysellData: [],
        buysellErrorMsg: action.payload,
      };
    case FETCH_INSTANT_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loadingOrderDetails: true,
        orderDetails: {},
        orderDetailsErrorMsg: '',
      };
    case FETCH_INSTANT_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loadingOrderDetails: false,
        orderDetails: action.payload,
        orderDetailsErrorMsg: '',
      };
    case FETCH_INSTANT_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loadingOrderDetails: false,
        orderDetails: {},
        orderDetailsErrorMsg: action.payload,
      };
    case FETCH_TRADE_VOLUME_DETAILS_REQUEST:
      return {
        ...state,
        loadingTradeVolumeDetails: true,
        tradeVolumeDetails: [],
        tradeVolumeErrorMsg: '',
      };
    case FETCH_TRADE_VOLUME_DETAILS_SUCCESS:
      return {
        ...state,
        loadingTradeVolumeDetails: false,
        tradeVolumeDetails: action.payload,
        tradeVolumeErrorMsg: '',
      };
    case FETCH_TRADE_VOLUME_DETAILS_FAILURE:
      return {
        ...state,
        loadingTradeVolumeDetails: false,
        tradeVolumeDetails: [],
        tradeVolumeErrorMsg: action.payload,
      };
    case FETCH_ALL_BUYSELL_DATA_SUCCESS:
      return {
        ...state,
        loadingAllBuySellData: false,
        allBuySellTxn: action.payload,
        allBuySellErrorMsg: '',
      };
    case FETCH_ALL_BUYSELL_DATA_REQUEST:
      return {
        ...state,
        loadingAllBuySellData: true,
        allBuySellTxn: [],
        allBuySellErrorMsg: '',
      };
    case FETCH_ALL_BUYSELL_DATA_FAILURE:
      return {
        ...state,
        loadingAllBuySellData: false,
        allBuySellTxn: [],
        allBuySellErrorMsg: action.payload,
      };
    case FETCH_ALL_RECENT_TXNS_REQUEST:
      return {
        ...state,
        loadingRecentTxns: true,
        recentTxns: [],
        recentTxnsErrorMsg: '',
      };
    case FETCH_ALL_RECENT_TXNS_FAILURE:
      return {
        ...state,
        loadingRecentTxns: false,
        recentTxns: [],
        recentTxnsErrorMsg: action.payload,
      };
    case FETCH_ALL_RECENT_TXNS_SUCCESS:
      return {
        ...state,
        loadingRecentTxns: false,
        recentTxns: action.payload,
        recentTxnsErrorMsg: '',
      };
    case SET_FILTER_QUERY_PARAMS:
      return {
        ...state,
        filterQueryParams: action.payload,
      };
    default:
      return state;
  }
};

export default buysellReducer;
