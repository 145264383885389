export const FETCH_ACTIVE_SIP_REQUEST = 'FETCH_ACTIVE_SIP_REQUEST';
export const FETCH_ACTIVE_SIP_SUCCESS = 'FETCH_ACTIVE_SIP_SUCCESS';
export const FETCH_ACTIVE_SIP_FAILED = 'FETCH_ACTIVE_SIP_FAILED';
export const FETCH_CLOSED_SIP_REQUEST = 'FETCH_CLOSED_SIP_REQUEST';
export const FETCH_CLOSED_SIP_SUCCESS = 'FETCH_CLOSED_SIP_SUCCESS';
export const FETCH_CLOSED_SIP_FAILED = 'FETCH_CLOSED_SIP_FAILED';
export const FETCH_SIP_EXECUTION_HISTORY_REQUEST =
  'FETCH_SIP_EXECUTION_HISTORY_REQUEST';
export const FETCH_SIP_EXECUTION_HISTORY_SUCCESS =
  'FETCH_SIP_EXECUTION_HISTORY_SUCCESS';
export const FETCH_SIP_EXECUTION_HISTORY_FAILED =
  'FETCH_SIP_EXECUTION_HISTORY_FAILED';
export const GET_USER_BALANCE_REQUEST = 'GET_USER_BALANCE_REQUEST';
export const GET_USER_BALANCE_SUCCESS = 'GET_USER_BALANCE_SUCCESS';
export const GET_USER_BALANCE_FAILED = 'GET_USER_BALANCE_FAILED';
export const UPDATE_SIP_REQUEST = 'UPDATE_SIP_REQUEST';
export const UPDATE_SIP_SUCCESS = 'UPDATE_SIP_SUCCESS';
export const UPDATE_SIP_FAILED = 'UPDATE_SIP_FAILED';
export const CANCEL_SIP_REQUEST = 'CANCEL_SIP_REQUEST';
export const CANCEL_SIP_SUCCESS = 'CANCEL_SIP_SUCCESS';
export const CANCEL_SIP_FAILED = 'CANCEL_SIP_FAILED';
export const FETCH_TRADE_LIMIT_FOR_USER_REQUEST =
  'FETCH_TRADE_LIMIT_FOR_USER_REQUEST';
export const FETCH_TRADE_LIMIT_FOR_USER_SUCCESS =
  'FETCH_TRADE_LIMIT_FOR_USER_SUCCESS';
export const FETCH_TRADE_LIMIT_FOR_USER_FAILED =
  'FETCH_TRADE_LIMIT_FOR_USER_FAILED';
export const FETCH_TRADE_LIMIT_BY_COUNTRY_REQUEST =
  'FETCH_TRADE_LIMIT_BY_COUNTRY_REQUEST';
export const FETCH_TRADE_LIMIT_BY_COUNTRY_SUCCESS =
  'FETCH_TRADE_LIMIT_BY_COUNTRY_SUCCESS';
export const FETCH_TRADE_LIMIT_BY_COUNTRY_FAILED =
  'FETCH_TRADE_LIMIT_BY_COUNTRY_FAILED';
export const FETCH_Z1_WALLET_BALANCE_REQUEST =
  'FETCH_Z1_WALLET_BALANCE_REQUEST';
export const FETCH_Z1_WALLET_BALANCE_SUCCESS =
  'FETCH_Z1_WALLET_BALANCE_SUCCESS';
export const FETCH_Z1_WALLET_BALANCE_FAILED = 'FETCH_Z1_WALLET_BALANCE_FAILED';
